import { combineReducers, configureStore } from '@reduxjs/toolkit';
import CommonReducer from '../reducer/common.reducer'; 

const rootReducer:any=combineReducers({
    CommonReducer:CommonReducer
})

const store = configureStore({
  reducer: rootReducer, 
});

export default store;