import React, { useEffect, useState } from "react";
import {
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Button, ButtonGroup, ButtonToolbar, Popover, Whisper } from "rsuite";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TimelineIcon from "@mui/icons-material/Timeline";
import TableChartIcon from "@mui/icons-material/TableChart";
import { useSelector } from "react-redux";
import { formatAmount, formatNumber } from "../../utils/commonFunctions";

const ForecastGraph = (props: any) => {
  const [legendStatus, setLegendStatus] = useState<any>([]);
  const { graphTable, flag } = props;
  const windowSize = useSelector(
    (state: any) => state.CommonReducer.windowSize
  );

  const handleGraph = (tab: number) => {
    props.setGraphTab(tab);
  };

  const handleGraphTable = (tab: number) => {
    props.setGraphTable(tab);
  };


  return (
    <div className="w-full">
      <div className=" flex justify-between items-center">
        <p
          className={`text-white font-medium text-1.3 text-center ${
            flag === 1 ? "" : "ml-3"
          } mb-1`}
        >
          {props?.title}
        </p>
        {flag !== 1 && (
          <ButtonToolbar className="pb-1 ">
            <ButtonGroup>
              <Button
                onClick={() => {
                  handleGraph(1);
                }}
                size="xs"
                appearance="ghost"
                className={`${
                  props?.graphTab === 1
                    ? "bg-green-500 text-white"
                    : "bg-white text-black"
                } border-black border-l-2 hover:bg-green-900 hover:text-white focus:bg-green-500 focus:text-white`}
              >
                Stock Out
              </Button>
              <Button
                onClick={() => handleGraph(2)}
                appearance="ghost"
                size="xs"
                className={`${
                  props?.graphTab === 2
                    ? "bg-green-500 text-white"
                    : "bg-white text-black"
                } border-black border-l-2  hover:bg-green-900 hover:text-white focus:bg-green-500 focus:text-white`}
              >
                Accuracy/Bias
              </Button>
              <Button
                onClick={() => {
                  handleGraph(3);
                }}
                appearance="ghost"
                size="xs"
                className={`${
                  props?.graphTab === 3
                    ? "bg-green-500 text-white"
                    : "bg-white text-black "
                } border-black  hover:bg-green-900 hover:text-white focus:bg-green-500 focus:text-white`}
              >
                Inventory
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        )}

        {flag === 1 && (
          <ButtonToolbar className="pb-1 mr-3">
            <ButtonGroup>
              <Button
                onClick={() => {
                  handleGraphTable(1);
                }}
                size="xs"
                appearance="ghost"
                className={`${
                  graphTable === 1
                    ? "bg-green-500 text-white"
                    : "bg-white text-black"
                } border-black border-l-2 hover:bg-green-900 hover:text-white focus:bg-green-500 focus:text-white`}
              >
                <TimelineIcon sx={{ fontSize: 20 }} />
              </Button>
              <Button
                onClick={() => handleGraphTable(2)}
                appearance="ghost"
                size="xs"
                className={`${
                  graphTable === 2
                    ? "bg-green-500 text-white"
                    : "bg-white text-black"
                } border-black border-l-2  hover:bg-green-900 hover:text-white focus:bg-green-500 focus:text-white`}
              >
                <TableChartIcon sx={{ fontSize: 20 }} />
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        )}
      </div>
      <ResponsiveContainer
        width={flag === 1 ? "98.5%" : "98.2%"}
        height={windowSize?.height /3.4}
        className={`border-2 border-mainGray ${flag === 1 ? "" : "ml-3"}`}
      >
        <LineChart
          margin={{
            top: 0,
            right: 50,
            left: 5,
            bottom: 0,
          }}
          layout="horizontal"
          data={props?.data}
        >
          <XAxis interval={flag!==4?1:2} fontSize={10} dataKey="forecastDate" type="category" stroke="#B5B5B5"/>
          
          {flag === 3 && <ReferenceLine y={0} stroke="#fff" />}
          {flag === 3 && (
            <YAxis
              allowDecimals={false}
              interval={1}
              ticks={[100,80, 60, 40, 20, 0, -20, -40, -60, -80,-100]}
              includeHidden
              type="number"
              stroke="#B5B5B5"
              fontSize={10}
              
            />
          )}

        {flag === 1 && (
            <YAxis
              allowDecimals={false}
              stroke="#B5B5B5"
              tickCount={10}
              // domain={[ (dataMin:any) => (dataMin *1), (dataMax:any) => (dataMax * 1)]}
              type="number"
              interval={1}
              fontSize={10}
              tickFormatter={(value: any) =>
                value && `${(formatAmount(value,0))}`
              }
            />
          )}

          {flag === 2&& (
            <YAxis
              allowDecimals={false}
              stroke="#B5B5B5"
              ticks={[0, 8, 16, 24, 31]}
              includeHidden
              type="number"
              fontSize={10}
            />
          )}
          {flag === 4&& (
            <YAxis
            fontSize={10}
              stroke="#B5B5B5"
              tickFormatter={(value: any) =>
                value && `${(formatAmount(value,0))}`
              }
              type="number"
            />
          )}

          {/* {flag === 3 && (
            <Tooltip formatter={(value) => value && `${value}%`} />
          )}
          {flag !== 3 && ( */}
          <Tooltip
            content={($) => <CustomTooltip data={$} flag={flag} />}
            // formatter={(value: number) => value && formatNumber(value)}
          />
          {/* )} */}
          {flag === 1 && (
            <Legend
              verticalAlign="top"
              content={
                <RenderLegend
                  onLegendChange={(data: any) => setLegendStatus(data)}
                />
              }
            />
          )}
          {flag !== 1 && <Legend verticalAlign="top" iconType="plainline"   wrapperStyle={{ paddingBottom: "10px" }}/>}
          {flag === 1 && (
            <Line
              name="CY Demand (Post outlier adj.)"
              dataKey="processedQty"
              stroke="#a4f504"
              fill="#a4f504"
              strokeWidth={2}
              dot={{ strokeWidth: 1,r:2 }}
            />
          )}
          {flag === 1 && (
            <Line
              name="CY Forecast"
              dataKey="toolforecast"
              stroke="#ffffff"
              fill="#ffffff"
              strokeWidth={2}
              dot={{ strokeWidth: 1,r:2  }}
            />
          )}
          {flag === 1 && (
            <Line
              name="Last Year Demand"
              dataKey="processedQtyLY"
              stroke="#0097A9"
              fill="#0097A9"
              strokeWidth={2}
              hide={legendStatus.includes(2)}
              dot={{ strokeWidth: 1,r:2  }}
            />
          )}
          {flag === 1 && (
            <Line
              name="Year-2 Demand"
              dataKey="processedQtyL2Y"
              stroke="#62B5E5"
              fill="#62B5E5"
              strokeWidth={2}
              hide={legendStatus.includes(3)}
              dot={{ strokeWidth: 1,r:2  }}
            />
          )}
          {flag === 2 && (
            <Line
              dataKey="stockout_days"
              stroke="#c04c4c"
              name="Stock Out"
              fill="#c04c4c"
              strokeWidth={2}
              dot={{ strokeWidth: 1,r:2  }}
            />
          )}
          {flag === 3 && (
            <Line
              dataKey="accuracy"
              stroke="#4ACB4A"
              fill="#4ACB4A"
              name="Accuracy"
              strokeWidth={2}
              dot={{ strokeWidth: 1,r:2  }}
            />
          )}
          {flag === 3 && (
            <Line
              dataKey="bias"
              stroke="#c7cf84"
              fill="#c7cf84"
              name="BIAS"
              strokeWidth={2}
              dot={{ strokeWidth: 1,r:2  }}
            />
          )}
          {flag === 4 && (
            <Line
              dataKey="onhand_inventory"
              stroke="#0097a9"
              fill="#0097a9"
              name="Inventory"
              strokeWidth={2}
              dot={{ strokeWidth: 1,r:2  }}
            />
          )}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

const RenderLegend = (props: any) => {
  const { payload } = props;

  const [legendStatus, setLegendStatus] = useState<any>([]);

  const handleLegend = (data: any) => {
    let legendindex = legendStatus;
    if (data !== 0 && data !== 1) {
      if (legendindex.includes(data)) {
        legendindex = legendindex.filter(($: any) => $ !== data);
        setLegendStatus(legendindex);
      } else {
        setLegendStatus([...legendStatus, data]);
      }
    }
  };

  useEffect(() => {
    props?.onLegendChange(legendStatus);
  }, [legendStatus]);

  return (
    <div className="flex justify-center mb-2">
      {payload.map((entry: any, index: number) => (
        <div
          onClick={() => {
            handleLegend(index);
          }}
          key={`item-${index}`}
          className={`ml-2 flex justify-between items-center ${
            index !== 1 && index !== 0 ? "cursor-pointer" : ""
          }`}
        >
          {/* <Checkbox defaultChecked  style={{color:entry?.color}}>
    </Checkbox> */}
          <hr
            className="w-3 mr-2 h-[2px] border-t-0"
            style={{
              backgroundColor: entry?.color,
              opacity: legendStatus.includes(index) ? 0.5 : 1,
            }}
          />
          <span
            style={{
              color: entry?.color,
              opacity: legendStatus.includes(index) ? 0.5 : 1,
            }}
            className="text-[0.8vw]"
          >
            {" "}
            {entry?.value}
            {index > 1 && (
              <Whisper
                trigger="hover"
                placement={"top"}
                controlId={`control-id-top`}
                speaker={
                  <Popover {...props}>
                    <p>Click on the legend to show/hide graph line </p>
                  </Popover>
                }
              >
                <InfoOutlinedIcon
                  style={{ color: entry?.color }}
                  className=" ml-1 top-[-2px] relative"
                  // sx={{fontSize: { xs: 30, sm: 40, md: 50, lg: 60 } }}
                  sx={{ fontSize: 15 }}
                />
              </Whisper>
            )}
          </span>
        </div>
      ))}
    </div>
  );
};

const CustomTooltip = (props: any) => {
  const { active, payload } = props.data;
  const { flag } = props;
  if (active && payload && payload.length) {
    return (
      <>
        <div className="bg-[#353535] p-3">
          {flag === 1 && (
            <>
              <p className="text-white text-0.95vw">
                {payload[0]?.payload?.forecastDate}
              </p>
              {payload?.map(($: any) => {
                return (
                  <>
                    <p className="text-white text-0.7vw">
                      {$?.name} : {formatNumber($?.value)}
                    </p>
                  </>
                );
              })}
            </>
          )}
          {flag === 2 && (
            <>
              <p className="text-white text-0.95vw">
                {payload[0]?.payload?.forecastDate}
              </p>
              {payload?.map(($: any) => {
                return (
                  <>
                    <p className="text-white text-0.7vw">
                      {$?.name} : {$?.value}
                    </p>
                  </>
                );
              })}
            </>
          )}
          {flag === 3 && (
            <>
              <p className="text-white text-0.95vw">
                {payload[0]?.payload?.forecastDate}
              </p>
              {payload?.map(($: any) => {
                return (
                  <>
                    <p className="text-white text-0.7vw">
                      {$?.name} : {$?.value}%
                    </p>
                  </>
                );
              })}
            </>
          )}
          {flag === 4 && (
            <>
              <p className="text-white text-0.95vw">
                {payload[0]?.payload?.forecastDate}
              </p>
              {payload?.map(($: any) => {
                return (
                  <>
                    <p className="text-white text-0.7vw">
                      {$?.name} : {formatNumber($?.value)}
                    </p>
                  </>
                );
              })}
            </>
          )}
        </div>
      </>
    );
  }

  return null;
};

export default ForecastGraph;
