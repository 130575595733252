import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { DUMMY } from "../../constants/dataConstant";
import { useSelector } from "react-redux";

const BarGraph = (props: any) => {

  const {barGraphData}=props

  const windowSize = useSelector(
    (state: any) => state.CommonReducer.windowSize
  );

  return (
    <div className="w-full">
      <span className="text-white font-medium text-0.95vw ">Accuracy</span>
    <ResponsiveContainer
      width={"100%"}
      height={windowSize?.width / 7}
      className={`border-[1px] border-mainGray`}
    >
      <BarChart
        width={500}
        height={300}
        data={barGraphData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis fontSize={10} stroke="#ffffff" dataKey="Plant_Code" />
        <YAxis fontSize={10} stroke="#ffffff" dataKey="weightedAccuracy" tickFormatter={(value: string) => value && `${parseFloat(value)*100}%`}/>
        <Tooltip cursor={{fill: 'transparent'}} content={($) => <CustomTooltip data={$} />} />
        <Legend verticalAlign="top" />
        <Bar  name="Accuracy" dataKey="weightedAccuracy"  barSize={60} fill="#4ACB4A" >
        <LabelList dataKey="weightedAccuracy" content={renderCustomizedLabel} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
    </div>
  );
};

const renderCustomizedLabel = (props: any) => {
  const { x, y, width, value,name } = props;
console.log(props)
  return (
    <>
      <text x={x + width / 2} y={y- (value>0?10:-10) } fill='#fff' textAnchor="middle" dominantBaseline="middle" >
        {value.toFixed(2)*100}%
      </text>
    </>
  );
};

const CustomTooltip = (props: any) => {
  const { active, payload } = props.data;
  if (active && payload && payload.length) {
    return (
      <>
        <div className="bg-[#353535] p-3">
          <>
            <p className="text-white text-0.95vw">
              {payload[0]?.payload?.Plant_Code}
            </p>
            {payload?.map(($: any) => {
              return (
                <>
                  <p className="text-white text-0.7vw">
                    {$?.name} : {(parseFloat($?.value) * 100).toFixed(2)}%
                  </p>
                </>
              );
            })}

          </>
        </div>
      </>
    );
  }

  return null;
};

export default BarGraph;
