import React, { useEffect, useState } from "react";
import LineGraph from "../../components/forecastSummary/lineGraph";
import BarGraph from "../../components/forecastSummary/barGraph";
import SummaryTable from "../../components/forecastSummary/summaryTable";
import {
  getAccuracySummaryData,
  getForecastSummaryChartsData,
  getForecastSummarySKUlData,
} from "../../queries/apiQueries";
import moment from "moment";
import { Loader } from "rsuite";
import { useSelector } from "react-redux";
import WaterfallGraph from "../../components/forecastSummary/waterfallGraph";

const ForecastSummary = (props: any) => {
  const [graphData, setGraphData] = useState<any>(null);
  const [barGraphData, setBarGraphData] = useState<any>(null);
  const [waterFallData, setWaterFallData] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const token = useSelector((state: any) => state.CommonReducer.token);
  const selectedPlantCode = useSelector(
    (state: any) => state.CommonReducer.selectedPlantCode
  );

  const getForecastSummaryGraphData = () => {
    getForecastSummaryChartsData(selectedPlantCode)
      .then((response: any) => {
        if (response?.status === 200) {
          let sorted = response?.data?.sdata.sort(
            (a: any, b: any) => b.forecast_date - a.forecast_date
          );
          sorted.reverse();
          if (sorted?.length !== 0) {
            let isFuture = 0;
            sorted.forEach((element: any) => {

              if(isFuture<6){
                element.forecastDate = moment(
                  element?.forecast_date?.slice(0, 10)
                ).format("MMM'YY");
              }else{
                element.toolforecast=null
              }
              // element.accuracy=element?.accuracy ? (element?.accuracy*100).toFixed(2):0
              // element.bias=element?.bias?(element?.bias*100).toFixed(2):0
              if (
                moment(new Date().setMonth(new Date().getMonth())).format(
                  "MMM YYYY"
                ) ===
                  moment(element?.forecast_date?.slice(0, 10)).format(
                    "MMM YYYY"
                  ) ||
                isFuture!==0
              ) {
                isFuture +=1;
                element.processedQty = null;
                element.processedQtyLY = null;
              }
            });
          }
          let filteredData=sorted?.filter((val:any)=>val?.toolforecast)
          setGraphData(filteredData);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getForecastSummarySKUlTabelData = () => {
    getForecastSummarySKUlData(selectedPlantCode)
      .then((response: any) => {
        if (response?.status === 200) {
          let data=response?.data?.sdata
          let obj = [
            {
              name: moment((data?.lastDate).slice(0,10)).format('MMM YYYY'),
              uv: data?.lastMonth,
              pv: 0,
            },
            {
              name: "Dropped",
              uv: data?.dropped,
              pv: data?.lastMonth,
            },
            {
              name: "Addition",
              uv: data?.addition,
              pv:data?.lastMonth+data?.dropped,
            },
            {
              name: moment((data?.currentDate).slice(0,10)).format('MMM YYYY'),
              uv: data?.currMonth,
              pv: 0,
            },
          ];

          setWaterFallData(obj);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getAccuracySummaryGraphData = () => {
    getAccuracySummaryData(selectedPlantCode)
      .then((response: any) => {
        if (response?.status === 200) {
          setBarGraphData(response?.data?.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    if (token) {
      getForecastSummaryGraphData();
      getForecastSummarySKUlTabelData();
      getAccuracySummaryGraphData();
    }
  }, [token, selectedPlantCode]);

  return (
    <>
      <div className=" mt-5">
        {loading && (
          <Loader backdrop size="md" inverse center content="loading..." />
        )}

        <div className="w-full flex justify-center">
          <div className="w-[95%]">
            <div className=" grid grid-cols-2 gap-6">
              <BarGraph barGraphData={barGraphData} />
              {/* <SummaryTable tableData={tableData} /> */}
              <WaterfallGraph waterfallGraph={waterFallData} />
            </div>
            <div className=" flex justify-center items-center">
              <div className="w-full mt-5">
                <LineGraph graphData={graphData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForecastSummary;
