import React from "react";
import "./App.css";
import {
  AuthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { InteractionStatus, IPublicClientApplication } from "@azure/msal-browser";
import Router from "./pages/router";
import { CustomProvider } from "rsuite";
import { loginRequest } from "./services/authConfig";

type AppProps = {
  pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {

  const { instance,inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  // useEffect(() => {
    if (inProgress === InteractionStatus.None && !isAuthenticated) {
      localStorage.setItem('authHandler','1')
      instance.loginRedirect(loginRequest)
        .catch((error) => console.log(error));
    }
  // },[pca])

  return (
    // <MsalProvider instance={pca}>
      <AuthenticatedTemplate>
        <CustomProvider theme="light">
          <Router />
        </CustomProvider>
       </AuthenticatedTemplate>
    // </MsalProvider>
  );
}

export default App;
