import React, { useEffect, useRef, useState } from "react";
import { CheckPicker, SelectPicker, TagPicker } from "rsuite";
import { getGBPFilters, getInventoryFilters, getPotentialActionFilter } from "../../queries/apiQueries";
import { useDispatch, useSelector } from "react-redux";
import { GBPFilters } from "../../model/forecastModel";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  ALERT_TABS,
  HIGHSTOCKOUT_ALERT_DATA,
  PO_ALERT_DATA,
  STATUS_DATA,
} from "../../constants/dataConstant";
import { TABLEFILTER } from "../forecast/forecastDataTable";

export const FILTERDATA = "FILTERDATA";
export const PLANTCODEFILTER = "PLANTCODEFILTER";

const Filters = (props: any) => {
  const dispatch = useDispatch<any>();
  let location = useLocation();

  const alertType = new URLSearchParams(location.search).get("alert");
  const type = new URLSearchParams(location.search).get("type");

  const gbpfilter = useSelector((state: any) => state.CommonReducer.gbpfilter);
  const tableSelection = useSelector(
    (state: any) => state.CommonReducer.tableSelection
  );
  const token = useSelector((state: any) => state.CommonReducer.token);
  const [filterData, setFilterData] = useState<GBPFilters>();
  const [firstHit, setFirstHit] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [routeType, setRouteType] = useState("");
  const [selectedPlantCode, setSelectedPlantCode] = useState({
    clustername: [],
  });
  const [selectedPOACTION, setSelectedPOACTION] = useState({
    poaction: [],
  });
  const [selectedFilterData, setSelectedFilterData] = useState<GBPFilters>({
    plant: [],
    l2: [],
    l3: [],
    materialDescription: [],
    material: [],
    mrpType: [],
    futureSegmentation: [],
    vendorName: [],
    clustername: [],
    alerts: [],
    reviewedFlag: [],
    poAlert: [],
    adjustedLogic: [],
    poType: [],
    pfxpg: [],
    poaction :[]
  });

  const didMount = useRef(false);
  console.log(selectedFilterData,123);
  const getGBPFilter = async () => {
    await dispatch(getGBPFilters(selectedFilterData));
  };

  const getInventoryFilter = async () => {
    await dispatch(getInventoryFilters(selectedFilterData));
  };

  const getPotentialActionFilterData = (payload : any) =>{
    getPotentialActionFilter(payload).then((response : any)=>{
      setSelectedPOACTION({ poaction: response?.data?.data });
    })
    
  }

  const getRecommendedFilterData = async () => {
    let payload = { pageName: "" }; 
    let locationSplit = location?.pathname?.split("/");
    if (
      locationSplit?.includes("inactive-inventory-managment") &&
      type === "2"
    ) {
      payload.pageName = "AgeingInactive"; 
    } else if (
      locationSplit?.includes("inactive-inventory-managment") &&
      type === "0"
    ) {
      payload.pageName = "PotentialInactive"; 
    }else if (
      locationSplit?.includes("inactive-inventory-managment") &&
      type === "1"
    ){
      payload.pageName = "UntaggedInactive"; 
    }
  
    (getPotentialActionFilterData(payload));
  }

  useEffect(() => {
    setLoading(true);
    if (token) {
      if (routeType === "inventory") {
        getInventoryFilter().then(() => {
          setLoading(false);
        });
      } else if (routeType === "forecast") {
        getGBPFilter().then(() => {
          setLoading(false);
        });
      }
    }
  }, [selectedFilterData, token, routeType]);

  useEffect(() => {
    if (location?.pathname?.split("/")?.includes("inventory")) {
      setRouteType("inventory");
    } else if (location?.pathname?.split("/")?.includes("forecast")) {
      setRouteType("forecast");
    }
    if (
      location?.pathname === "/" ||
      location?.pathname?.split("/")?.includes("inventory-alerts") ||
      location?.pathname?.split("/")?.includes("forecast-alerts")
    ) {
      props?.onFilterChange({
        plant: [],
        l2: [],
        l3: [],
        materialDescription: [],
        material: [],
        mrpType: [],
        futureSegmentation: [],
        vendorName: [],
        clustername: [],
        poaction :[],
        alerts: [],
        reviewedFlag: [],
        poAlert: [],
        adjustedLogic: [],
        poType: [],
        pfxpg: [],
      });
      props?.onRowChange();
    }
    
  }, [location]);


  useEffect(() => {
    if(location?.pathname?.split("/").includes("inactive-inventory-managment")){
      getRecommendedFilterData();
    }
  }, [location])
  

  useEffect(() => {
    setFilterData(gbpfilter);
  }, [gbpfilter]);

  useEffect(() => {
    console.log(alertType);
    if (alertType) {
      setSelectedFilterData({ ...selectedFilterData, alerts: [alertType] });
      setFirstHit(firstHit + 1);
    } else {
      setSelectedFilterData({ ...selectedFilterData, alerts: [] });
    }
  }, [alertType]);

  const updateFilterState = () => {
    setFirstHit(firstHit + 1);
  };

  useEffect(() => {
    if (firstHit !== 0) {
      props?.onFilterChange(selectedFilterData);
      props?.onPlantCodeChange(selectedPlantCode);
    }
  }, [firstHit]);

  useEffect(() => {
    if (didMount.current) {
      let plant: any = [],
        material: any = [];

      if (tableSelection?.length !== 0) {
        tableSelection.forEach((element: any) => {
          plant.push(element?.plant);
          material.push((element?.material).toString());
        });
      }
      console.log( location?.pathname?.split("/"));
      
      setSelectedFilterData({
        ...selectedFilterData,
        plant: plant,
        material: material,
        poaction:  location?.pathname?.split("/")?.includes("inventory-alerts") &&location?.pathname?.split("/")?.length == 3 ? [] : selectedFilterData.poaction,
      });
      console.log(plant, material, 123);
      updateFilterState();
    } else {
      didMount.current = true;
    }
  }, [tableSelection]);

  return (
    <>
      {location.pathname !== "/" && !location.pathname?.split("/")?.includes("inventory-reports") && (
        <div
          className={`px-[3vw] grid ${
            location?.pathname?.split("/")?.includes("forecast-overview")
              ? "grid-cols-11"
              : location?.pathname?.split("/")?.includes("inventory")
              ? location?.pathname
                  ?.split("/")
                  ?.includes("po-inventory-managment") ||  location?.pathname
                  ?.split("/")
                  ?.includes("inactive-inventory-managment") ||
                (location?.pathname
                  ?.split("/")
                  ?.includes("overstock-inventory-managment") &&
                  type === "0")
                ? "grid-cols-8"
                : "grid-cols-7"
              : "grid-cols-10"
          }  gap-2 w-full`}
        >
          {location?.pathname?.split("/")?.includes("forecast-summary") && (
            <div className="w-full">
              <p className="text-mainGreen  text-0.95vw">SC Group</p>
              <CheckPicker
                onChange={(data: any) => {
                  setSelectedPlantCode({ clustername: data });
                  updateFilterState();
                }}
                placeholder="All"
                loading={loading}
                className="w-[99%]"
                data={filterData?.clustername}
                labelKey="plantcode"
                valueKey="cluster"
                size="sm"
              />
            </div>
          )}

          {!location?.pathname?.split("/")?.includes("forecast-summary") &&
            location?.pathname?.split("/")?.includes("forecast") && (
              <>
                <div className="w-full">
                  <p className="text-mainGreen  text-0.95vw">SC Group</p>
                  <CheckPicker
                    onChange={(data: any) => {
                      setSelectedFilterData({
                        ...selectedFilterData,
                        clustername: data,
                      });
                      updateFilterState();
                    }}
                    placeholder="All"
                    loading={loading}
                    className="w-[99%]"
                    data={filterData?.clustername}
                    labelKey="plantcode"
                    valueKey="cluster"
                    size="sm"
                  />
                </div>
                <div className="w-full">
                  <p className="text-mainGreen  text-0.95vw">Plant</p>
                  <CheckPicker
                    size="sm"
                    virtualized
                    placeholder="All"
                    loading={loading}
                    onChange={(data: any) => {
                      setSelectedFilterData({
                        ...selectedFilterData,
                        plant: data,
                      });
                      updateFilterState();
                    }}
                    className="w-[99%]"
                    data={filterData?.plant}
                    labelKey="plant"
                    valueKey="plant"
                    value={selectedFilterData?.plant}
                  />
                </div>
                <div className="w-full">
                  <p className="text-mainGreen  text-0.95vw">PFXPG</p>
                  <CheckPicker
                    size="sm"
                    virtualized
                    placeholder="All"
                    loading={loading}
                    onChange={(data: any) => {
                      setSelectedFilterData({
                        ...selectedFilterData,
                        pfxpg: data,
                      });
                      updateFilterState();
                    }}
                    className="w-[99%]"
                    data={filterData?.pfxpg}
                    labelKey="PFXPG"
                    valueKey="PFXPG"
                  />
                </div>
                <div className="w-full">
                  <p className="text-mainGreen  text-0.95vw">Category (L2)</p>
                  <CheckPicker
                    size="sm"
                    placeholder="All"
                    loading={loading}
                    onChange={(data: any) => {
                      setSelectedFilterData({
                        ...selectedFilterData,
                        l2: data,
                      });
                      updateFilterState();
                    }}
                    className="w-full"
                    data={filterData?.l2}
                    labelKey="L2"
                    valueKey="L2"
                  />
                </div>
                <div className="w-full">
                  <p className="text-mainGreen  text-0.95vw">
                    Sub Category (L3)
                  </p>
                  <CheckPicker
                    size="sm"
                    placeholder="All"
                    loading={loading}
                    onChange={(data: any) => {
                      setSelectedFilterData({
                        ...selectedFilterData,
                        l3: data,
                      });
                      updateFilterState();
                    }}
                    className="w-[99%]"
                    data={filterData?.l3}
                    labelKey="L3"
                    valueKey="L3"
                  />
                </div>
                <div className="w-full">
                  <p className="text-mainGreen  text-0.95vw">Material</p>
                  <CheckPicker
                    size="sm"
                    virtualized
                    placeholder="All"
                    loading={loading}
                    onChange={(data: any) => {
                      setSelectedFilterData({
                        ...selectedFilterData,
                        material: data,
                      });
                      updateFilterState();
                    }}
                    className="w-full"
                    data={filterData?.material}
                    labelKey="material"
                    valueKey="material"
                    value={selectedFilterData?.material}
                  />
                </div>
                <div className="w-full">
                  <p className="text-mainGreen  text-0.95vw">MRP Group</p>
                  <CheckPicker
                    size="sm"
                    virtualized
                    placeholder="All"
                    loading={loading}
                    onChange={(data: any) => {
                      setSelectedFilterData({
                        ...selectedFilterData,
                        mrpType: data,
                      });
                      updateFilterState();
                    }}
                    className="w-[99%]"
                    data={filterData?.mrpType}
                    labelKey="mrpType"
                    valueKey="mrpType"
                  />
                </div>
                <div className="w-full">
                  <p className="text-mainGreen  text-0.95vw">
                    ABC Segmentation
                  </p>
                  <CheckPicker
                    size="sm"
                    virtualized
                    placeholder="All"
                    loading={loading}
                    className="w-full "
                    onChange={(data: any) => {
                      setSelectedFilterData({
                        ...selectedFilterData,
                        futureSegmentation: data,
                      });
                      updateFilterState();
                    }}
                    data={filterData?.futureSegmentation}
                    labelKey="futureSeg"
                    valueKey="futureSeg"
                  />
                </div>
                <div className="w-full">
                  <p className="text-mainGreen  text-0.95vw">Vendor Number</p>
                  <CheckPicker
                    size="sm"
                    placeholder="All"
                    loading={loading}
                    className="w-full"
                    onChange={(data: any) => {
                      setSelectedFilterData({
                        ...selectedFilterData,
                        vendorName: data,
                      });
                      updateFilterState();
                    }}
                    data={filterData?.vendorName}
                    labelKey="vendor"
                    valueKey="vendor"
                  />
                </div>
                {location?.pathname
                  ?.split("/")
                  ?.includes("forecast-overview") && (
                  <div className="w-full">
                    <p className="text-mainGreen  text-0.95vw">Alerts</p>
                    <CheckPicker
                      size="sm"
                      placeholder="All"
                      className="w-full"
                      onChange={(data: any) => {
                        setSelectedFilterData({
                          ...selectedFilterData,
                          alerts: data,
                        });
                        updateFilterState();
                      }}
                      value={selectedFilterData?.alerts}
                      data={ALERT_TABS}
                    />
                  </div>
                )}
                <div className="w-full">
                  <p className="text-mainGreen  text-0.95vw">Alert's Status</p>
                  <SelectPicker
                    size="sm"
                    virtualized
                    placeholder="All"
                    loading={loading}
                    onChange={(data: any) => {
                      setSelectedFilterData({
                        ...selectedFilterData,
                        reviewedFlag: data !== null ? [data] : [],
                      });
                      updateFilterState();
                    }}
                    className="w-full"
                    data={STATUS_DATA}
                    labelKey="label"
                    valueKey="value"
                  />
                </div>
              </>
            )}

          {location?.pathname?.split("/")?.includes("inventory") && (
            <>
              <div className="w-full">
                <p className="text-mainGreen  text-0.95vw">Plant Code</p>
                <CheckPicker
                  onChange={(data: any) => {
                    setSelectedFilterData({
                      ...selectedFilterData,
                      clustername: data,
                    });
                    updateFilterState();
                  }}
                  placeholder="All"
                  loading={loading}
                  className="w-[99%]"
                  data={filterData?.clustername}
                  labelKey="plantcode"
                  valueKey="plantcode"
                  size="sm"
                />
              </div>
              <div className="w-full">
                <p className="text-mainGreen  text-0.95vw">Plant</p>
                <CheckPicker
                  size="sm"
                  virtualized
                  placeholder="All"
                  loading={loading}
                  onChange={(data: any) => {
                    setSelectedFilterData({
                      ...selectedFilterData,
                      plant: data,
                    });
                    updateFilterState();
                  }}
                  className="w-[98%]"
                  data={filterData?.plant}
                  labelKey="plant"
                  valueKey="plant"
                  value={selectedFilterData?.plant}
                />
              </div>
              <div className="w-full">
                <p className="text-mainGreen  text-0.95vw">Category (L2)</p>
                <CheckPicker
                  size="sm"
                  placeholder="All"
                  loading={loading}
                  onChange={(data: any) => {
                    setSelectedFilterData({ ...selectedFilterData, l2: data });
                    updateFilterState();
                  }}
                  className="w-full"
                  data={filterData?.l2}
                  labelKey="L2"
                  valueKey="L2"
                />
              </div>
              <div className="w-full">
                <p className="text-mainGreen  text-0.95vw">Material</p>
                <CheckPicker
                  size="sm"
                  virtualized
                  placeholder="All"
                  loading={loading}
                  onChange={(data: any) => {
                    setSelectedFilterData({
                      ...selectedFilterData,
                      material: data,
                    });
                    updateFilterState();
                  }}
                  className="w-full"
                  data={filterData?.material}
                  labelKey="material"
                  valueKey="material"
                  value={selectedFilterData?.material}
                />
              </div>
              <div className="w-full">
                <p className="text-mainGreen  text-0.95vw">MRP Type</p>
                <CheckPicker
                  size="sm"
                  virtualized
                  placeholder="All"
                  loading={loading}
                  onChange={(data: any) => {
                    setSelectedFilterData({
                      ...selectedFilterData,
                      mrpType: data,
                    });
                    updateFilterState();
                  }}
                  className="w-[99%]"
                  data={filterData?.mrpType}
                  labelKey="mrpType"
                  valueKey="mrpType"
                />
              </div>
              <div className="w-full">
                <p className="text-mainGreen  text-0.95vw">ABC Segmentation</p>
                <CheckPicker
                  size="sm"
                  virtualized
                  placeholder="All"
                  loading={loading}
                  className="w-full "
                  onChange={(data: any) => {
                    setSelectedFilterData({
                      ...selectedFilterData,
                      futureSegmentation: data,
                    });
                    updateFilterState();
                  }}
                  data={filterData?.futureSegmentation}
                  labelKey="futureSeg"
                  valueKey="futureSeg"
                />
              </div>
              <div className="w-full">
                <p className="text-mainGreen  text-0.95vw">Vendor Name</p>
                <CheckPicker
                  size="sm"
                  placeholder="All"
                  loading={loading}
                  className="w-full"
                  onChange={(data: any) => {
                    setSelectedFilterData({
                      ...selectedFilterData,
                      vendorName: data,
                    });
                    updateFilterState();
                  }}
                  data={filterData?.vendorName}
                  labelKey="vendor"
                  valueKey="vendor"
                />
              </div>
              {location?.pathname
                ?.split("/")
                ?.includes("po-inventory-managment") && (
                <div className="w-full">
                  <p className="text-mainGreen  text-0.95vw">PO Type</p>
                  <SelectPicker
                    size="sm"
                    virtualized
                    placeholder="All"
                    // loading={loading}
                    onChange={(data: any) => {
                      setSelectedFilterData({
                        ...selectedFilterData,
                        poAlert: data !== null ? [data] : [],
                      });
                      updateFilterState();
                    }}
                    className="w-full"
                    data={filterData?.poType}
                    labelKey="label"
                    valueKey="value"
                  />
                </div>
              )}
              {location?.pathname
                ?.split("/")
                ?.includes("overstock-inventory-managment") &&
                type === "0" && (
                  <div className="w-full">
                    <p className="text-mainGreen  text-0.95vw">
                      Adjusted ORQ Logic
                    </p>
                    <SelectPicker
                      size="sm"
                      virtualized
                      placeholder="All"
                      loading={loading}
                      onChange={(data: any) => {
                        setSelectedFilterData({
                          ...selectedFilterData,
                          adjustedLogic: data !== null ? [data] : [],
                        });
                        updateFilterState();
                      }}
                      className="w-full"
                      data={HIGHSTOCKOUT_ALERT_DATA}
                      labelKey="label"
                      valueKey="value"
                    />
                  </div>
                )}
              {location?.pathname
                ?.split("/")
                ?.includes("inactive-inventory-managment") && (
                <div className="w-full">
                  <p className="text-mainGreen  text-0.95vw">Potential Actions</p>
                  <SelectPicker
                    size="sm"
                    placeholder="All"
                    loading={loading}
                    onChange={(data: any) => {
                      console.log(data);
                      setSelectedFilterData({
                        ...selectedFilterData,
                        poaction: data !== null ? [data] : [],
                      });
                      updateFilterState();
                    }}
                    className="w-full"
                    data={selectedPOACTION.poaction}
                    labelKey="RecommendedAction"
                    valueKey="RecommendedAction"
                  />
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  onFilterChange: (data: any) => dispatch({ type: FILTERDATA, data: data }),
  onPlantCodeChange: (data: any) =>
    dispatch({ type: PLANTCODEFILTER, data: data }),
  onRowChange: (data: any = []) => dispatch({ type: TABLEFILTER, data: [] }),
});

export default connect(null, mapDispatchToProps)(Filters);
