import axios from "axios";
import {  LOCAL_API_URL, SUBSCRIPTION_KEY, } from "../config/config";

class QueryService {

    retryCount = 5;
    private _apiEndpoint = LOCAL_API_URL;

    constructor(API: string = LOCAL_API_URL) {
        this._apiEndpoint = API;
    }

    getConfig = () => {
        return {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                // "Authorization" : "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6ImpTMVhvMU9XRGpfNTJ2YndHTmd2UU8yVnpNYyIsImtpZCI6ImpTMVhvMU9XRGpfNTJ2YndHTmd2UU8yVnpNYyJ9.eyJhdWQiOiIzMmRkMzQzNy03ZTY2LTQzNjUtODI1NS0yMmQxMTA2MTU2ZDgiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC83ZWI1NTMxNC1iMGJhLTQ3YWItOTZjOS00OWNkOTQ4YTcyZWUvIiwiaWF0IjoxNjQ4NTI4NzUyLCJuYmYiOjE2NDg1Mjg3NTIsImV4cCI6MTY0ODUzMjY1MiwiYWlvIjoiQVhRQWkvOFRBQUFBYUorNWwxbE0wOHhzV3dKUWJWOTR1M2IrcERISXBVd3IwNXU4YUxSZFB2NW81Y3pLNklubnUxZ0Z4aERjNU9RUkRHdXRRd3FyeHV2amRFdUtaTjlHRTQrUU8rcFhsSlcyMk9NZTFYaWZGS0NXdkdLRXNqR1h4UGRDWGpqRlBORDN0enlOT091ZWJ6alh6dW1XOFM2ZDBnPT0iLCJhbXIiOlsicHdkIiwicnNhIl0sImVtYWlsIjoiZ211cnVnZXNhbkBkZWxvaXR0ZS5jb20iLCJmYW1pbHlfbmFtZSI6Ik11cnVnZXNhbiIsImdpdmVuX25hbWUiOiJHb3d0aGFtIiwiaWRwIjoiaHR0cHM6Ly9zdHMud2luZG93cy5uZXQvMzZkYTQ1ZjEtZGQyYy00ZDFmLWFmMTMtNWFiZTQ2Yjk5OTIxLyIsImlwYWRkciI6IjExNy4yMDMuNzAuNCIsIm5hbWUiOiJNdXJ1Z2VzYW4sIEdvd3RoYW0iLCJub25jZSI6ImVkZTQ1MjIwLWI3YjgtNDcwYi04NDI2LTRkMzY2ZGNjNjZjYyIsIm9pZCI6ImYzOGQwMDAzLWMxMDQtNGU0ZS04Y2ZlLTJjOTE1OWE2ODRlOCIsInJoIjoiMC5BU1lBRkZPMWZycXdxMGVXeVVuTmxJcHk3amMwM1RKbWZtVkRnbFVpMFJCaFZ0Z1ZBSHcuIiwic3ViIjoibmZlU0FoUndlZEwxYjhiaWtZQ3lHM29QcUFjOHZTRm1ja0ZFZHNpdC0wSSIsInRpZCI6IjdlYjU1MzE0LWIwYmEtNDdhYi05NmM5LTQ5Y2Q5NDhhNzJlZSIsInVuaXF1ZV9uYW1lIjoiZ211cnVnZXNhbkBkZWxvaXR0ZS5jb20iLCJ1dGkiOiJZVThGNEFJU1cwT0l4eVAyV2FnTUFBIiwidmVyIjoiMS4wIn0.QGTeRi430VxX4tnhNhsRy1PURqXETAp8Gh9KsPuWk5Ravy1_ApHj79Gr5Cvs1C-wkZMDnpiOWtV8NAKycr2VrHRgTj9wEUdnC9eSjinjmD2iWOkc10ReLO-IumADy5f5BDEoLjEBSh0qFPPZ8vo2PKG3klm1gmxcp35lQbfpogtErcng_B3tNJErZW0hFuGNvjrELBc3NWeVBLY_M-SPRLw8KOs11YSqVd024ara1_STpGtV__yH1JclUknvpDSIV3TRPlJQxFv3Ri1KXNhNB6UJ6aDu7LjwXzia_YwX9MwxpkCPr4bMbm6KnuZ9ZNQl-Ta8T-AiBiXT9gYUjkdxeA",
                "Authorization" : `Bearer ${localStorage.getItem("accessToken")}`,
                "Ocp-Apim-Subscription-Key" : SUBSCRIPTION_KEY,
            },
            data: {},
        };
    }

    /**
     * Base query to make calls to the backend
     * @param {string} relativeURL - the extended URL to be called
     * @param {string} method - the http method to be executed - default to GET
     * @param {any} payload - the payload in cases of post request
     * @param {any} callback - the callback method that is optional in case, needs to be executed after the call
     * @param {any} configOverWrite - any overwite of the default configs
     *
     * @returns {Promise} - a promise object for resolving from the caller function
     */
    runQuery: any = (
        relativeURL: string,
        method: string = "GET",
        payload: any = {},
        callback: any = () => { },
        configOverWrite: any = {},
    ) => {
        const config = { ...this.getConfig(), ...configOverWrite };
        // Initializing the promise
        let axiosPromise;
        if (method === "GET") {
            axiosPromise = axios.get(this._apiEndpoint + relativeURL, config);
        } else if (method === "POST") {
            axiosPromise = axios.post(this._apiEndpoint + relativeURL, payload, config);
        } else if (method === "PUT") {
            axiosPromise = axios.put(this._apiEndpoint + relativeURL, payload, config);
        } else if (method === "DELETE") {
            axiosPromise = axios.delete(this._apiEndpoint + relativeURL, config);
        } else {
            axiosPromise = new Promise((resolve, reject) => {
                reject("Unknown method used to make API call");
            });
        }

        return axiosPromise.then((data: any) => {
            if (callback) {
                callback();
            }
            return data;
        })
            .catch((err: Error) => {
                let errorMessage = "Error in Request";
                if (err.message === "Network Error") {
                    const networErrorDisplayMessage = `
                Not connected to the server.
                Please check your internet connection.
                Or, contact the system administrator`;
                    // No Connection to the server
                    errorMessage = err.message;
                    // alert(networErrorDisplayMessage);
                } else if (err.message.indexOf("401") > -1) {
                    localStorage.removeItem("JWT_Token");
                    // window.location.reload();
                }
                throw new Error(errorMessage);
            });
    }
}

export default QueryService;
