import React, { useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Notification,
  Pagination,
  Table,
  useToaster,
} from "rsuite";
import { PO_INV_TABLE } from "../../constants/dataConstant";
import {
  getPotentialOverstockData,
  updateReviewedInventoryData,
} from "../../queries/apiQueries";
import { CSVLink } from "react-csv";
import { TABLEFILTER } from "../forecast/forecastDataTable";
import { formatNumber } from "../../utils/commonFunctions";
import { Tooltip } from "@mui/material";
import { Info } from "@mui/icons-material";
import moment from "moment";

const { Column, HeaderCell, Cell } = Table;

const leftStick = [
  "plant",
  "material",  
];
const rightStick = [
  "isReviewed",
  "RecommendedDate",
  "RecommendedAction",
];

const POInventoryTable = (props: any) => {
  const windowSize = useSelector(
    (state: any) => state.CommonReducer.windowSize
  );
  const toaster = useToaster();
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [tableData, setTableData] = useState(null);
  const [count, setCount] = useState(0);
  const [exportAllData, setExportALLData] = useState<any>([]);
  const [isExport, setIsExport] = useState(false);
  const exportFullRef = useRef<any>(null);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [selectedRowData, setSelectedRowData] = useState<any>([]);
  const didMount = useRef(false);
  const [coundtHandler, setCountHandler] = useState(0);

  const selectedGBPFilter = useSelector(
    (state: any) => state.CommonReducer.selectedGBPFilter
  );

  const getPotentialOverstockDataHandler = () => {
    if (isExport) {
      toaster.push(message, { placement: "topCenter" });
    } else {
      setLoading(true);
    }
    let payload = {
      ...selectedGBPFilter,
      pageNumber: pageNumber || 1,
      pageSize: 100,
      isExport: isExport,
    };

    getPotentialOverstockData(payload)
      .then((response: any) => {
        if (response?.status === 200) {
          if (!isExport) {
            setTableData(response?.data?.data);
            setCount(response?.data?.count);
          } else {
            prepareExportData(response?.data?.data);
          }
        }

        setLoading(false);
        setIsExport(false);
      })
      .catch((err) => {
        setLoading(false);
        setIsExport(false);
        console.log(err);
      });
  };

  useEffect(() => {
    if (
      pageNumber &&
      Object.keys(selectedGBPFilter).length !== 0 &&
      selectedRow?.length === 0
    ) {
      getPotentialOverstockDataHandler();
    }
  }, [pageNumber, selectedGBPFilter]);

  useEffect(() => {
    if (isExport) {
      getPotentialOverstockDataHandler();
    }
  }, [isExport]);

  useEffect(() => {
    if (exportAllData?.length !== 0) {
      exportFullRef?.current?.link.click();
    }
  }, [exportAllData]);

  const handleRowSelection = (data: any) => {
    let index = selectedRow.indexOf(data?.SKUl);
    if (index !== -1) {
      // selectedRow.splice(index, 1);
      setSelectedRow([]);
      setSelectedRowData([]);
      props?.selectedRow(null)
    } else {
      setSelectedRow([data?.SKUl]);
      setSelectedRowData([data]);
      props?.selectedRow(data)
    }
  };

  useEffect(() => {
    if (didMount.current) {
      if (coundtHandler >= 1) {
        props?.onRowChange(selectedRowData);
      } else {
        setCountHandler(coundtHandler + 1);
      }
    } else {
      didMount.current = true;
      setCountHandler(coundtHandler + 1);
    }
  }, [selectedRowData]);
  const prepareExportData = (data: any) => {
    if (data) {
      data?.forEach((element: any) => {
        element.povalue$M_1 = "$" + formatNumber(element?.povalue$M_1);

        element.effective_ss = formatNumber(element?.effective_ss);
        element.onhand = formatNumber(element?.onhand);
        element.open_eros = formatNumber(element?.open_eros);
        element.Total_Dmd_Within_LT_Custom = formatNumber(
          element?.Total_Dmd_Within_LT_Custom
        );
        element.InvAtEndOfLT = formatNumber(element?.InvAtEndOfLT);
        element.poqty = formatNumber(element?.poqty);
        element.RecommendedDate = moment(element.RecommendedDate).format("MMDDYYYY");
      });
    }
    setExportALLData(data);
  };

  const handleReviewChange = (data: any) => {
    updateReviewedInventoryData(data)
      .then((response) => {
        if (response?.status === 200) {
          getPotentialOverstockDataHandler();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  function getLabel(value: any) {
    
    switch (value) {
      case "Inventory Threshold (Qty)":
        return `2*(Total Demand Within LT (Custom)) + Forecast for the LT`;
      case "Recommended Date":
        return `As per ERO Management`;
      case "ATP Custom":
        return `ATP Custom = ATP + Open EROs`;
      case "Net Inv Qty (Custom)":
        return `Net Inv Qty Custom = ATP Custom - Dp Dmd OLT - Ind Dmd Wlt`;
      default:
        return `Open PO: GR_POSTING_DATE_BICZGRPSTDAT is blank 
                `;
    }
  }
  return (
    <>
      <Table
        height={windowSize?.height / 3.3}
        data={tableData ? tableData : []}
        loading={loading}
        bordered={true}
        className="border-mainGray mt-2"
        cellBordered={true}
        headerHeight={65}
        hover={false}
      >
        {PO_INV_TABLE?.map((value) => {
          return (
            
            <Column
              key={value?.key}
              width={(value?.label?.length>18||value?.key==='RecommendedAction' )?windowSize?.width/10:windowSize?.width/15}
              fullText
              fixed={
                rightStick.includes(value?.key)
                  ? "right"
                  : leftStick.includes(value?.key)
                  ? "left"
                  : false
              }
            >
              <HeaderCell
                style={{
                  backgroundColor: "black",
                  color: "white",
                }}
                className="text-0.7vw text-center  border-[#333231]"
              >
                {value?.label === "Inventory Threshold (Qty)" ||value?.label === "Recommended Date" ||
                value?.label === "PO Status" ||value?.label === 'Net Inv Qty (Custom)' || value?.label === 'ATP Custom' ? (
                  <Tooltip

                    title={getLabel(value?.label)}

                    placement="top"
                  >
                    <p className="text-wrap">{value?.label} <Info   className="text-white "
                                    sx={{
                                      fontSize: {
                                        xs: 3,
                                        sm: 7,
                                        md: 17,
                                        lg: 17,
                                      },
                                    }}></Info></p>
                  </Tooltip>
                ) : (
                  <p className="text-wrap">{value?.label}</p>
                )}
              </HeaderCell>
              <DataCell
                dataKey={value?.key}
                selectedRow={selectedRow}
                handleRowSelection={(data: any) => {
                  handleRowSelection(data);
                }}
                handleReviewChange={(data: any) => {
                  handleReviewChange(data);
                }}
              />
            </Column>
          );
        })}
      </Table>
      <div className="w-full flex justify-between p-2">
        <div className="w-3/12"></div>
        <div className="w-1/2 flex justify-center">
          <Pagination
            ellipsis
            boundaryLinks
            maxButtons={5}
            last
            next
            first
            size="xs"
            total={count}
            limit={100}
            layout={["pager", "skip"]}
            activePage={pageNumber}
            onChangePage={(page: any) => {
              setPageNumber(page);
            }}
          />
        </div>
        <div className="w-3/12 flex justify-end">
          <Button
            onClick={() => setIsExport(true)}
            className="w-20"
            color="green"
            appearance="primary"
          >
            Export
          </Button>
          <CSVLink
            data={exportAllData}
            ref={exportFullRef}
            headers={PO_INV_TABLE}
            filename={"Potential_Overstock"}
            className="hidden"
          ></CSVLink>
        </div>
      </div>
    </>
  );
};

const DataCell = ({ rowData, dataKey, ...props }: any) => {
  const changeKey = [
    "PotentialOverStock",
    "PotentialOverStock$",
    "Adjusted_ORQ",
    "InventoryThreshold",
    "onhand",
    "open_eros",
    "Total_Dmd_Within_LT_Custom",
    "effective_ss",
    "InvAtEndOfLT",
    "poqty",
    "povalue",
    "Onhand$Value",
    "LTChange6Months",
    "Open_Req",
    "IND_Dmd_Within_LT",
    "Dep_Dmd_OLT",
    "RecommendedDate",
    ,"ATP","AvgFutureForecastNext6M"

  ];
  const handleReviewClick = (data: any, check: number) => {
    props.handleReviewChange({
      SKUl: data?.SKUl,
      reviewedFlag: check,
      alertType: "po",
    });
  };

  const handleRowSelection = (data: any) => {
    props?.handleRowSelection({
      SKUl: data?.SKUl,
      plant: data?.plant,
      material: data?.material,
      poNumber:data?.ponumber
    });
  };
  return (
    <Cell
      {...props}
      className=" text-white"
      onClick={() => {
        handleRowSelection(rowData);
      }}
      style={{
        backgroundColor:
          props?.selectedRow[0] === rowData?.SKUl
            ? "#56544e"
            : "#1a1916",
      }}
    >
      {dataKey !== "isReviewed" ? (
        <>
          {!changeKey?.includes(dataKey) && (
            <p className="truncate  text-0.7vw">
              {rowData[dataKey] !== null ? rowData[dataKey] : ""}
            </p>
          )}
          {changeKey?.includes(dataKey) && dataKey!=='RecommendedDate' && dataKey!=='LTChange6Months'&&(
            <p className="truncate  text-0.7vw">
              {rowData[dataKey] !== null
                ? `${
                    dataKey !== "povalue" && dataKey !== "Onhand$Value"  && dataKey !== "PotentialOverStock$"
                      ? ""
                      : "$"
                  } ${formatNumber(rowData[dataKey])} `
                : ""}
            </p>
          )}
           {changeKey?.includes(dataKey) && dataKey==='LTChange6Months'&&(
            <p className="truncate  text-0.7vw">
              {rowData[dataKey] !== null
                ? ` ${(Number(rowData[dataKey])*100).toFixed(0)}% `
                : ""}
            </p>
          )}
          
           {changeKey?.includes(dataKey) && dataKey === "RecommendedDate" && (
            <p className="truncate  text-0.7vw">
              {rowData[dataKey] !== null
                ? moment(rowData[dataKey].slice(0, 10)).format("MM-DD-YYYY")
                : ""}
            </p>
          )}
         
        </>
      ) : (
        <ButtonToolbar className="pb-1 ">
          <ButtonGroup>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                handleReviewClick(rowData, 0);
              }}
              size="xs"
              appearance="ghost"
              className={`${
                rowData[dataKey] === 0
                  ? "bg-gray-500 text-white"
                  : "bg-white text-black"
              } border-black border-l-2 hover:bg-green-900 hover:text-white  text-xs`}
            >
              N
            </Button>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                handleReviewClick(rowData, 1);
              }}
              appearance="ghost"
              size="xs"
              className={`${
                rowData[dataKey] === 1
                  ? "bg-yellow-500 text-white"
                  : "bg-white text-black"
              } border-black border-l-2  hover:bg-green-900 hover:text-white text-xs `}
            >
              IP
            </Button>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                handleReviewClick(rowData, 2);
              }}
              appearance="ghost"
              size="xs"
              className={`${
                rowData[dataKey] === 2
                  ? "bg-green-500 text-white"
                  : "bg-white text-black"
              } border-black  hover:bg-green-900 hover:text-white  text-xs`}
            >
              Y
            </Button>
          </ButtonGroup>
        </ButtonToolbar>
      )}
    </Cell>
  );
};

const message = (
  <Notification type={"info"} header={`Exporting...`} closable>
    <p>Your file is being created. It will be download automatically</p>
  </Notification>
);
const mapDispatchToProps = (dispatch: any) => ({
  onRowChange: (data: any) => dispatch({ type: TABLEFILTER, data: data }),
});

export default connect(null, mapDispatchToProps)(POInventoryTable);
