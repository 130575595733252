import { Dispatch } from "redux";
import { BACKEND_API_URL } from "../config/config";
import QueryService from "../services/queryService";
import { ForecastDataPayload, ForeCastGrapDataPayload, GBPFilters, UpdateForeCast } from "../model/forecastModel";

export const PLANT='PLANT'
export const L2='L2'
export const L3='L3'
export const MATERIALDESCRIPTION='MATERIALDESCRIPTION'
export const MATERIAL='MATERIAL'
export const MRPTYPE='MRPTYPE'
export const FUTURESEGMENTATION='FUTURESEGMENTATION'
export const VENDORNAME='VENDORNAME'
export const CLUSTERNAME='CLUSTERNAME'
export const PFXPG='PFXPG'
export const POTYPE='POTYPE'
export const POACTION='POACTION'


export const getGBPFilters = (payload:GBPFilters) => {
    return async (dispatch:Dispatch)=>{
        const queryService = new QueryService(BACKEND_API_URL);
        const fetchData = async () => {
            let url = `/getGBPFilters`;
            const data = await queryService.runQuery(url, "POST",payload);
            return data;
        }
        return fetchData().then((data) => {
            console.log(data);
            if(data?.status===200){
                if(payload.plant?.length===0){
                    dispatch({type:PLANT,data:data?.data?.plant})
                }
                if(payload.l2?.length===0){
                    dispatch({type:L2,data:data?.data?.l2})
                }
                if(payload.l3?.length===0){
                    dispatch({type:L3,data:data?.data?.l3})
                }
                if(payload.materialDescription?.length===0){
                    dispatch({type:MATERIALDESCRIPTION,data:data?.data?.materialDescription})
                }
                if(payload.material?.length===0){
                    dispatch({type:MATERIAL,data:data?.data?.material})
                }
                if(payload.mrpType?.length===0){
                    dispatch({type:MRPTYPE,data:data?.data?.mrpType})
                }
                if(payload.futureSegmentation?.length===0){
                    dispatch({type:FUTURESEGMENTATION,data:data?.data?.futureSegmentation})
                }
                if(payload.vendorName?.length===0){
                    dispatch({type:VENDORNAME,data:data?.data?.vendorName})
                }
                if(payload.clustername?.length===0){
                    dispatch({type:CLUSTERNAME,data:data?.data?.clustername})
                }
                if(payload.pfxpg?.length===0){
                    dispatch({type:PFXPG,data:data?.data?.pfxpg})
                }
             dispatch({type:POACTION,data:data?.data?.poaction})
                
            }
            return data;
        }).catch(() => {
            console.log("Could Not fetch data");
        });
    }
};

export const getInventoryFilters = (payload:GBPFilters) => {
    return async (dispatch:Dispatch)=>{
        const queryService = new QueryService(BACKEND_API_URL);
        const fetchData = async () => {
            let url = `/getInventoryFilters`;
            const data = await queryService.runQuery(url, "POST",payload);
            return data;
        }
        return fetchData().then((data) => {
            if(data?.status===200){
                if(payload.plant?.length===0){
                    dispatch({type:PLANT,data:data?.data?.plant})
                }
                if(payload.l2?.length===0){
                    dispatch({type:L2,data:data?.data?.l2})
                }
                if(payload.material?.length===0){
                    dispatch({type:MATERIAL,data:data?.data?.material})
                }
                if(payload.mrpType?.length===0){
                    dispatch({type:MRPTYPE,data:data?.data?.mrpType})
                }
                if(payload.futureSegmentation?.length===0){
                    dispatch({type:FUTURESEGMENTATION,data:data?.data?.futureSegmentation})
                }
                if(payload.vendorName?.length===0){
                    dispatch({type:VENDORNAME,data:data?.data?.vendorName})
                }
                if(payload.clustername?.length===0){
                    dispatch({type:CLUSTERNAME,data:data?.data?.clustername})
                }  
                console.log(data?.data?.poType,312)
                dispatch({type:POTYPE,data:data?.data?.poType})
                dispatch({type:POACTION,data:data?.data?.poaction})
            }
            return data;
        }).catch(() => {
            console.log("Could Not fetch data");
        });
    }
};

export const getScenarioPlanningData = (payload:ForecastDataPayload) => {
        const queryService = new QueryService(BACKEND_API_URL);
        const fetchData = async () => {
            let url = `/getScenarioPlanningData`;
            const data = await queryService.runQuery(url, "POST",payload);
            return data;
        }
        return fetchData().then((data) => {
            return data;
        }).catch(() => {
            console.log("Could Not fetch data");
        });
};

export const getGBPAlerts = (payload:GBPFilters) => {
    const queryService = new QueryService(BACKEND_API_URL);
    const fetchData = async () => {
        let url = `/getGBPAlerts`;
        const data = await queryService.runQuery(url, "POST",payload);
        return data;
    }
    return fetchData().then((data) => {
        return data;
    }).catch(() => {
        console.log("Could Not fetch data");
    });
};

export const getStockOutAnddemandData = (payload:ForeCastGrapDataPayload) => {
    const queryService = new QueryService(BACKEND_API_URL);
    const fetchData = async () => {
        let url = `/getStockOutAnddemandData`;
        const data = await queryService.runQuery(url, "POST",payload);
        return data;
    }
    return fetchData().then((data) => {
        return data;
    }).catch(() => {
        console.log("Could Not fetch data");
    });
};

export const getInventoryAvailabilityData = (payload:ForeCastGrapDataPayload) => {
    const queryService = new QueryService(BACKEND_API_URL);
    const fetchData = async () => {
        let url = `/getInventoryAvailabilityData`;
        const data = await queryService.runQuery(url, "POST",payload);
        return data;
    }
    return fetchData().then((data) => {
        return data;
    }).catch(() => {
        console.log("Could Not fetch data");
    });
};

export const updateForecastData = (payload:UpdateForeCast) => {
    const queryService = new QueryService(BACKEND_API_URL);
    const fetchData = async () => {
        let url = `/updateForecastData`;
        const data = await queryService.runQuery(url, "POST",payload.data);
        return data;
    }
    return fetchData().then((data) => {
        return data;
    }).catch(() => {
        console.log("Could Not fetch data");
    });
};

export const updateInventoryComments = (payload :any) => {
    const queryService = new QueryService(BACKEND_API_URL);
    const fetchData = async () => {
        let url = `/updateInventoryComments`;
        const data = await queryService.runQuery(url, "POST",payload);
        return data;
    }
    return fetchData().then((data) => {
        return data;
    }).catch(() => {
        console.log("Could Not fetch data");
    });
};
export const updateInventoryReportComments = (payload :any) => {
    const queryService = new QueryService(BACKEND_API_URL);
    const fetchData = async () => {
        let url = `/updateInventoryReportComments`;
        const data = await queryService.runQuery(url, "POST",payload);
        return data;
    }
    return fetchData().then((data) => {
        return data;
    }).catch(() => {
        console.log("Could Not fetch data");
    });
};

export const getGainsFormatExportData = (payload:any={},query:number=0) => {
    const queryService = new QueryService(BACKEND_API_URL);
    const fetchData = async () => {
        let url = `/getGainsFormatExportData?isOverride=${query}`;
        const data = await queryService.runQuery(url, "POST",payload);
        return data;
    }
    return fetchData().then((data) => {
        return data;
    }).catch(() => {
        console.log("Could Not fetch data");
    });
};
export const updateReviewedForecastData = (payload:any={}) => {
    const queryService = new QueryService(BACKEND_API_URL);
    const fetchData = async () => {
        let url = `/updateReviewedForecastData`;
        const data = await queryService.runQuery(url, "POST",payload);
        return data;
    }
    return fetchData().then((data) => {
        return data;
    }).catch(() => {
        console.log("Could Not fetch data");
    });
};


export const getExportData = (payload:ForecastDataPayload) => {
    const queryService = new QueryService(BACKEND_API_URL);
    const fetchData = async () => {
        let url = `/getExportData`;
        const data = await queryService.runQuery(url, "POST",payload);
        return data;
    }
    return fetchData().then((data) => {
        return data;
    }).catch(() => {
        console.log("Could Not fetch data");
    });
};
export const getModifiedExportData = () => {
    const queryService = new QueryService(BACKEND_API_URL);
    const fetchData = async () => {
        let url = `/getModifiedExportData`;
        const data = await queryService.runQuery(url, "GET");
        return data;
    }
    return fetchData().then((data) => {
        return data;
    }).catch(() => {
        console.log("Could Not fetch data");
    });
};
export const getForecastSummaryChartsData = (payload:any) => {
    const queryService = new QueryService(BACKEND_API_URL);
    const fetchData = async () => {
        let url = `/getForecastSummaryChartsData`;
        const data = await queryService.runQuery(url, "POST",payload);
        return data;
    }
    return fetchData().then((data) => {
        return data;
    }).catch(() => {
        console.log("Could Not fetch data");
    });
};

export const getForecastSummarySKUlData = (payload:any) => {
    const queryService = new QueryService(BACKEND_API_URL);
    const fetchData = async () => {
        let url = `/getForecastSummarySKUlData`;
        const data = await queryService.runQuery(url, "POST",payload);
        return data;
    }
    return fetchData().then((data) => {
        return data;
    }).catch(() => {
        console.log("Could Not fetch data");
    });
};

export const getSummaryExportData = (payload:any) => {
    const queryService = new QueryService(BACKEND_API_URL);
    const fetchData = async () => {
        let url = `/getSummaryExportData`;
        const data = await queryService.runQuery(url, "POST",payload);
        return data;
    }
    return fetchData().then((data) => {
        return data;
    }).catch(() => {
        console.log("Could Not fetch data");
    });
};

export const getAccuracySummaryData = (payload:any) => {
    const queryService = new QueryService(BACKEND_API_URL);
    const fetchData = async () => {
        let url = `/getAccuracySummaryData`;
        const data = await queryService.runQuery(url, "POST",payload);
        return data;
    }
    return fetchData().then((data) => {
        return data;
    }).catch(() => {
        console.log("Could Not fetch data");
    });
};

export const getInactiveInventoryData = (payload:any) => {
    const queryService = new QueryService(BACKEND_API_URL);
    const fetchData = async () => {
        let url = `/getInactiveInventoryData`;
        const data = await queryService.runQuery(url, "POST",payload);
        return data;
    }
    return fetchData().then((data) => {
        return data;
    }).catch(() => {
        console.log("Could Not fetch data");
    });
};

export const getInventoryReportsFilters = (payload:any) => {
    const queryService = new QueryService(BACKEND_API_URL);
    const fetchData = async () => {
        let url = `/getInventoryReportsFilters`;
        const data = await queryService.runQuery(url, "POST",payload);
        return data;
    }
    return fetchData().then((data) => {
        return data;
    }).catch(() => {
        console.log("Could Not fetch data");
    });
};

export const getInactiveReportTableData = (payload:any) => {
    const queryService = new QueryService(BACKEND_API_URL);
    const fetchData = async () => {
        let url = `/getInactiveReportTableData`;
        const data = await queryService.runQuery(url, "POST",payload);
        return data;
    }
    return fetchData().then((data) => {
        return data;
    }).catch(() => {
        console.log("Could Not fetch data");
    });
};

export const getInactiveReportChartData = (payload:any) => {
    const queryService = new QueryService(BACKEND_API_URL);
    const fetchData = async () => {
        let url = `/getInactiveReportChartData`;
        const data = await queryService.runQuery(url, "POST",payload);
        return data;
    }
    return fetchData().then((data) => {
        return data;
    }).catch(() => {
        console.log("Could Not fetch data");
    });
};
export const updateReviewedInventoryReportData = (payload:any) => {
    const queryService = new QueryService(BACKEND_API_URL);
    const fetchData = async () => {
        let url = `/updateReviewedInventoryReportData`;
        const data = await queryService.runQuery(url, "POST",payload);
        return data;
    }
    return fetchData().then((data) => {
        return data;
    }).catch(() => {
        console.log("Could Not fetch data");
    });
};

export const getInactiveInventoryChartData = (payload:any) => {
    const queryService = new QueryService(BACKEND_API_URL);
    const fetchData = async () => {
        let url = `/getInactiveInventoryChartData`;
        const data = await queryService.runQuery(url, "POST",payload);
        return data;
    }
    return fetchData().then((data) => {
        return data;
    }).catch(() => {
        console.log("Could Not fetch data");
    });
};

export const getInactiveInventoryWaterfallData = (payload:any) => {
    const queryService = new QueryService(BACKEND_API_URL);
    const fetchData = async () => {
        let url = `/getInactiveInventoryWaterfallData`;
        const data = await queryService.runQuery(url, "POST",payload);
        return data;
    }
    return fetchData().then((data) => {
        return data;
    }).catch(() => {
        console.log("Could Not fetch data");
    });
};
export const getPotentialActionFilter = (payload:any) => {
    const queryService = new QueryService(BACKEND_API_URL);
    const fetchData = async () => {
        let url = `/getPotentialActionFilter`;
        const data = await queryService.runQuery(url, "POST",payload);
        return data;
    }
    return fetchData().then((data) => {
        return data;
    }).catch(() => {
        console.log("Could Not fetch data");
    });
};

export const getInactiveInventoryPlantChartData = (payload:any) => {
    const queryService = new QueryService(BACKEND_API_URL);
    const fetchData = async () => {
        let url = `/getInactiveInventoryPlantChartData`;
        const data = await queryService.runQuery(url, "POST",payload);
        return data;
    }
    return fetchData().then((data) => {
        return data;
    }).catch(() => {
        console.log("Could Not fetch data");
    });
};

export const getInactiveInventoryPlantLevelData = (payload:any) => {
    const queryService = new QueryService(BACKEND_API_URL);
    const fetchData = async () => {
        let url = `/getInactiveInventoryPlantLevelData`;
        const data = await queryService.runQuery(url, "POST",payload);
        return data;
    }
    return fetchData().then((data) => {
        return data;
    }).catch(() => {
        console.log("Could Not fetch data");
    });
};

export const getOverstockData = (payload:any) => {
    const queryService = new QueryService(BACKEND_API_URL);
    const fetchData = async () => {
        let url = `/getOverstockData`;
        const data = await queryService.runQuery(url, "POST",payload);
        return data;
    }
    return fetchData().then((data) => {
        return data;
    }).catch(() => {
        console.log("Could Not fetch data");
    });
};

export const getOverstockChartData = (payload:any) => {
    const queryService = new QueryService(BACKEND_API_URL);
    const fetchData = async () => {
        let url = `/getOverstockChartData`;
        const data = await queryService.runQuery(url, "POST",payload);
        return data;
    }
    return fetchData().then((data) => {
        return data;
    }).catch(() => {
        console.log("Could Not fetch data");
    });
};

export const getDemandInventoryLineGraphData = (payload:any) => {
    const queryService = new QueryService(BACKEND_API_URL);
    const fetchData = async () => {
        let url = `/getDemandInventoryLineGraphData`;
        const data = await queryService.runQuery(url, "POST",payload);
        return data;
    }
    return fetchData().then((data) => {
        return data;
    }).catch(() => {
        console.log("Could Not fetch data");
    });
};

export const getOverstockPlantChartData = (payload:any) => {
    const queryService = new QueryService(BACKEND_API_URL);
    const fetchData = async () => {
        let url = `/getOverstockPlantChartData`;
        const data = await queryService.runQuery(url, "POST",payload);
        return data;
    }
    return fetchData().then((data) => {
        return data;
    }).catch(() => {
        console.log("Could Not fetch data");
    });
};

export const getInventoryReportAlerts = (payload:any) => {
    const queryService = new QueryService(BACKEND_API_URL);
    const fetchData = async () => {
        let url = `/getInventoryReportAlerts`;
        const data = await queryService.runQuery(url, "POST",payload);
        return data;
    }
    return fetchData().then((data) => {
        return data;
    }).catch(() => {
        console.log("Could Not fetch data");
    });
};

export const getInventoryAlerts = (payload:any) => {
    const queryService = new QueryService(BACKEND_API_URL);
    const fetchData = async () => {
        let url = `/getInventoryAlerts`;
        const data = await queryService.runQuery(url, "POST",payload);
        return data;
    }
    return fetchData().then((data) => {
        return data;
    }).catch(() => {
        console.log("Could Not fetch data");
    });
};

export const getStockoutData = (payload:any) => {
    const queryService = new QueryService(BACKEND_API_URL);
    const fetchData = async () => {
        let url = `/getStockoutData`;
        const data = await queryService.runQuery(url, "POST",payload);
        return data;
    }
    return fetchData().then((data) => {
        return data;
    }).catch(() => {
        console.log("Could Not fetch data");
    });
};

export const getStockoutChartData = (payload:any) => {
    const queryService = new QueryService(BACKEND_API_URL);
    const fetchData = async () => {
        let url = `/getStockoutChartData`;
        const data = await queryService.runQuery(url, "POST",payload);
        return data;
    }
    return fetchData().then((data) => {
        return data;
    }).catch(() => {
        console.log("Could Not fetch data");
    });
};

export const getPotentialOverstockData = (payload:any) => {
    const queryService = new QueryService(BACKEND_API_URL);
    const fetchData = async () => {
        let url = `/getPotentialOverstockData`;
        const data = await queryService.runQuery(url, "POST",payload);
        return data;
    }
    return fetchData().then((data) => {
        return data;
    }).catch(() => {
        console.log("Could Not fetch data");
    });
};

export const getPotentialOverstockLineGraphData = (payload:any) => {
    const queryService = new QueryService(BACKEND_API_URL);
    const fetchData = async () => {
        let url = `/getPotentialOverstockLineGraphData`;
        const data = await queryService.runQuery(url, "POST",payload);
        return data;
    }
    return fetchData().then((data) => {
        return data;
    }).catch(() => {
        console.log("Could Not fetch data");
    });
};

export const getHighLowDOSTrendChart = (payload:any) => {
    const queryService = new QueryService(BACKEND_API_URL);
    const fetchData = async () => {
        let url = `/getHighLowDOSTrendChart`;
        const data = await queryService.runQuery(url, "POST",payload);
        return data;
    }
    return fetchData().then((data) => {
        return data;
    }).catch(() => {
        console.log("Could Not fetch data");
    });
};

export const getHighOutlierChartData = (payload:any) => {
    const queryService = new QueryService(BACKEND_API_URL);
    const fetchData = async () => {
        let url = `/getHighOutlierChartData`;
        const data = await queryService.runQuery(url, "POST",payload);
        return data;
    }
    return fetchData().then((data) => {
        return data;
    }).catch(() => {
        console.log("Could Not fetch data");
    });
};

export const getHighOutlierTableData = (payload:any) => {
    const queryService = new QueryService(BACKEND_API_URL);
    const fetchData = async () => {
        let url = `/getHighOutlierTableData`;
        const data = await queryService.runQuery(url, "POST",payload);
        return data;
    }
    return fetchData().then((data) => {
        return data;
    }).catch(() => {
        console.log("Could Not fetch data");
    });
};

export const getHighOutlierCustomerData = (payload:any) => {
    const queryService = new QueryService(BACKEND_API_URL);
    const fetchData = async () => {
        let url = `/getHighOutlierCustomerData`;
        const data = await queryService.runQuery(url, "POST",payload);
        return data;
    }
    return fetchData().then((data) => {
        return data;
    }).catch(() => {
        console.log("Could Not fetch data");
    });
};


export const updateReviewedInventoryData = (payload:any) => {
    const queryService = new QueryService(BACKEND_API_URL);
    const fetchData = async () => {
        let url = `/updateReviewedInventoryData`;
        const data = await queryService.runQuery(url, "POST",payload);
        return data;
    }
    return fetchData().then((data) => {
        return data;
    }).catch(() => {
        console.log("Could Not fetch data");
    });
};

export const getForecastCustomerLevelData = (payload:any) => {
    const queryService = new QueryService(BACKEND_API_URL);
    const fetchData = async () => {
        let url = `/getForecastCustomerLevelData`;
        const data = await queryService.runQuery(url, "POST",payload);
        return data;
    }
    return fetchData().then((data) => {
        return data;
    }).catch(() => {
        console.log("Could Not fetch data");
    });
};

export const getForecastRebalancingData = (payload:any) => {
    const queryService = new QueryService(BACKEND_API_URL);
    const fetchData = async () => {
        let url = `/getForecastRebalancingData`;
        const data = await queryService.runQuery(url, "POST",payload);
        return data;
    }
    return fetchData().then((data) => {
        return data;
    }).catch(() => {
        console.log("Could Not fetch data");
    });
};




