import React, {  useState } from "react";
import {
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useSelector } from "react-redux";
import { formatAmount } from "../../utils/commonFunctions";

const ForecastLongTermLineGraph = (props: any) => {

  const {lineGraphData}=props

//   const [graphTab,setGraphTab]=useState(1)

  const windowSize = useSelector(
    (state: any) => state.CommonReducer.windowSize
  );



  return (
    <>
      <div className="w-full">
      <div className="flex wifull justify-between">
      <span className="text-white font-medium text-0.95vw ">{'Historical Transactional and Monthly Outlier Correction'}</span>
      
        </div>
        <ResponsiveContainer
          width={"100%"}
          height={windowSize?.width / 6.5}
          className={`border-[1px] border-mainGray `}
        >
          <LineChart
            margin={{
              top: 0,
              right: 50,
              left: 10,
              bottom: 0,
            }}
            layout="horizontal"
            data={lineGraphData}
          >
            <XAxis fontSize={10} stroke="#B5B5B5" dataKey="date" type="category" />

            
            <YAxis fontSize={10} stroke="#B5B5B5"  tickFormatter={(value: any) => value && `${formatAmount(value)}`}/>

            <Tooltip content={($) => <CustomTooltip data={$} />} />
            <Legend verticalAlign="top"  wrapperStyle={{ paddingBottom: "10px" }}/>
            <ReferenceLine x="Page C" stroke="green"  />
            <Line
              name="Actual Qty "
              dataKey="actualQTY"
              stroke="#a4f504"
              fill="#a4f504"
              strokeWidth={2}
              dot={{ strokeWidth: 1,r:2 }}
            />
            <Line
              name="Post Monthly Correction (PA)"
              dataKey="normalizedPAQty"
              stroke="#ffffff"
              fill="#ffffff"
              strokeWidth={2}
              dot={{ strokeWidth: 1,r:2 }}
            />
            <Line
              name="Post Transactional Correction (GB)"
              dataKey="normalizedQTYTransaction"
              stroke="#0097A9"
              fill="#0097A9"
              strokeWidth={2}
              dot={{ strokeWidth: 1,r:2 }}
            />
           



          </LineChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

const CustomTooltip = (props: any) => {
  const { active, payload } = props.data;
  if (active && payload && payload.length) {
    return (
      <>
        <div className="bg-[#353535] p-3">
          <>
            <p className="text-white text-0.95vw">
              {payload[0]?.payload?.date}
            </p>
            {payload?.map(($: any) => {
              return (
                <>
                  {$?.name!=='connect'&&$?.name!=='On Hand Last 6M'&&<p key={$?.name} className="text-white text-0.7vw">
                    {$?.name?.includes('Last')? $?.name.replace('Last 3M',''):$?.name.replace('Next 3M','')} : {formatAmount($?.value)}
                  </p>}
                  {$?.name==='On Hand Last 6M'&&<p key={$?.name} className="text-white text-0.7vw">
                    {$?.name.replace('Last 6M','')} : {formatAmount($?.value)}
                  </p>}
                </>
              );
            })}
          </>
        </div>
      </>
    );
  }

  return null;
};

export default ForecastLongTermLineGraph;
