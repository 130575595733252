import React from "react";
import {
  Area,
  Bar,
  BarChart,
  Cell,
  ComposedChart,
  LabelList,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useSelector } from "react-redux";
import { formatAmount, formatNumber } from "../../utils/commonFunctions";

const OverstockWaterFallGraph = (props: any) => {
  const { waterfallData, type , kkey } = props;

  const windowSize = useSelector(
    (state: any) => state.CommonReducer.windowSize
  );
  console.log(waterfallData)

  return (
    <div className="w-full">
     

     {
      kkey == 1 ?
      <span className="text-white font-medium text-0.95vw ">
            {"Inventory Trend vs Target Inventory (Qty)"}
          </span> : <></>
    }
             

      <ResponsiveContainer
        width={"100%"}
        height={windowSize?.width / 6.5}
        className={`border-[1px] border-mainGray`}
      >
        <ComposedChart
          data={waterfallData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis interval={0} fontSize={10} stroke="#B5B5B5" dataKey="date" />
          <YAxis
            fontSize={10}
            stroke="#B5B5B5"
            tickFormatter={(value: any) => value && ` ${formatAmount(value)}`}
          />
          <Tooltip
            cursor={{ fill: "transparent" }}
            content={($) => <CustomTooltip data={$} type={type} />}
          />
          <Area dataKey="adjusted" fill="#c9cac6" stroke="#c9cac6" strokeWidth="0.2" fillOpacity="0.2" />
          <Area
            stackId="1"
            dataKey="effectivess"
            fill="#5b9dbc"
            stroke="#5b9dbc"
            fillOpacity="0.2"
            strokeWidth="0.2" 
          />
          {/* <Line type="monotone" dot={{ strokeWidth: 0, r: 0 }} dataKey="effectivess" fill="#8884d8" stroke="#8884d8" /> */}
          <Line
            type="monotone"
            dataKey={(data) => data.onhand !== null ? data.onhand : data.InvAtEndOfLT1 !== null ? data.InvAtEndOfLT1 : data.InvAtEndOfLT2}
            fill="#82ca9d"
            stroke="#82ca9d"
            strokeWidth={3}
          />
        </ComposedChart>

      </ResponsiveContainer>
    </div>
  );
};

const renderCustomizedLabel = (props: any) => {
  const { x, y, width, value, height } = props;
  return (
    <>
      {value !== 0 && (
        <text
          x={x + width / 2}
          y={y - (value > 0 ? 10 : -(height - 10))}
          fill="#fff"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {formatAmount(
            Number(parseFloat(value < 0 ? value * -1 : value).toFixed(2))
          )}
        </text>
      )}
    </>
  );
};

const CustomTooltip = (props: any) => {
  const { active, payload } = props.data;
  if (active && payload && payload.length) {
    console.log(payload);
    
    return (
      <>
        <div className="bg-[#353535] p-3">
          <>
            {/* <p className="text-white text-0.95vw">
              {payload[0]?.payload?.name}
            </p> */}
            {payload?.map(($: any) => {
              return (
                <>
                  {$?.name === "adjusted" && (
                    <p className="text-white text-0.7vw">
                      Effective SS + Adjusted ORQ : {formatAmount($?.value)}
                    </p>
                  )}
                  {$?.name === "effectivess" && (
                    <p className="text-white text-0.7vw">
                      Effective SS : {formatAmount($?.value)}
                    </p>
                  )}
                  {$?.name.name === "dataKey" && (
                    <p className="text-white text-0.7vw">
                      {$?.payload?.netinv ? "Net Inv Qty" :$?.payload?.InvAtEndOfLT1 ? "Inv At End Of LT1" :$?.payload?.InvAtEndOfLT2 ? "Inv At End Of LT2" : "Onhand Qty"} :{" "}
                      {formatAmount($?.value)}
                    </p>
                  )}
                </>
              );
            })}
          </>
        </div>
      </>
    );
  }

  return null;
};

export default OverstockWaterFallGraph;
