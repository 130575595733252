import React, { useState } from "react";
import {
  Bar,
  BarChart,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { useSelector } from "react-redux";
import { Button, ButtonGroup, ButtonToolbar } from "rsuite";
import { formatAmount, formatNumber } from "../../utils/commonFunctions";

const InactiveInventoryTimeframeBarGraph = (props: any) => {
  const {
    plantLvlDataForCluster,
    plantLvlDataForVendor,
    selectedGBPFilter,
    barGraphData,
    selectedRowData,
    onbarClick,
    goBack,
    selectedCluster,
    barGraphData1,
  } = props;
  const [graphTab, setGraphTab] = useState(1);
  const windowSize = useSelector(
    (state: any) => state.CommonReducer.windowSize
  );

  console.log(
   "filter",  selectedGBPFilter,
    plantLvlDataForCluster,
    plantLvlDataForVendor,
   "BAR1",   barGraphData1,
 "BAR",   barGraphData,
    selectedRowData,
    selectedCluster
  );
  return (
    <div className="w-full ">
      <div className="flex justify-between">
        <div>
          {/* {!barGraphData1 && (
            <span className="text-white font-medium text-0.95vw ">
              {graphTab === 1 ? "Clusterwise" : "Vendor"} Inactive Age Breakdown{" "}
            </span>
          )} */}
            {/* <span className="text-white font-medium text-0.95vw ">
            {graphTab == 1
              ? selectedRowData?.length > 0
                ? `Plantwise Breakdown of Untagged Inactive - ${selectedCluster}`
                :  `${selectedRowData?.length > 0  ?`OnHand (Qty) Breakdown - ${selectedRowData[0]?.plant}` :`Inactive Age Breakdown - ${selectedRowData[0]?.plant}`}`
              : selectedCluster
              ? `OnHand (Qty) and DOH Across Plants in ${selectedCluster}`
              : ` Inventory Details for Other Active Locations (Within the Cluster) `}{" "}
            </span> */}
            <span className="text-white font-medium text-0.95vw ">
           {graphTab == 1 ?
            selectedRowData.length > 0 ? `Inactive Age Breakdown - ${selectedRowData[0]?.plant}` : selectedCluster ? `Plantwise Inactive Age Breakdown - ${selectedCluster}` : selectedGBPFilter?.plant?.length > 0 ? `Inactive Age Breakdown -${selectedGBPFilter?.plant}` :selectedGBPFilter?.plant?.length > 0  ?`Inactive Age Breakdown- ${selectedRowData[0]?.plant}` : selectedCluster ? `Plantwise Inactive Age Breakdown - ${selectedCluster}` : selectedGBPFilter?.clustername?.length > 0 ? `Inactive Age Breakdown -${selectedGBPFilter?.clustername}`:  `${graphTab == 1 ? 'Clusterwise' : 'Vendorwise'}  Inactive Age Breakdown`
            :
            selectedRowData.length > 0 ? `Vendorwise Inactive Age Breakdown - ${selectedRowData[0]?.plant}` : selectedGBPFilter?.plant?.length > 0 ?  `Vendorwise Inactive Age Breakdown -${selectedGBPFilter?.plant}`:  selectedGBPFilter?.clustername?.length > 0 ? `Vendorwise Inactive Age Breakdown - ${selectedGBPFilter?.clustername}` : selectedCluster ? `Vendorwise Inactive Age Breakdown - ${selectedCluster}`:'Vendorwise Inactive Age Breakdown'
           }
            </span>
          {barGraphData1 && (
            <Button
              onClick={() => {
                goBack();
              }}
              size="xs"
              appearance="ghost"
              className={` bg-green-500 text-white
                 border-black border-l-2 hover:bg-green-900 hover:text-white focus:bg-green-500 focus:text-white ml-3`}
            >
              Go Back
            </Button>
          )}
        </div>
        <ButtonToolbar className="">
          <ButtonGroup>
            <Button
              onClick={() => {
                setGraphTab(1);
              }}
              size="xs"
              appearance="ghost"
              className={`${
                graphTab === 1
                  ? "bg-green-500 text-white"
                  : "bg-white text-black"
              } border-black border-l-2 hover:bg-green-900 hover:text-white focus:bg-green-500 focus:text-white`}
            >
              Cluster
            </Button>
            <Button
              onClick={() => setGraphTab(2)}
              appearance="ghost"
              size="xs"
              className={`${
                graphTab === 2
                  ? "bg-green-500 text-white"
                  : "bg-white text-black"
              } border-black border-l-2  hover:bg-green-900 hover:text-white focus:bg-green-500 focus:text-white`}
            >
              Vendor
            </Button>
          </ButtonGroup>
        </ButtonToolbar>
      </div>
      <ResponsiveContainer
        width={"100%"}
        height={windowSize?.width / 6.5}
        className={`border-[1px]  border-mainGray`}
      >
        <BarChart
          margin={{ top: 0, right: 0, left: 0, bottom: 25 }}
          data={
            graphTab == 1
              ? plantLvlDataForCluster?.cluster?.length > 0
                ? plantLvlDataForCluster?.cluster
                : selectedRowData?.length > 0 ?  barGraphData1?.cluster : selectedCluster == ''  ? barGraphData?.cluster : barGraphData1?.cluster
              : plantLvlDataForVendor
              ? plantLvlDataForVendor
              :selectedRowData?.length > 0 ? barGraphData1?.cluster : barGraphData?.vendor
          }
        >
          <XAxis
            fontSize={10}
            stroke="#B5B5B5"
            dataKey="cluster"
            angle={-45}
            dx={0}
            dy={15}
            minTickGap={-200}
            axisLine={false}
          />
          <YAxis
            stroke="#B5B5B5"
            fontSize={10}
            tickFormatter={(value: any) => value && `${formatAmount(value)}`}
          />
          <Tooltip
            cursor={{ fill: "transparent" }}
            content={($) => <CustomTooltip data={$} />}
          />
          <Legend verticalAlign="top" />
        

          <Bar
            dataKey="M812"
            onClick={($) =>
              graphTab == 2 ? null : onbarClick({ cluster: $?.cluster }, 3)
            }
            name={"8-12 Months"}
            barSize={60}
            stackId="a"
            fill="#B9E5E8"
          ></Bar>
          <Bar
            dataKey="M8"
            onClick={($) =>
              graphTab == 2 ? null : onbarClick({ cluster: $?.cluster }, 3)
            }
            name={">12 Months"}
            barSize={60}
            stackId="a"
            fill="#7AB2D3"
          ></Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

const renderCustomizedLabel = (props: any, key: number, type: number = 0) => {
  const { x, y, width, value } = props;
  return (
    <>
      <text
        x={x + width / 2}
        y={y + 10}
        fill="#fff"
        fontSize={10}
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {value > 500000 && type === 0 && (
          <>{key === 1 && value > 100000 ? ">12" : "8-12"}</>
        )}
        {value > 10000 && type === 1 && (
          <>{key === 1 && value > 10000 ? ">12" : "8-12"}</>
        )}
      </text>
    </>
  );
};

const CustomTooltip = (props: any) => {
  const { active, payload } = props.data;

  if (active && payload && payload.length) {
    return (
      <div className="bg-[#353535] p-3">
        <>
          <p className="text-white text-0.95vw">
            {payload[0]?.payload?.cluster}
          </p>
          {payload
            .slice()
            .reverse()
            .map(($: any) => {
              return (
                <>
                  <p className="text-white text-0.7vw">
                    {$?.name?.toUpperCase()} :{" "}
                    {formatNumber(parseFloat($?.value))}
                  </p>
                </>
              );
            })}
        </>
      </div>
    );
  }

  return null;
};

export default InactiveInventoryTimeframeBarGraph;
