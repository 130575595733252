import React, { useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Notification,
  Pagination,
  Table,
  useToaster,
} from "rsuite";
import {
  STOCKOUT_INV_TABLE,
} from "../../constants/dataConstant";
import { useLocation } from "react-router-dom";
import {
  getStockoutData,
  updateReviewedInventoryData,
} from "../../queries/apiQueries";
import { CSVLink } from "react-csv";
import { TABLEFILTER } from "../forecast/forecastDataTable";
import { formatNumber } from "../../utils/commonFunctions";
import { Tooltip } from "@mui/material";
import { Info } from "@mui/icons-material";

const { Column, HeaderCell, Cell } = Table;

const leftStick = ["plant", "material"];
const rightStick = ["isReviewed", "RecommendedActionLostService"];

const StockoutInventoryTable = (props: any) => {
  const location = useLocation();
  const toaster = useToaster();
  const [loading, setLoading] = useState(false);
  const windowSize = useSelector(
    (state: any) => state.CommonReducer.windowSize
  );

  const [isExport, setIsExport] = useState(false);
  const exportFullRef = useRef<any>(null);
  const [tableData, setTableData] = useState(null);
  const [count, setCount] = useState(0);
  const [exportAllData, setExportALLData] = useState<any>([]);
  const [tableHeader, setTableHeader] = useState(STOCKOUT_INV_TABLE);
  const [pageNumber, setPageNumber] = useState(1);
  const type = new URLSearchParams(location.search).get("type");
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [selectedRowData, setSelectedRowData] = useState<any>([]);
  const didMount = useRef(false);
  const [coundtHandler, setCountHandler] = useState(0);

  const selectedGBPFilter = useSelector(
    (state: any) => state.CommonReducer.selectedGBPFilter
  );

  const getStockoutDataHandler = () => {
    if (isExport) {
      toaster.push(message, { placement: "topCenter" });
    } else {
      setLoading(true);
    }
    let payload = {
      ...selectedGBPFilter,
      alertType: Number(type),
      pageNumber: pageNumber || 1,
      pageSize: 100,
      isExport: isExport,
    };

    getStockoutData(payload)
      .then((response: any) => {
        if (response?.status === 200) {
          if (!isExport) {
            if (selectedRow.length !== 0) {
              if (
                !selectedGBPFilter?.plant?.includes(
                  selectedRowData[0]?.plant
                ) &&
                !selectedGBPFilter?.material?.includes(
                  selectedRowData[0]?.material
                )
              ) {
                setSelectedRow([]);
                setSelectedRowData([]);
                didMount.current = false;
              }
            }
            setTableData(response?.data?.data);
            setCount(response?.data?.count);
          } else {
            prepareExportData(response?.data?.data);
          }
        }

        setLoading(false);
        setIsExport(false);
      })
      .catch((err) => {
        setLoading(false);
        setIsExport(false);
        console.log(err);
      });
  };

  useEffect(() => {
    if (
      type &&
      pageNumber &&
      Object.keys(selectedGBPFilter).length !== 0 &&
      selectedRow?.length === 0
    ) {
      getStockoutDataHandler();
    }
  }, [type, pageNumber, selectedGBPFilter]);

  useEffect(() => {
    if (isExport) {
      getStockoutDataHandler();
    }
  }, [isExport]);

  useEffect(() => {
    if (exportAllData?.length !== 0) {
      exportFullRef?.current?.link.click();
    }
  }, [exportAllData]);

  useEffect(() => {
    let table = [...STOCKOUT_INV_TABLE];
    if (type === "0") {
      let obj = [
        {
          label: "OnHand ($)",
          key: "Onhand$Value",
        },
        {
          label: "OnHand (Qty)",
          key: "onHand",
        },
        {
          label: "Eff. SS (Qty)",
          key: "Effective_SS",
        },
        {
          label: "Adj. ORQ (Qty)",
          key: "Adjusted_ORQ",
        },
        {
          label: "Stockout Days M-3",
          key: "StockOutDays_M_3",
        },
        {
          label: "Stockout Days M-2",
          key: "StockOutDays_M_2",
        },
        {
          label: "Stockout Days M-1",
          key: "StockOutDays_M_1",
        },
        {
          label: "Stockout Qty Estimated (M-3)",
          key: "StockOutM_3",
        },
        {
          label: "Stockout Qty Estimated (M-2)",
          key: "StockOutM_2",
        },
        {
          label: "Stockout Qty Estimated (M-1)",
          key: "StockOutM_1",
        },
        {
          label: "Forecast Qty M-3",
          key: "MonthlyForecast_M_3",
        },
        {
          label: "Forecast Qty M-2",
          key: "MonthlyForecast_M_2",
        },
        {
          label: "Forecast Qty M-1",
          key: "MonthlyForecast_M_1",
        },
        {
          label: "Demand Qty M-3",
          key: "Qty_M_3",
        },
        
        {
          label: "Demand Qty M-2",
          key: "Qty_M_2",
        },
        {
          label: "Demand Qty M-1",
          key: "Qty_M_1",
        },
        {
          label: "Monthly Demand (Avg. Last 6M)",
          key: "AvgMonthlyDemandLast6M",
        },
        {
          label:'Monthly Forecast (next 6M)',
          key:'AvgFutureForeastNext6M'
      },
      {
        label:' Catalogue Number',
        key:'Item_Description'
    },
        {
          label: "Rep LT (Weeks) ",
          key: "Rep_LT",
        },
        {
          label: "LT Change (%) ",
          key: "AvgLeadTime",
        },
        {
          label: "Potential Actions",
          key: "RecommendedActionLostService",
        },
        {
          label: "Review Status",
          key: "isReviewed",
        },
      ];
      obj.forEach((value) => {
        table.push(value);
      });
      setTableHeader(table);
    } else if (type === "1") {
      let obj = [
        {
          label: "MRP Type",
          key: "mrp_type",
        },
        {
          label: "MRP Group",
          key: "mrp_group",
        },
        {
          label: "OnHand ($)",
          key: "Onhand$Value",
        },
        {
          label: "OnHand (Qty)",
          key: "onHand",
        },
        {
          label: "Open EROs (Qty)",
          key: "open_eros",
        },
        {
          label: "Total Dmd Within LT (Excluding Eff SS)",
          key: "Total_Dmd_Within_LT_Custom",
        },
        {
          label: "Eff. SS (Qty)",
          key: "Effective_SS",
        },
        {
          label: "Adj. ORQ (Qty)",
          key: "Adjusted_ORQ",
        },
        {
          label: "Count of Active SKULs (Vendor-Plant)",
          key: "ActiveSKUlCount",
        },
        {
          label: "Avg. PO frequency (weeks) (Vendor-Plant)",
          key: "POFrequency",
        },
        {
          label: "Inv at End of LT 1 (Qty)",
          key: "InvAtEndOfLT1",
        },
        {
          label: "End of LT 1 Date",
          key: "End_of_LT_Date",
        },

        {
          label: "Potential PO in LT2",
          key: "Open_ERO_LT1_LT2",
        },
        {
          label: "Demand in LT2(based on forecast)",
          key: "Forecasted_Demand_LT1_LT2",
        },
        {
          label: "Inv at end of LT2 (Qty)",
          key: "InvAtEndOfLT2",
        },
        {
          label: "End of LT 2 Date",
          key: "EndOfLTDateLT2",
        },
        {
          label: "Monthly Demand (Avg. Last 6M)",
          key: "AvgMonthlyDemandLast6M",
        },
        {
          label: "Avg Future Foreast Next 6M",
          key: "AvgFutureForeastNext6M",
        },
        {
          label: "Rep LT (Weeks) ",
          key: "Rep_LT",
        },
        {
          label: "LT Change 6 Months",
          key: "LTChange6Months",
        },
        {
          label: "Potential Actions",
          key: "RecommendedActionLostService",
        },
        {
          label: "Review Status",
          key: "isReviewed",
        },
      ];

      obj.forEach((value) => {
        table.push(value);
      });
      setTableHeader(table);
    } else if (type === "2") {
      let obj = [
        {
          label: "MRP Type",
          key: "mrp_type",
        },
        {
          label: "MRP Group",
          key: "mrp_group",
        },
        {
          label: "Current OnHand ($)",
          key: "Onhand$Value",
        },
        {
          label: "Current OnHand (Qty)",
          key: "onHand",
        },
        {
          label: "Open EROs (Qty)",
          key: "open_eros",
        },
        {
          label: "Total Dmd Within LT (Qty)",
          key: "Total_Dmd_Within_LT_Custom",
        },
        {
          label: "Inv at End of LT Current (Qty)",
          key: "InvAtEndOfLT1",
        },
        {
          label: "Eff. SS Current (Qty)",
          key: "Effective_SS",
        },
        {
          label: "Adj. ORQ Current (Qty)",
          key: "Adjusted_ORQ",
        },
        {
          label: "Monthly Demand (Avg. Last 6M)",
          key: "AvgMonthlyDemandLast6M",
        },
        {
          label: "Rep LT (Weeks) ",
          key: "Rep_LT",
        },
        {
          label: "LT Change (%) ",
          key: "AvgLeadTime",
        },
        {
          label: "Current Effective SS (Qty)",
          key: "Effective_SS",
        },
        {
          label: "Potential Actions",
          key: "RecommendedActionLostService",
        },
        {
          label: "Review Status",
          key: "isReviewed",
        },
      ];

      obj.forEach((value) => {
        table.push(value);
      });
      setTableHeader(table);
    }
  }, [type]);

  const handleRowSelection = (data: any) => {
    let index = selectedRow.indexOf(data?.SKUl);
    if (index !== -1) {
      // selectedRow.splice(index, 1);
      setSelectedRow([]);
      setSelectedRowData([]);
    } else {
      setSelectedRow([data?.SKUl]);
      setSelectedRowData([data]);
    }
  };

  useEffect(() => {
    if (didMount.current) {
      if (coundtHandler >= 1) {
        props?.onRowChange(selectedRowData);
      } else {
        setCountHandler(coundtHandler + 1);
      }
    } else {
      didMount.current = true;
      setCountHandler(coundtHandler + 1);
    }
  }, [selectedRowData]);

  const prepareExportData = (data: any) => {
    if (data) {
      data?.forEach((element: any) => {
        element.StockOutM_1 = "$" + formatNumber(element?.StockOutM_1);
        element.StockOutM_2 = "$" + formatNumber(element?.StockOutM_2);
        element.StockOutM_3 = "$" + formatNumber(element?.StockOutM_3);
        element.EndOfLTDateLT2 = element?.EndOfLTDateLT2?.slice(0, 10);
        element.End_of_LT_Date = element?.End_of_LT_Date?.slice(0, 10);
        element.InvAtEndOfLT1 = formatNumber(element?.InvAtEndOfLT1);
        element.Effective_SS = formatNumber(element?.Effective_SS);
        element.Total_Dmd_Within_LT_Custom = formatNumber(
          element?.Total_Dmd_Within_LT_Custom
        );
        element.open_eros = formatNumber(element?.open_eros);
        element.AvgMonthlyDemandLast6M = formatNumber(
          element?.AvgMonthlyDemandLast6M
        );
        element.onHand = formatNumber(element?.onHand);
      });
    }
    // console.log(data)
    setExportALLData(data);
  };

  const handleReviewChange = (data: any) => {
    updateReviewedInventoryData(data)
      .then((response) => {
        if (response?.status === 200) {
          getStockoutDataHandler();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  function getLabel(value: any) {
    switch (value) {
      case "Open_ERO_LT1_LT2":
        return `Max(Open EROs LT1, Adj. ORQ))`;
      case "InvAtEndOfLT1":
        return `Inv at End of LT1 = Onhand + Open ERO - Total Demand withn LT (Excluding Eff SS)`;
      case "InvAtEndOfLT2":
        return `Inv at End of LT2 = Inv at End of LT1 + Potential PO in LT2 - Demand in LT2(based on forecast)`;
    }
  }

  return (
    <>
      <Table
        height={windowSize?.height / 3.3}
        data={tableData || []}
        loading={loading}
        bordered={true}
        className="border-mainGray mt-2"
        cellBordered={true}
        headerHeight={65}
        // affixHeader={10}
        // fillHeight
        hover={false}
      >
        {tableHeader?.map((value) => {
          return (
            <Column
              fixed={
                rightStick.includes(value?.key)
                  ? "right"
                  : leftStick.includes(value?.key)
                  ? "left"
                  : false
              }
              key={value?.key}
              fullText={
                value?.key === "segment" || value?.key === "category"||value?.key==='Item_Description' ||value?.key==='RecommendedActionLostService'
                  ? true
                  : false
              }
              // flexGrow={type === "2" ? 1 : 0}
              width={
                value?.label?.length > 18 || value?.key === "RecommendedActionLostService"
                  ? windowSize?.width / 10
                  : windowSize?.width / 15
              }
            >
              <HeaderCell
                style={{
                  backgroundColor: "black",
                  color: "white",
                }}
                className="text-0.7vw text-center border-[#333231]"
              >
                {value?.key === "Open_ERO_LT1_LT2"  || value?.key === "InvAtEndOfLT1" || value?.key === "InvAtEndOfLT2" ? (
                  <Tooltip

                    title={getLabel(value?.key)}

                    placement="top"
                  >
                    <p className="text-wrap">{value?.label} <Info   className="text-white "
                                    sx={{
                                      fontSize: {
                                        xs: 3,
                                        sm: 7,
                                        md: 17,
                                        lg: 17,
                                      },
                                    }}></Info></p>
                  </Tooltip>
                ) : (
                  <p className="text-wrap">{value?.label}</p>
                )}
              </HeaderCell>
              <DataCell
                selectedRow={selectedRow}
                dataKey={value?.key}
                handleRowSelection={(data: any) => {
                  handleRowSelection(data);
                }}
                handleReviewChange={(data: any) => {
                  handleReviewChange(data);
                }}
              />
            </Column>
          );
        })}
      </Table>
      <div className="w-full flex justify-between p-2">
        <div className="w-3/12"></div>
        <div className="w-1/2 flex justify-center">
          <Pagination
            ellipsis
            boundaryLinks
            maxButtons={5}
            last
            next
            first
            size="xs"
            total={count}
            limit={100}
            layout={["pager", "skip"]}
            activePage={pageNumber}
            onChangePage={(page: any) => {
              setPageNumber(page);
            }}
          />
        </div>
        <div className="w-3/12 flex justify-end">
          <Button
            onClick={() => setIsExport(true)}
            className="w-20"
            color="green"
            appearance="primary"
          >
            Export
          </Button>
          <CSVLink
            data={exportAllData}
            ref={exportFullRef}
            headers={tableHeader}
            filename={
              type === "0"
                ? "High_Stockout"
                : type === "1"
                ? "Potential_Stockout"
                : "Low_DOS"
            }
            className="hidden"
          ></CSVLink>
        </div>
      </div>
    </>
  );
};

const DataCell = ({ rowData, dataKey, ...props }: any) => {
  const changeKey = [
    "Qty_M_1",
    "Adjusted_ORQ",
    "LTChange6Months",
    "Forecasted_Demand_LT1_LT2",
    "Open_ERO_LT1_LT2",
    "Qty_M_2",
    "Onhand$Value",
    "Qty_M_3",
    "StockOutM_1",
    "StockOutM_2",
    "StockOutM_3",
    "MonthlyForecast_M_1",
    "MonthlyForecast_M_2",
    "MonthlyForecast_M_3",
    "InvAtEndOfLT1",
    "ActiveSKUlCount",
    "EndOfLTDateLT2",
    "End_of_LT_Date",
    "Effective_SS",
    "Total_Dmd_Within_LT_Custom",
    "open_eros",
    "onHand",
    "AvgMonthlyDemandLast6M",
    "InvAtEndOfLT2","AvgFutureForeastNext6M"
  ];
  const dateOnly = ["EndOfLTDateLT2", "End_of_LT_Date"];
  const handleRowSelection = (data: any) => {
    props?.handleRowSelection({
      SKUl: data?.material + data?.plant,
      plant: data?.plant,
      material: data?.material,
    });
  };

  const handleReviewClick = (data: any, check: number) => {
    props.handleReviewChange({
      SKUl: data?.SKUl,
      reviewedFlag: check,
      alertType: "stockout",
    });
  };

  return (
    <Cell
      {...props}
      className="text-white"
      onClick={() => {
        handleRowSelection(rowData);
      }}
      style={{
        backgroundColor:
          props?.selectedRow[0] === rowData?.material + rowData?.plant
            ? "#56544e"
            : "#1a1916",
      }}
    >
      {dataKey !== "isReviewed" ? (
        <>
          {!changeKey?.includes(dataKey) && (
            <p className="truncate  text-0.7vw">
              {rowData[dataKey] !== null ? rowData[dataKey] : ""}
            </p>
          )}
          

          {dateOnly?.includes(dataKey) && (
            <p className="truncate  text-0.7vw">
              {rowData[dataKey] !== null ? rowData[dataKey].slice(0, 10) : ""}
            </p>
          )}
          {changeKey?.includes(dataKey) &&
            !dateOnly?.includes(dataKey) &&
            dataKey !== "LTChange6Months" && (
              <p className="truncate  text-0.7vw">
                {rowData[dataKey] !== null
                  ? `${dataKey === "Onhand$Value" ? "$" : ""}` +
                    formatNumber(rowData[dataKey])
                  : ""}
              </p>
            )}
          {dataKey === "LTChange6Months" && (
            <p className="truncate  text-0.7vw">
              {rowData[dataKey] !== null
                ? (Number(rowData[dataKey]) * 100).toFixed(0) + "%"
                : ""}
            </p>
          )}
          {dataKey === "ActiveSKUlCount" && (
            <p className="truncate  text-0.7vw">
              {rowData[dataKey] !== null
                ?  formatNumber(rowData[dataKey])
                : ""}
            </p>
          )}
        </>
      ) : (
        <ButtonToolbar className="pb-1 ">
          <ButtonGroup>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                handleReviewClick(rowData, 0);
              }}
              size="xs"
              appearance="ghost"
              className={`${
                rowData[dataKey] === 0
                  ? "bg-gray-500 text-white"
                  : "bg-white text-black"
              } border-black border-l-2 hover:bg-green-900 hover:text-white  text-xs`}
            >
              N
            </Button>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                handleReviewClick(rowData, 1);
              }}
              appearance="ghost"
              size="xs"
              className={`${
                rowData[dataKey] === 1
                  ? "bg-yellow-500 text-white"
                  : "bg-white text-black"
              } border-black border-l-2  hover:bg-green-900 hover:text-white text-xs `}
            >
              IP
            </Button>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                handleReviewClick(rowData, 2);
              }}
              appearance="ghost"
              size="xs"
              className={`${
                rowData[dataKey] === 2
                  ? "bg-green-500 text-white"
                  : "bg-white text-black"
              } border-black  hover:bg-green-900 hover:text-white  text-xs`}
            >
              Y
            </Button>
          </ButtonGroup>
        </ButtonToolbar>
      )}
    </Cell>
  );
};

const message = (
  <Notification type={"info"} header={`Exporting...`} closable>
    <p>Your file is being created. It will be download automatically</p>
  </Notification>
);

const mapDispatchToProps = (dispatch: any) => ({
  onRowChange: (data: any) => dispatch({ type: TABLEFILTER, data: data }),
});

export default connect(null, mapDispatchToProps)(StockoutInventoryTable);
