import React from "react";
import { Button, ButtonGroup, ButtonToolbar, Table } from "rsuite";
import { GRAPH_TABLE } from "../../constants/dataConstant";
import { formatNumber } from "../../utils/commonFunctions";
import { useSelector } from "react-redux";
import TimelineIcon from '@mui/icons-material/Timeline';
import TableChartIcon from '@mui/icons-material/TableChart';

const { Column, HeaderCell, Cell } = Table;

const ForecastGraphTabular = (props: any) => {
  const { graphTable, data } = props;
  const windowSize = useSelector(
    (state: any) => state.CommonReducer.windowSize
  );

  const handleGraph = (tab: number) => {
    props.setGraphTable(tab);
  };

  return (
    <>
      <div className=" flex justify-between">
        <p className={`text-white font-medium text-1.1 text-left mb-1`}>
          Demand vs Forecast
        </p>
        <ButtonToolbar className="pb-1 mr-3">
          <ButtonGroup>
            <Button
              onClick={() => {
                handleGraph(1);
              }}
              appearance="ghost"
              size="xs"
              className={`${
                graphTable === 1
                  ? "bg-green-500 text-white"
                  : "bg-white text-black"
              } border-black border-l-2 hover:bg-green-900 hover:text-white focus:bg-green-500 focus:text-white`}
            >
              <TimelineIcon sx={{fontSize:20}} />
            </Button>
            <Button
              onClick={() => handleGraph(2)}
              appearance="ghost"
              size="xs"
              className={`${
                graphTable === 2
                  ? "bg-green-500 text-white"
                  : "bg-white text-black"
              } border-black border-l-2  hover:bg-green-900 hover:text-white focus:bg-green-500 focus:text-white`}
            >
              <TableChartIcon sx={{fontSize:20}} />
            </Button>
          </ButtonGroup>
        </ButtonToolbar>
      </div>
      <div className="h-full">
        <Table
          style={{height:windowSize.width/7.4}}
          data={data}
          bordered={true}
          cellBordered={true}
          headerHeight={45}
          affixHeader={10}
          className="w-[100%] mr-3 border-mainGray border-2"
          fillHeight
          hover={false}
        >
          {GRAPH_TABLE?.map((value) => {
            return (
              <Column
                key={value?.key}
                // fullText
                flexGrow={1}
                // sortable
              >
                <HeaderCell
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    fontSize: 12,
                  }}
                  className="text-center"
                >
                  <p className="text-wrap">{value?.label}</p>
                </HeaderCell>
                <DataCell dataKey={value?.key} />
              </Column>
            );
          })}
        </Table>
      </div>
    </>
  );
};

const DataCell = ({ rowData, dataKey, ...props }: any) => {


  return (
    <Cell style={{backgroundColor:'#1a1916'}} {...props} className="text-white ">
      {dataKey === "forecastDate" && (
        <p className="truncate  text-0.7vw">{rowData[dataKey]}</p>
      )}
      {dataKey !== "forecastDate" && (
        <p className="truncate  text-0.7vw">{rowData[dataKey]!==null?formatNumber(rowData[dataKey]):'NA'}</p>
      )}
    </Cell>
  );
};

export default ForecastGraphTabular;
