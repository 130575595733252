import React, { useState } from "react";
import {
  Bar,
  BarChart,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { DUMMY, DUMMYInactive } from "../../constants/dataConstant";
import { useSelector } from "react-redux";
import { Button, ButtonGroup, ButtonToolbar } from "rsuite";
import { formatAmount, formatNumber } from "../../utils/commonFunctions";

const StockoutInventoryBarGraph = (props: any) => {
  console.log(props,'props')
  const {barGraphData}=props
  const [graphTab,setGraphTab]=useState(1)

  const windowSize = useSelector(
    (state: any) => state.CommonReducer.windowSize
  );
  

  return (
    <div className="w-full">
        <div className="flex w-full justify-between items-baseline">
      <span className="text-white font-medium text-0.95vw ">{graphTab===1?'Monthly Stockout Days Breakdown':'Monthly Lost Sale $ Breakdown'}</span>
      

        </div>
        <ResponsiveContainer
      width={"100%"}
      height={windowSize?.width / 6.5}
      className={`border-[1px] border-mainGray`}
    >
      <ComposedChart
        width={500}
        height={300}
        data={graphTab===1?barGraphData?.stockOut:barGraphData?.lostSale}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis fontSize={10} stroke="#B5B5B5" dataKey="name" />
        <YAxis  yAxisId="right-axis"  hide={true}  
                orientation="right"  />
        <YAxis stroke="#B5B5B5"  fontSize={10} tickFormatter={(value: string) => value && `${graphTab===1?formatNumber(Number(value)):formatAmount(Number(value))}`}/>
        <Tooltip cursor={{fill: 'transparent'}} content={($) => <CustomTooltip data={$} graphTab={graphTab}/>} />
        <Legend verticalAlign="top" />

        {barGraphData?.key?.map((value:any)=>{
          return(
            <>
            <Bar dataKey={value?.key} barSize={60} stackId="a" fill={value?.color} />
           
            </>
          )

        })}
 <Line
              name="DOHM"
              dataKey="dohm"
              stroke="#c7da01"
              fill="#c7da01"
              strokeWidth={3}
              dot={{ strokeWidth: 1, r: 3 }}
                   yAxisId="right-axis"
            />
          {/* <Bar dataKey="uv" barSize={60}  stackId="a" fill="#57A4AD" />
          <Bar dataKey="amt" barSize={60}  stackId="a" fill="#4ACB4A" /> */}
      </ComposedChart>
    </ResponsiveContainer>

    <div className="flex justify-end">
    <span className="text-white font-medium text-0.7vw">Note: M stands for current month</span>
    </div>
    </div>
  );
};

const renderCustomizedLabel = (props: any) => {
  const { x, y, width, value,name } = props;
console.log(props)
  return (
    <>
      <text x={x + width / 2} y={y- (value>0?10:-10) } fill='#fff' textAnchor="middle" dominantBaseline="middle" >
        {parseFloat(value)*100}%
      </text>
    </>
  );
};

const CustomTooltip = (props: any) => {
  const { active, payload } = props.data;
  if (active && payload && payload.length) {
    return (
      <>
        <div className="bg-[#353535] p-3">
          <>
            <p className="text-white text-0.95vw">
              {payload[0]?.payload?.name}
            </p>
            {payload?.map(($: any) => {
              return (
                <>
                  <p className="text-white text-0.7vw">
                    {$?.name} : {props?.graphTab===1?formatNumber(parseFloat($?.value)):formatAmount(parseFloat($?.value))}
                  </p>
                </>
              );
            })}
          </>
        </div>
      </>
    );
  }

  return null;
};

export default StockoutInventoryBarGraph;
