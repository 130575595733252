import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Dropdown,
  Modal,
  Notification,
  Pagination,
  Popover,
  Table,
  useToaster,
  Whisper,
} from "rsuite";
import { DATA_TABLE } from "../../constants/dataConstant";
import {
  getExportData,
  getGainsFormatExportData,
  getScenarioPlanningData,
  updateForecastData,
  updateReviewedForecastData,
} from "../../queries/apiQueries";
import { ForeaCastData, UpdateForeCast } from "../../model/forecastModel";
import { connect, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import { useLocation } from "react-router-dom";
import { appInsights } from "../../pages/router";
import { formatNumber } from "../../utils/commonFunctions";
import InfoIcon from '@mui/icons-material/Info';
import ForecastCustomerLevelTable from "./forecastCustomerLevelTable";
const { Column, HeaderCell, Cell } = Table;

export const TABLEFILTER='TABLEFILTER'

const leftStick = [
  "PLANT_PLANT",
  "MATERIAL_MATERIAL",
  "material_description",
  
];
const rightStick = [
  "AdjustedForecast1",
  "AdjustedForecast0",
  "forecastMonth1",
  "Comments",
  "forecastMonth0",
  "isReviewed",
];
const nonSorting = ["isReviewed", "AdjustedForecast1", "AdjustedForecast0"];

const ForecastDataTable = (props: any) => {
  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [first, setFirst] = useState(false);
  const [foreCastData, setForeCastData] = useState<ForeaCastData>({ data: [] });
  const [changedAdjustedForecast, setChangedAdjustedForecast] =
    useState<UpdateForeCast>({ data: [] });
  const [saveButton, setSaveButton] = useState(true);
  const [exportData, setExportData] = useState<any>([]);
  const [exportAllData, setExportALLData] = useState<any>([]);
  const [exportGainData, setExportGainData] = useState<any>([]);
  const [exportAdjustedData, setExportAdjustedData] = useState<any>([]);
  const exportFullRef = useRef<any>(null);
  const exportGainRef = useRef<any>(null);
  const exportAdjustedRef = useRef<any>(null);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [selectedRowData, setSelectedRowData] = useState<any>([]);
  const [selectedSKUL, setSelectedSKUL] = useState<any>(null);

  const [openModal,setOpenModal]=useState(false)
  const windowSize = useSelector(
    (state: any) => state.CommonReducer.windowSize
  );


  const didMount=useRef(false)

  const toaster = useToaster();

  let location = useLocation();

  const alertType = new URLSearchParams(location.search).get("alert");

  const selectedGBPFilter = useSelector(
    (state: any) => state.CommonReducer.selectedGBPFilter
  );
  const [selectedFilterData, setSelectedFilterData] = useState<any>({
    orderBy: "AnnualRevenue",
    order: "desc",
    pageNumber: 1,
    pageSize: 100,
    monthNumber: [0, 1],
  });

  const getData = () => {
    let data = foreCastData?.data;
    if (sortColumn && sortType) {
      return data.sort((a, b) => {
        let x: any = a[sortColumn];
        let y: any = b[sortColumn];
        if (typeof x === "string") {
          x = x.charCodeAt(0);
        }
        if (typeof y === "string") {
          y = y.charCodeAt(0);
        }
        if (sortType === "asc") {
          return x - y;
        } else {
          return y - x;
        }
      });
    }
    return data;
  };

  const handleSortColumn = (sortColumn: any, sortType: any) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  useEffect(() => {

    if(Object.keys(selectedGBPFilter).length !== 0&&selectedRow?.length===0){
      getScenarioPlanningTableData()
    }
    
    // if (
    //   Object.keys(selectedGBPFilter).length !== 0 &&
    //   selectedGBPFilter?.alerts?.length !== 0 &&
    //   first === false &&
    //   alertType
    // ) {

    //   getScenarioPlanningTableData();
    //   setFirst(true);
    // }

    // if ((first || !alertType)&&selectedRow?.length===0) {
      
    //   getScenarioPlanningTableData();
    // }
  }, [selectedGBPFilter]);

  const getScenarioPlanningTableData = async (data: any = null) => {
    setLoading(true);
    let payload = {
      ...selectedGBPFilter,
      orderBy: "annualRevenue",
      order: "desc",
      pageNumber: data?.pageNumber ? data?.pageNumber : 1,
      pageSize: 100,
    };

    if(selectedRow?.length!==0){
      payload={...payload,plant:[],material:[]}
    }

    getScenarioPlanningData(payload)
      .then((response) => {
        if (response?.status === 200 && response?.data) {
          setForeCastData({ data: response?.data?.data });
          setCount(response?.data?.count);
          prepareExport(response?.data?.data);
          setSaveButton(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleAdjustedForecast = (data: any) => {
    let found = false,
      foreCastData = changedAdjustedForecast.data;
    foreCastData.forEach((element: any) => {
      if (element?.SKUl === data?.SKUl) {
        if (data?.type === 0) {
          element.adjustedForecast0 = data?.adjustedForecast;
        } else if (data?.type === 1) {
          element.adjustedForecast1 = data?.adjustedForecast;
        } else {
          element.Comments = data?.Comments;
        }
        element.previousAdjustedForecast0 = data?.Forecast0;
        element.previousAdjustedForecast1 = data?.Forecast1;
        found = true;
      }
    });

    if (!found) {
      foreCastData.push({
        SKUl: data?.SKUl,
        adjustedForecast0:
          data?.type === 0 ? data?.adjustedForecast : data?.Forecast0,
        adjustedForecast1:
          data?.type === 1 ? data?.adjustedForecast : data?.Forecast1,
        previousAdjustedForecast0: data?.Forecast0,
        previousAdjustedForecast1: data?.Forecast1,
        prevForecast0: data?.prevForecast0,
        prevForecast1: data?.prevForecast1,
        userOverride: 0,
        Comments:
          data?.type === 2
            ? data?.Comments
            : data?.Comments
            ? data?.Comments
            : "",
      });
    }

    console.log(foreCastData);

    let filteredData: any = foreCastData;
    if (data?.type !== 2) {
      filteredData = foreCastData.filter(
        (element: any) =>
          element?.adjustedForecast0 !== element?.previousAdjustedForecast0 ||
          element?.adjustedForecast1 !== element?.previousAdjustedForecast1
      );

      if (filteredData?.length !== 0) {
        filteredData.forEach((value: any) => {
          value.userOverride =
            value?.adjustedForecast0 === value?.prevForecast0 &&
            value?.adjustedForecast1 === value?.prevForecast1
              ? 0
              : 1;
        });
      }
    }

    setSaveButton(filteredData.length !== 0 || data?.type === 2 ? false : true);
    setChangedAdjustedForecast({ data: filteredData });
  };

  const updateForecast = async () => {
    updateForecastData(changedAdjustedForecast)
      .then((response) => {
        appInsights.trackEvent({
          name: `Update Adjusted Forecast by ${localStorage.getItem('name')}`,
        });
        if (response?.status === 200) {
          setLoading(true);
          getScenarioPlanningTableData({
            monthNumber: props?.monthNumber,
            pageNumber: selectedFilterData.pageNumber,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleReviewChange = async (data: any) => {
    updateReviewedForecastData(data)
      .then((response) => {
        appInsights.trackEvent({
          name: `Update IsReviewed Forecast by ${localStorage.getItem('name')}`,
        });
        if (response?.status === 200) {
          setLoading(true);
          getScenarioPlanningTableData({
            monthNumber: props?.monthNumber,
            pageNumber: selectedFilterData.pageNumber,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };



  const prepareExport = (data: any, type: number = 0) => {
    let finalData: any = [];
    data.forEach((element: any) => {
      let obj = {
        PFXPG:element.PFXPG,
        delta:element?.delta,
        service_center_cluster_name: element.service_center_cluster_name,
        PLANT_PLANT: element.PLANT_PLANT,
        MATERIAL_MATERIAL: element.MATERIAL_MATERIAL,
        vendor_name: element.vendor_name,
        Alerts: element.Alerts,
        forecast_date: element?.forecast_date?.slice(0, 10),
        standard_price: `$${formatNumber(
          (element.standard_price || 0)?.toFixed(2)
        )}`,
        annualRevenue: `$${formatNumber(
          (element.annualRevenue || 0)?.toFixed(2)
        )}`,
        AvgDemand_Last12Months: formatNumber(
          (element.AvgDemand_Last12Months || 0)?.toFixed(0)
        ),
        AvgDemand_Last8Months: formatNumber(
          (element.AvgDemand_Last8Months || 0)?.toFixed(0)
        ),
        AvgDemand_Last4Months: formatNumber(
          (element.AvgDemand_Last4Months || 0)?.toFixed(0)
        ),
        CurrentMonthDemandLY: formatNumber(
          (element.CurrentMonthDemandLY || 0)?.toFixed(0)
        ),
        AvgForecast_Last4Months: formatNumber(
          (element.AvgForecast_Last4Months || 0)?.toFixed(0)
        ),
        Last4Month_Accuracy: `${(
          (element.Last4Month_Accuracy || 0) * 100
        )?.toFixed(0)}%`,
        Last4Month_BIAS: element.Last4Month_BIAS,
        Lag1_Foreast: formatNumber((element.Lag1_Foreast || 0)?.toFixed(0)),
        Lag2_Foreast: formatNumber((element.Lag2_Foreast || 0)?.toFixed(0)),
        forecastMonth0: formatNumber((element.forecastMonth0 || 0)?.toFixed(0)),
        CurrentMonthOpenSalesQty:formatNumber((element.CurrentMonthOpenSalesQty || 0)?.toFixed(0)),
        AdjustedForecast0: formatNumber(
          (element.AdjustedForecast0 || 0)?.toFixed(0)
        ),
        forecastMonth1: formatNumber(
          (element.forecastMonth1 || 0)?.toFixed(0)
        ),
        AdjustedForecast1: formatNumber(
          (element.AdjustedForecast1 || 0)?.toFixed(0)
        ),
        Comments:element.Comments,
        vendor_number:element.vendor_number,
        material_description:element.material_description,
        isReviewed:element.isReviewed===0?'No': element.isReviewed === 2 ? 'In Progress' : 'Yes',
      };
      finalData.push(obj);
    });
    if (type === 0) {
      setExportData(finalData);
      console.log(finalData);
      
    } else if (type === 1) {
      appInsights.trackEvent({
        name: `Export Complete Data by ${localStorage.getItem('name')}`,
      });
      setExportALLData(finalData);
      console.log(finalData);
    } else if (type === 2) {
      setExportAdjustedData(finalData);
    }
  };

  useEffect(() => {
    if (exportAllData?.length !== 0) {
      exportFullRef?.current?.link.click();
    }
  }, [exportAllData]);

  useEffect(() => {
    if (exportGainData?.length !== 0) {
      exportGainRef?.current?.link.click();
    }
  }, [exportGainData]);

  useEffect(() => {
    if (exportAdjustedData?.length !== 0) {
      exportAdjustedRef?.current?.link.click();
    }
  }, [exportAdjustedData]);

  const getAllDataExport = async () => {
    toaster.push(message, { placement: "topCenter" });
    let payload = {
      ...selectedGBPFilter,
      monthNumber: selectedFilterData?.monthNumber,
    };
    getExportData(payload)
      .then((response) => {
        if (response?.status === 200) {
          prepareExport(response?.data?.data, 1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getGainsDataExport = async () => {
    toaster.push(message, { placement: "topCenter" });
    getGainsFormatExportData({}, 0)
      .then((response) => {
        appInsights.trackEvent({
          name: `Export Complete Data (Gains Format) by ${localStorage.getItem('name')}`,
        });
        if (response?.status === 200) {
          
          setExportGainData(response?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAdjustedDataExport = async () => {
    toaster.push(message, { placement: "topCenter" });
    getGainsFormatExportData({}, 1)
      .then((response) => {
        if (response?.status === 200) {
          appInsights.trackEvent({
            name: `Export Adjusted Forecast Data (Gains Format) by ${localStorage.getItem('name')}`,
          });
          if (response?.data?.data?.length !== 0) {
            setExportAdjustedData(response?.data?.data);
          } else {
            toaster.push(warning, { placement: "topCenter" });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRowSelection=(data:any)=>{
    let index=selectedRow.indexOf(data?.SKUl)
    if (index !== -1) {
      // selectedRow.splice(index, 1);
      setSelectedRow([]);
      setSelectedRowData([]);
    } else {
      setSelectedRow([data?.SKUl]);
      setSelectedRowData([ data]);
    }
  }


  useEffect(()=>{
    console.log('seelced',selectedRowData)
    if(didMount.current){
      props?.onRowChange(selectedRowData)
    }else{
      didMount.current=true
    }
  },[selectedRowData])

  const fullText=['annualRevenue','Alerts','material_description']

  return (
    <>
      <Table
        height={windowSize?.width/6.2}
        data={getData()}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        loading={loading}
        bordered={true}
        className="border-gray-700 mt-2"
        headerHeight={windowSize?.width/25}
        hover={false}
      >
        {DATA_TABLE?.map((value) => {
          return (
            <Column
              key={value?.key}

              width={value?.label==='Alert Type'||value?.label==='Material'?windowSize?.width/10:windowSize?.width/15}

              fullText={fullText?.includes(value?.key)?true:false}
              fixed={
                rightStick.includes(value?.key)
                  ? "right"
                  : leftStick.includes(value?.key)
                  ? "left"
                  : false
              }
              sortable
            >
              <HeaderCell
                style={{
                  backgroundColor: "black",
                  color: "white",
                  fontWeight:'700',
                }}
                className="text-0.7vw text-center border-[#333231]"
                
                sortable={nonSorting.includes(value?.key) ? false : true}
              >
                <Whisper
                  placement="top"
                  speaker={
                    <Popover>
                      <p>{value?.label}</p>
                    </Popover>
                  }
                >
                  <p className="text-wrap">{value?.label}</p>
                </Whisper>
              </HeaderCell>
              <DataCell
                selectedGBPFilter={selectedGBPFilter}
                setOpenModal={(data:any)=>{setOpenModal(true);setSelectedSKUL(data)}}
                dataKey={value?.key}
                foreCastingChange={(data: any) => {
                  handleAdjustedForecast(data);
                }}
                handleReviewChange={(data: any) => {
                  handleReviewChange(data);
                }}
                selectedRow={selectedRow}
                handleRowSelection={(data: any) => {
                  handleRowSelection(data);
                }}
              />
            </Column>
            
          );
        })}
      </Table>
      <div className="w-full flex justify-between p-2">
        <div className="w-3/12">
          <span className="text-white text-0.7vw">Note: Select/Unselect Plant/Material to filter data</span>
        </div>
        <div className="w-1/2 flex justify-center">
          <Pagination
            ellipsis
            boundaryLinks
            maxButtons={5}
            last
            next
            first
            size="xs"
            total={count}
            limit={selectedFilterData?.pageSize}
            layout={["pager", "skip"]}
            activePage={selectedFilterData?.pageNumber}
            onChangePage={(page: any) => {
              setSelectedFilterData({
                ...selectedFilterData,
                pageNumber: page,
              });
              getScenarioPlanningTableData({
                monthNumber: props?.monthNumber,
                pageNumber: page,
              });
            }}
          />
        </div>
        <div className="w-3/12 flex justify-end">
          <Button
            onClick={updateForecast}
            disabled={saveButton}
            className="w-20"
            color="green"
            appearance="primary"
          >
            Save
          </Button>
          <Dropdown
            className=" ml-2"
            title="Export"
            trigger="hover"
            placement="leftEnd"
          >
            <Dropdown.Item>
              <CSVLink
                data={exportData}
                headers={DATA_TABLE}
                filename={"ForeCast100"}
                onClick={()=>{appInsights.trackEvent({
                  name: `Export 100 Data by ${localStorage.getItem('name')}`,
                });}}
              >
                Export This Page
              </CSVLink>
            </Dropdown.Item>
            <Dropdown.Item className="hidden">
              <CSVLink
                data={exportAllData}
                ref={exportFullRef}
                headers={DATA_TABLE}
                filename={"ForeCastComplete"}
              ></CSVLink>
            </Dropdown.Item>
            <Dropdown.Item onClick={getAllDataExport}>
              Export Complete Data
            </Dropdown.Item>
            <Dropdown.Item className="hidden">
              <CSVLink
                data={exportAdjustedData}
                ref={exportAdjustedRef}
                filename={"AdjustedForecast"}
              ></CSVLink>
            </Dropdown.Item>
            <Dropdown.Item onClick={getAdjustedDataExport}>
              Export Adjusted Forecast Data (Gains Format)
            </Dropdown.Item>
            <Dropdown.Item className="hidden">
              <CSVLink
                data={exportGainData}
                ref={exportGainRef}
                filename={"GainsFormat"}
              ></CSVLink>
            </Dropdown.Item>

            <Dropdown.Item onClick={getGainsDataExport}>
              Export Complete Data (Gains Format)
            </Dropdown.Item>
          </Dropdown>
        </div>
      </div>

      <Modal size={'65vw'} open={openModal} onClose={()=>{setOpenModal(false);setSelectedSKUL(null)}}>
        <Modal.Header>
          <Modal.Title className="text-white">Customer Level Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Placeholder.Paragraph rows={size === 'full' ? 100 : 10} /> */}
          <ForecastCustomerLevelTable SKUl={selectedSKUL} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={()=>{setOpenModal(false);setSelectedSKUL(null)}} className="bg-red-600 text-white focus:bg-red-900" appearance="subtle">
            Close
          </Button>

        </Modal.Footer>
      </Modal>
    </>
  );
};

const DataCell = ({ rowData, dataKey, ...props }: any) => {
  const [changeData, setChangeData] = useState<any>(null);
  const windowSize = useSelector(
    (state: any) => state.CommonReducer.windowSize
  );

  let keyWords = [
    "forecast_date",
    "AdjustedForecast1",
    "AdjustedForecast0",
    "CurrentMonthDemandLY",
    "forecastMonth1",
    "forecastMonth0",
    "standard_price",
    "annualRevenue",
    "AvgDemand_Last12Months",
    "AvgDemand_Last8Months",
    "AvgDemand_Last4Months",
    "AvgForecast_Last4Months",
    "Last4Month_Accuracy",
    "Lag1_Foreast",
    "Lag2_Foreast",
    "Comments",
    "isReviewed",
    "Alerts",
    "CurrentMonthOpenSalesQty",
    "MATERIAL_MATERIAL",
    "delta"
  ];

  

  const handleForecastChange = (data: any) => {
    let obj = {
      SKUl: data?.SKUl,
      adjustedForecast: parseInt(data?.newValue),
      type: data?.type,
      Forecast0: parseInt(data?.Forecast0),
      Forecast1: parseInt(data?.Forecast1),
      prevForecast0: parseInt(data?.prevForecast0),
      prevForecast1: parseInt(data?.prevForecast1),
      Comments: data?.Comments,
    };

    setChangeData(obj);
  };

  const handleReviewClick=(data:any)=>{
    props.handleReviewChange([{
      SKUl:data?.SKUl,
      reviewedFlag:data?.check,
      Comments:data?.Comments
    }])

}

  useEffect(()=>{
    if(changeData){
      props.foreCastingChange(changeData);
    }
  }, [changeData]);

  const handleRowSelection = (data: any) => {
    if((dataKey==='MATERIAL_MATERIAL'||dataKey==='PLANT_PLANT')){
      props?.handleRowSelection({SKUl:data?.SKUl,plant:data?.PLANT_PLANT,material:data?.MATERIAL_MATERIAL})
    }
  };


  return (
    <Cell
      {...props}
      // className={`${props?.selectedRow.includes(rowData?.SKUl) ? "bg-[#ccfcd1]" : "bg-[#1A1916]"} cursor-pointer `}
      className={` text-white cursor-pointer ${props.selectedRow.includes(rowData.SKUl) ? 'bg-[#ccfcd1] ' : 'bg-[#ffffff]'}`}
      style={{backgroundColor:props?.selectedRow[0]===rowData?.SKUl?'#56544e':'#1a1916'}}
      onClick={() => {handleRowSelection(rowData)}}
      height={windowSize?.width/25}
    >
      {!keyWords.includes(dataKey) && (
        <p className="truncate text-0.7vw ">{rowData[dataKey]}</p>
      )}
      {dataKey === "MATERIAL_MATERIAL" && (
        <p className="truncate text-0.7vw ">{rowData[dataKey]} 
        {(rowData?.Alerts?.includes('ConsistentHistoricalUnderForecasting')||rowData?.Alerts?.includes('ConsistentHistoricalOverForecasting'))&&
          <span onClick={(e)=>{e.stopPropagation();props.setOpenModal(rowData?.SKUl)}}>
          <InfoIcon   className="ml-2" sx={{
          fontSize: { xs: 15, sm: 15, md: 15, lg: 15 },
        }}/>
          </span>}</p>
      )}
      {dataKey === "forecast_date" && (
        <p className="truncate text-0.7vw ">{rowData[dataKey]?.slice(0, 10)}</p>
      )}
      {dataKey === "standard_price" && (
        <p className="truncate text-0.7vw ">
          ${formatNumber((rowData[dataKey] || 0)?.toFixed(2))}
        </p>
      )}
      {dataKey === "delta" && (
        <p className="truncate text-0.7vw ">
          ${formatNumber((rowData[dataKey] || 0)?.toFixed(2))}
        </p>
      )}
      {dataKey === "annualRevenue" && (
        <p className="truncate text-0.7vw ">
          ${formatNumber((rowData[dataKey] || 0)?.toFixed(2))}
        </p>
      )}
      {dataKey === "AvgDemand_Last12Months" && (
        <p className="truncate text-0.7vw ">
          {formatNumber((rowData[dataKey] || 0)?.toFixed(0))}
        </p>
      )}
      {dataKey === "Alerts" && (
        <p className="truncate text-0.7vw ">
          {rowData[dataKey].replace(/([a-z])([A-Z])/g, "$1 $2")}
        </p>
      )}
      {dataKey === "AvgDemand_Last8Months" && (
        <p className="truncate text-0.7vw ">
          {formatNumber((rowData[dataKey] || 0)?.toFixed(0))}
        </p>
      )}
      {dataKey === "AvgDemand_Last4Months" && (
        <p className="truncate text-0.7vw ">
          {formatNumber((rowData[dataKey] || 0)?.toFixed(0))}
        </p>
      )}
      {dataKey === "AvgForecast_Last4Months" && (
        <p className="truncate text-0.7vw ">
          {formatNumber((rowData[dataKey] || 0)?.toFixed(0))}
        </p>
      )}
      {dataKey === "CurrentMonthDemandLY" && (
        <p className="truncate text-0.7vw ">
          {formatNumber((rowData[dataKey] || 0)?.toFixed(0))}
        </p>
      )}
      {dataKey === "Last4Month_Accuracy" && (
        <p className="truncate text-0.7vw ">
          {((rowData[dataKey] || 0) * 100)?.toFixed(0)}%
        </p>
      )}
      {dataKey === "forecastMonth1" && (
        <p className="truncate text-0.7vw ">
          {formatNumber((rowData[dataKey] || 0)?.toFixed(0))}
        </p>
      )}
      {dataKey === "CurrentMonthOpenSalesQty" && (
        <p className="truncate text-0.7vw ">
          {formatNumber((rowData[dataKey] || 0)?.toFixed(0))}
        </p>
      )}
      {dataKey === "forecastMonth0" && (
        <p className="truncate text-0.7vw ">
          {formatNumber((rowData[dataKey] || 0)?.toFixed(0))}
        </p>
      )}
      {dataKey === "Lag1_Foreast" && (
        <p className="truncate text-0.7vw ">
          {formatNumber((rowData[dataKey] || 0)?.toFixed(0))}
        </p>
      )}
      {dataKey === "Lag2_Foreast" && (
        <p className="truncate text-0.7vw ">
          {formatNumber((rowData[dataKey] || 0)?.toFixed(0))}
        </p>
      )}
      {dataKey === "AdjustedForecast1" && (
        <input
          onClick={(e)=>e.stopPropagation()}
          type="number"
          key={rowData[dataKey]}
          defaultValue={rowData[dataKey]}
          className="w-16 h-4 text-[12px] px-2 bg-[grey] text-white [&::-webkit-inner-spin-button]:appearance-none"
          onChange={($) =>
            handleForecastChange({
              newValue: $?.target?.value,
              SKUl: rowData["SKUl"],
              type: 1,
              Forecast0: rowData["AdjustedForecast0"],
              Forecast1: rowData[dataKey],
              prevForecast0: rowData["forecastMonth0"],
              prevForecast1: rowData["forecastMonth1"],
              Comments: rowData["Comments"],
            })
          }
        />
      )}
      {dataKey === "AdjustedForecast0" && (
        <input
        onClick={(e)=>e.stopPropagation()}
          type="number"
          key={rowData[dataKey]}
          defaultValue={rowData[dataKey]}
          className="w-16  h-4 text-[12px] px-2 border-gray-700  bg-[grey] text-white [&::-webkit-inner-spin-button]:appearance-none"
          onChange={($) =>
            handleForecastChange({
              newValue: $?.target?.value,
              SKUl: rowData["SKUl"],
              type: 0,
              Forecast0: rowData[dataKey],
              Forecast1: rowData["AdjustedForecast1"],
              prevForecast0: rowData["forecastMonth0"],
              prevForecast1: rowData["forecastMonth1"],
              Comments: rowData["Comments"],
            })
          }
        />
      )}

      {dataKey === "Comments" && (
        <textarea
        onClick={(e)=>e.stopPropagation()}
          key={rowData[dataKey]}
          defaultValue={rowData[dataKey]}

          className="w-full text-[12px] border-[0.5px] text-black rounded-lg h-[35px] relative top-[-10px] px-2 border-gray-700 "

          onChange={($) =>
            handleForecastChange({
              Comments: $?.target?.value,
              SKUl: rowData["SKUl"],
              type: 2,
              Forecast0: rowData["AdjustedForecast0"],
              Forecast1: rowData["AdjustedForecast1"],
              prevForecast0: rowData["forecastMonth0"],
              prevForecast1: rowData["forecastMonth1"],
              newValue: 0,
            })
          }
        />
      )}
      {dataKey === "isReviewed" && (
        <ButtonToolbar className="pb-1 ">
        <ButtonGroup>
          <Button
            // onClick={() => {
            //   handleGraph(1);
            // }}
            onClick={(e) => {
                  e.stopPropagation()
                  handleReviewClick({
                    check: 0,
                    SKUl: rowData["SKUl"],
                    Comments: rowData["Comments"],
                  });
                }}
            size="xs"
            appearance="ghost"
            className={`${rowData[dataKey] === 0 ?  "bg-gray-500 text-white" : "bg-white text-black"
            } border-black border-l-2 hover:bg-green-900 hover:text-white  text-xs`}
          >
            N
          </Button>
          <Button
            // onClick={() => handleGraph(2)}
            onClick={(e) => {
              e.stopPropagation()
              handleReviewClick({
                check: 2,
                SKUl: rowData["SKUl"],
                Comments: rowData["Comments"],
              });
            }}
            appearance="ghost"
            size="xs"
            className={`${rowData[dataKey] === 2 ?
                 "bg-yellow-500 text-white" :"bg-white text-black" 
            } border-black border-l-2  hover:bg-green-900 hover:text-white text-xs `}
          >
            IP
          </Button>
          <Button
            // onClick={() => {
            //   handleGraph(3);
            // }}
            onClick={(e) => {
              e.stopPropagation()
              handleReviewClick({
                check:  1,
                SKUl: rowData["SKUl"],
                Comments: rowData["Comments"],
              });
            }}
            appearance="ghost"
            size="xs"
            className={`${rowData[dataKey] === 1 ?
                 "bg-green-500 text-white" : "bg-white text-black" 
            } border-black  hover:bg-green-900 hover:text-white  text-xs`}
          >
            Y
          </Button>
        </ButtonGroup>
      </ButtonToolbar>
      )}

    </Cell>
  );
};

const message = (
  <Notification type={"info"} header={`Exporting...`} closable>
    <p>Your file is being created. It will be download automatically</p>
  </Notification>
);

const warning = (
  <Notification type={"warning"} header={`No Adjusted Data Found!`} closable>
    <p>Currently there is no adjusted forecast data to export</p>
  </Notification>
);

// export default ForecastDataTable; 

const mapDispatchToProps = (dispatch:any) => ({
  onRowChange: (data:any) => dispatch({ type: TABLEFILTER,data:data }),
});

export default connect(null,mapDispatchToProps)(ForecastDataTable);

