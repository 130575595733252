import React, { useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Button, Notification, Pagination, Table, useToaster } from "rsuite";
import { LONGTERM_TABLE, PO_INV_TABLE } from "../../constants/dataConstant";
import { getHighOutlierTableData, getPotentialOverstockData } from "../../queries/apiQueries";
import { CSVLink } from "react-csv";
import { TABLEFILTER } from "../forecast/forecastDataTable";
import { formatNumber } from "../../utils/commonFunctions";

const { Column, HeaderCell, Cell } = Table;

const ForecastLongTermTable = (props: any) => {

  const {getHighOutlierCustomerDataHandler}=props

  const windowSize = useSelector(
    (state: any) => state.CommonReducer.windowSize
  );
  const toaster = useToaster();
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [tableData, setTableData] = useState(null);
  const [count, setCount] = useState(0);
  const [exportAllData, setExportALLData] = useState<any>([]);
  const [isExport, setIsExport] = useState(false);
  const exportFullRef = useRef<any>(null);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [selectedRowData, setSelectedRowData] = useState<any>([]);
  const didMount = useRef(false);
  const [coundtHandler, setCountHandler] = useState(0);

  const selectedGBPFilter = useSelector(
    (state: any) => state.CommonReducer.selectedGBPFilter
  );

  const getHighOutlierTableDataHandler = () => {
    if (isExport) {
      toaster.push(message, { placement: "topCenter" });
    } else {
      setLoading(true);
    }
    let payload = {
      ...selectedGBPFilter,
      pageNumber: pageNumber || 1,
      pageSize: 100,
      isExport: isExport,
    };

    getHighOutlierTableData(payload)
      .then((response: any) => {
        if (response?.status === 200) {
          if (!isExport) {
            setTableData(response?.data?.data);
            setCount(response?.data?.count);
          } else {
            prepareExportData(response?.data?.data)
          }
        }

        setLoading(false);
        setIsExport(false);
      })
      .catch((err) => {
        setLoading(false);
        setIsExport(false);
        console.log(err);
      });
  };

  useEffect(() => {
    if (pageNumber && Object.keys(selectedGBPFilter).length !== 0&&selectedRow?.length===0) {
      getHighOutlierTableDataHandler();
    }
  }, [pageNumber, selectedGBPFilter]);

  useEffect(() => {
    if (isExport) {
      getHighOutlierTableDataHandler();
    }
  }, [isExport]);

  useEffect(() => {
    if (exportAllData?.length !== 0) {
      exportFullRef?.current?.link.click();
    }
  }, [exportAllData])

  const handleRowSelection = (data: any) => {
    let index = selectedRow.indexOf(data?.SKUl);
if (index !== -1) {
      // selectedRow.splice(index, 1);
      setSelectedRow([]);
      getHighOutlierCustomerDataHandler([])
      setSelectedRowData([]);
    } else {
      setSelectedRow([data?.SKUl]);
      getHighOutlierCustomerDataHandler(data?.SKUl)
      setSelectedRowData([ data]);
    }
  };

  useEffect(() => {
    if (didMount.current) {
      if (coundtHandler >= 1) {
        props?.onRowChange(selectedRowData);
      } else {
        setCountHandler(coundtHandler + 1);
      }
    } else {
      didMount.current = true;
      setCountHandler(coundtHandler + 1);
    }
  }, [selectedRowData]);
  const prepareExportData=(data:any)=>{
    if(data){
      data?.forEach((element:any) => {
        element.povalue$M_1='$'+formatNumber(element?.povalue$M_1)

        element.effective_ss=formatNumber(element?.effective_ss)
        element.onhand=formatNumber(element?.onhand)
        element.open_eros=formatNumber(element?.open_eros)
        element.Total_Dmd_Within_LT_Custom=formatNumber(element?.Total_Dmd_Within_LT_Custom)
        element.InvAtEndOfLT=formatNumber(element?.InvAtEndOfLT)
        element.poqty=formatNumber(element?.poqty)
      });
    }
    setExportALLData(data)
  }

  return (
    <>
    <div className=" flex justify-between mt-[-1px]">
        <p className={`text-white font-medium text-0.95vw text-left `}>
          Items With High Outlier Correction -SKUL View
        </p>
        <Pagination
            ellipsis
            boundaryLinks
            maxButtons={5}
            last
            next
            first
            size="xs"
            total={count}
            limit={100}
            layout={["pager"]}
            activePage={pageNumber}
            onChangePage={(page: any) => {
              setPageNumber(page);
            }}
          />
      </div>
      <Table
        style={{height:windowSize.width/6.5}}
        data={tableData ? tableData : []}
        bordered={true}
        cellBordered={true}
        headerHeight={55}
        affixHeader={10}
        className="w-[100%]  border-mainGray"
        fillHeight
        hover={false}
        loading={loading}
      >
        {LONGTERM_TABLE?.map((value) => {
          return (
            <Column key={value?.key} fullText flexGrow={1}>
              <HeaderCell
                style={{
                  backgroundColor: "black",
                  color: "white",
                }}
                className="text-0.7vw  border-[#333231]"
              >
                <p className="text-wrap">{value?.label}</p>
              </HeaderCell>
              <DataCell
                dataKey={value?.key}
                selectedRow={selectedRow}
                handleRowSelection={(data: any) => {
                  handleRowSelection(data);
                }}
              />
            </Column>
          );
        })}
      </Table>

    </>
  );
};

const DataCell = ({ rowData, dataKey, ...props }: any) => {

  const changeKey=['PLANT_PLANT','MATERIAL_MATERIAL']
  const percentage=['OverAllReduction','ReductionByPA','ReductionByGraybar']

  const handleRowSelection = (data: any) => {
    props?.handleRowSelection({
      SKUl: data?.SKUl,
      plant: data?.PLANT_PLANT,
      material: data?.MATERIAL_MATERIAL,
    });
  };
  return (
    <Cell
      {...props}
      className={` text-white cursor-pointer `}
      style={{backgroundColor:props?.selectedRow[0]===rowData?.SKUl?'#56544e':'#1a1916'}}
      onClick={() => {
        handleRowSelection(rowData);
      }}
    >
      {!changeKey?.includes(dataKey) && !percentage?.includes(dataKey)&& (
        <p className="truncate  text-0.7vw">
          {rowData[dataKey] !== null ? formatNumber(rowData[dataKey]) : ""}
        </p>
      )}
      {percentage?.includes(dataKey)&& (
        <p className="truncate  text-0.7vw">
          {rowData[dataKey] !== null ? ((rowData[dataKey])*100).toFixed(2)+'%' : ""}
        </p>
      )}
{changeKey?.includes(dataKey) && (
        <p className="truncate  text-0.7vw">
          {rowData[dataKey] !== null ? rowData[dataKey] : ""}
        </p>
      )}

    </Cell>
  );
};

const message = (
  <Notification type={"info"} header={`Exporting...`} closable>
    <p>Your file is being created. It will be download automatically</p>
  </Notification>
);
const mapDispatchToProps = (dispatch: any) => ({
  onRowChange: (data: any) => dispatch({ type: TABLEFILTER, data: data }),
});

export default connect(null, mapDispatchToProps)(ForecastLongTermTable);

