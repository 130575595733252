import React, { useEffect, useState } from "react";
import { Loader } from "rsuite";
import InactiveInventoryBarGraph from "../../../components/inventory/inactiveInventoryBarGraph";
import InactiveInventoryTable from "../../../components/inventory/inactiveInventoryTable";
import InactiveInventoryWaterfall from "../../../components/inventory/inactiveInventoryWaterFallGraph";
import InactiveInventoryWaterfall1 from "../../../components/inventory/inactiveInventoryWaterFallGraph1";
import { useLocation } from "react-router-dom";
import {
  getInactiveInventoryChartData,
  getInactiveInventoryData,
  getInactiveInventoryPlantChartData,
  getInactiveInventoryPlantLevelData,
  getInactiveInventoryWaterfallData,
} from "../../../queries/apiQueries"; 
import InactiveInventoryZDBarGraph from "../../../components/inventory/inactiveInventoryZDBarGraph";
import InactiveInventoryTimeframeBarGraph from "../../../components/inventory/inactiveInventoryTimeframeBarGraph";
import { useSelector } from "react-redux";
import PinactiveRebalanceGraph from "../../../components/inventory/pinactiveRebalanceGraph";

const InactiveInventoryPage = (props: any) => {
  const location = useLocation();
  const type = new URLSearchParams(location.search).get("type");
  const [loading, setLoading] = useState(false);
  const [hasMounted, setHasMounted] = useState(false);
  const [isPlantChartDataHandlerCalled, setIsPlantChartDataHandlerCalled] = useState(false);
  const [plantLvlDataForOnhand , setPlantLvlDataForOnhand] = useState<any>(null)
  const [plantLvlDataForVendor , setPlantLvlDataForVendor] = useState<any>(null)
  const [plantLvlDataForCluster , setPlantLvlDataForCluster] = useState<any>(null)
  const [plantLvlDataForRebalance , setPlantLvlDataForRebalance] = useState<any>(null)
  const [plantLvlDataForPiRebalance , setPlantLvlDataForPiRebalance] = useState<any>(null)
  const [plantLvlDataForx1x2 , setPlantLvlDataForx1x2] = useState<any>(null)
  const [RebalanceData, setRebalanceData] = useState<any>(null)
  const [RebalanceDataForPi, setRebalanceDataForPi] = useState<any>(null)
  const [RebalanceData1, setRebalanceData1] = useState<any>(null)
  const [CurrentAlert, setCurrentAlert] = useState<any>(null)
  const [barGraphData, setBarGraphData] = useState<any>(null);
  const [barGraphDataFromBreakDownClick, setBarGraphDataFromBreakDownClick] = useState<any>(null);
  const [barGraphData1, setBarGraphData1] = useState<any>(null);
  const [barGraphDataZD, setBarGraphDataZD] = useState<any>(null);
  const [barGraphDataZDSKUl, setBarGraphDataZDSKUl] = useState(null);
  const [barGraphDataAge, setBarGraphDataAge] = useState(null);
  const [barGraphDataAge1, setBarGraphDataAge1] = useState(null);
  const [inactiveTaggedData, setInactiveTaggedData] = useState(null);
  const [inactiveTimeframeData, setInactiveTimeframeData] = useState(null);
  const [selectedCluster, setSelectedCluster] = useState("");
  const [selectedRowData, setSelectedRowData] = useState<any>([]);
  const selectedGBPFilter = useSelector(
    (state: any) => state.CommonReducer.selectedGBPFilter
  );

  const getBarGraphDataHandler = () => {
    let payload = {
      ...selectedGBPFilter,
      alertType: Number(type),
    };
    if(selectedCluster!==''){
      payload={...payload,clustername:[selectedCluster]}
    }
    getInactiveInventoryChartData(payload)
      .then((response: any) => {
        if (response?.status === 200) {
          prepareBarGraphData(response?.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getInactiveInventoryWaterfallDataHandler = () => {
    let payload = {
      ...selectedGBPFilter,
      alertType: Number(type),
    };
    if(selectedCluster!==''){
      payload={...payload,clustername:[selectedCluster]}
    }
  
    console.log(payload);
    getInactiveInventoryWaterfallData(payload)
      .then((response: any) => {
        if (response?.status === 200) {
            prepareWaterfallData(response?.data);
            setRebalanceData(response?.data?.dohData)
            setRebalanceDataForPi(response?.data?.dohData)
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };



  const preparevendordataforplantLvl = (data:any) =>{
    
    let obj2:any=new Object()
      data?.forEach((value: any) => {
        if (obj2.hasOwnProperty(value?.Vendor_Code)) {
          console.log(value?.InventoryAgeBuckets, 2);
          if (value?.InventoryAgeBuckets === ">12Months") {
            obj2[value?.Vendor_Code].M8 += value?.onhand;
          } else {
            obj2[value?.Vendor_Code].M812 += value?.onhand;
          }
          obj2[value?.Vendor_Code].sum += value?.onhand;
        } else {
          console.log(value?.InventoryAgeBuckets, 1);
          obj2[value?.Vendor_Code] = {
            cluster: value?.Vendor_Code,
            M8: value?.InventoryAgeBuckets === ">12Months" ? value?.onhand : 0,
            M812: value?.InventoryAgeBuckets === "8-12Months" ? value?.onhand : 0,
            M12: value?.InventoryAgeBuckets === "<8Months" ? value?.onhand : 0,
            sum: value?.onhand,
          };
        }
      });
      let finalData2: any = [];
      
      for (let key in obj2) {
        finalData2.push(obj2[key]);
      }
      finalData2.sort((a: any, b: any) => b?.sum - a?.sum);
      setPlantLvlDataForVendor(finalData2)
  }

  const getInactiveInvRebalanceFromTable = (data:any , alerttype:any = 3)=>{
    console.log(data);
    getInactiveInventoryPlantLevelData({
      material : data?.material,
      cluster: data?.cluster,
      plant : data?.plant,
      alertType: type === '0' ? 0 : 1,
    }).then((response:any)=>{
      if (response?.status === 200) {
        console.log(response?.data?.data);
        setPlantLvlDataForRebalance(response?.data?.rebalanceData)
        prepareBarGraphData(response?.data?.onhandData , 2)
        prepareBarGraphData(response?.data?.clusterData , 3)
        preparevendordataforplantLvl(response?.data?.vendorData)
        if(type == '0'){
          prepareBarGraphData(response?.data?.x1x2Data , 1)
          setPlantLvlDataForPiRebalance(response?.data?.rebalanceData)
        }
        // if(type == '1'){
        //   setRebalanceData(response?.data?.data)
        // }else if(type == '0'){
        //   setRebalanceDataForPi(response?.data?.data)
        // }
      }
    })
  }

  const getInactiveInvRebalance = (data:any, Atype:any =3) =>{
    getInactiveInventoryPlantLevelData({
      cluster: data?.cluster,
      alertType: Number(type) === 3  ?  0 : Number(type),
    }).then((response :any)=>{
      if (response?.status === 200) {
        setRebalanceData1(response?.data?.rebalanceData)
        setPlantLvlDataForPiRebalance(response?.data?.rebalanceData)
        if(type == '0'){
          prepareBarGraphData(response?.data?.x1x2Data, 1)
        }
        // setPlantLvlDataForx1x2(response?.data?.x1x2Data)
      }
    })
  }

  const getInactiveInvDataForRebalanceClick = (data: any , Alerttype :any) =>{
    setSelectedCluster(data?.cluster);
    console.log(data);
    setIsPlantChartDataHandlerCalled(true);
    setCurrentAlert(Alerttype)
    getInactiveInvRebalance(data , Alerttype)
    
  }

  const getInactiveInventoryPlantChartDataHandler = (data: any , Alerttype :any) => {
    setSelectedCluster(data?.cluster);
    setCurrentAlert(Alerttype)
    console.log(data) ;
    
    // getInactiveInvRebalance(data)
    getInactiveInventoryPlantLevelData({
      cluster: data?.cluster,
      alertType: type === '0' ? 0 : 1,
    })
      .then((response: any) => {
        if (response?.status === 200) {
          console.log(response?.data);
          
          let finalData
          if(type==='2'){
            let d1=response?.data?.data?.filter(
              (value: any) => value?.plant !== data?.plant
            );
            let d2=response?.data?.vendor?.filter(
              (value: any) => value?.plant !== data?.plant
            );
            finalData={data:d1,vendor:d2}
          }
          else{
            if(Alerttype == 2){
              finalData = response?.data?.onhandData
            }
          }
          console.log(finalData);
          setPlantLvlDataForRebalance(response?.data?.rebalanceData)
          prepareBarGraphData(response?.data?.onhandData, 2 );
          prepareBarGraphData(response?.data?.clusterData, 3 );
          preparevendordataforplantLvl(response?.data?.vendorData)
          if(type == '0'){
            prepareBarGraphData(response?.data?.x1x2Data, 1);
            setPlantLvlDataForPiRebalance(response?.data?.rebalanceData);
          }
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (Object.keys(selectedGBPFilter).length !== 0) {
      // setLoading(true)
      // if (!hasMounted) {
      //   getInactiveInventoryWaterfallDataHandler();
      //   setHasMounted(true); 
      // }
      console.log(CurrentAlert);
      if (type === "0" || "1" || "2") {
        getBarGraphDataHandler();
        getInactiveInventoryWaterfallDataHandler()

        
        if (isPlantChartDataHandlerCalled) {
          getInactiveInventoryWaterfallDataHandler(); 
        }
      } else if (type === "1" || type === "2" ) {
        if (isPlantChartDataHandlerCalled) {
          getInactiveInventoryWaterfallDataHandler(); 
        }
      }
    
    }
  }, [type, selectedGBPFilter,selectedCluster ,isPlantChartDataHandlerCalled]);


  useEffect(()=>{
    
    console.log("entered" ,selectedRowData);
    if((type==='1' || type == '0' || type == '2' )&&selectedRowData?.length!==0){
      const data = {
        material: selectedRowData[0]?.material, 
        cluster: selectedRowData[0]?.cluster, 
        plant : selectedRowData[0]?.plant
    }
    getInactiveInvRebalanceFromTable(data)
    }
    else{
    setPlantLvlDataForOnhand(null)
      setPlantLvlDataForCluster(null)
      setPlantLvlDataForRebalance(null)
      setPlantLvlDataForPiRebalance(null)
      setPlantLvlDataForx1x2(null) 
      setPlantLvlDataForVendor(null)
    }
  },[selectedRowData])

  useEffect(() => {
    return () => {
      setIsPlantChartDataHandlerCalled(false);
    };
  }, [plantLvlDataForOnhand]);


  const prepareWaterfallData = (data: any) => {
    const { taggedData,  taggedData1, timeframeData1  } = data;
    console.log(data);
    
    let obj1: any = new Object();
    timeframeData1?.forEach((value: any) => {
      if (obj1.hasOwnProperty(value?.plant_code)) {
        console.log(value?.InventoryAgeBuckets, 2);
        if (value?.InventoryAgeBuckets === ">12Months") {
          obj1[value?.plant_code].M8 += value?.onhand;
        } else {
          obj1[value?.plant_code].M812 += value?.onhand;
        }
        obj1[value?.plant_code].sum += value?.onhand;
      } else {
        console.log(value?.InventoryAgeBuckets, 1);
        obj1[value?.plant_code] = {
          cluster: value?.plant_code,
          M8: value?.InventoryAgeBuckets === ">12Months" ? value?.onhand : 0,
          M812: value?.InventoryAgeBuckets === "8-12Months" ? value?.onhand : 0,
          M12: value?.InventoryAgeBuckets === "<8Months" ? value?.onhand : 0,
          sum: value?.onhand,
        };
      }
    });

    let finalData3: any = [];
    for (let key in obj1) {
      finalData3.push(obj1[key]);
    }
    finalData3.sort((a: any, b: any) => b?.sum - a?.sum);

    setBarGraphDataAge(finalData3);

    let obj: any = new Object();
    taggedData1?.forEach((value: any) => {
      if (obj.hasOwnProperty(value?.plant_code)) {
        if (value?.mrp_type == 'ZD') {
          obj[value?.plant_code].ZD += value?.onhand;
        } else {
          obj[value?.plant_code].notZD += value?.onhand;
        }
        obj[value?.plant_code].sum += value?.onhand;
      } else {
        obj[value?.plant_code] = {
          cluster: value?.plant_code,
          ZD: value?.mrp_type == 'ZD' ? value?.onhand : 0,
          notZD: value?.mrp_type != 'ZD' ? value?.onhand : 0,
    
          sum: value?.onhand,
        };
      }
    });

    let finalData: any = [];
    for (let key in obj) {
      finalData.push(obj[key]);
    }
    finalData.sort((a: any, b: any) => b?.sum - a?.sum);
    // setBarGraphDataZD(finalData);

    let obj2: any = new Object();
    taggedData1?.forEach((value: any) => {
      if (obj2.hasOwnProperty(value?.plant_code)) {
        if (value?.mrp_type == 'ZD') {
          obj2[value?.plant_code].ZD += value?.SKUl;
        } else {
          obj2[value?.plant_code].notZD += value?.SKUl;
        }
        obj2[value?.plant_code].sum += value?.SKUl;
      } else {
        obj2[value?.plant_code] = {
          cluster: value?.plant_code,
          ZD: value?.mrp_type == 'ZD' ? value?.SKUl : 0,
          notZD: value?.mrp_type != 'ZD' ? value?.SKUl : 0,
          sum: value?.SKUl,
        };
      }
    });

    let finalData4: any = [];
    for (let key in obj2) {
      finalData4.push(obj2[key]);
    }
    finalData4.sort((a: any, b: any) => b?.sum - a?.sum);
    // setBarGraphDataZDSKUl(finalData4);
    setBarGraphDataFromBreakDownClick({cluster:finalData,skul:finalData4})
    setBarGraphDataZD({cluster:finalData,skul:finalData4});
    const updatedBarGraphDataZD = {
      cluster: finalData.map((cluster:any) => {
        const skul = finalData4.find((skul:any) => skul.cluster === cluster.cluster);
        return {
          ...cluster,
          OtherMRPSkulCount: skul.notZD,
          ZDSkulCount: skul.ZD,
        };
      }),
      skul: finalData4,
    };
    setBarGraphDataFromBreakDownClick(updatedBarGraphDataZD)
    setBarGraphDataZD(updatedBarGraphDataZD);
    
  
    // let finalData1: any = [
    //   {
    //     name: "Total Inactive Inv",
    //     uv: taggedData[0]?.onhand,
    //     pv: 0,
    //   },
    //   {
    //     name: "Unaddressable Inactive",
    //     uv: taggedData[0]?.unaddressable,
    //     pv: taggedData[0]?.onhand,
    //   },
    //   {
    //     name: "Addressable Inactive",
    //     uv: taggedData[0]?.addressable,
    //     pv: 0,
    //   },
    // ];
    // setInactiveTaggedData(finalData1);

    let obj4: any = new Object();
    taggedData?.forEach((value: any) => {
      if (obj4.hasOwnProperty(value?.Vendor_Code)) {
        console.log(value?.InventoryAgeBuckets, 2);
        if (value?.InventoryAgeBuckets === ">12Months") {
          obj4[value?.Vendor_Code].M8 += value?.onhand;
        } else {
          obj4[value?.Vendor_Code].M812 += value?.onhand;
        }
        obj4[value?.Vendor_Code].sum += value?.onhand;
      } else {
        console.log(value?.InventoryAgeBuckets, 1);
        obj4[value?.Vendor_Code] = {
          cluster: value?.Vendor_Code,
          M8: value?.InventoryAgeBuckets === ">12Months" ? value?.onhand : 0,
          M812: value?.InventoryAgeBuckets === "8-12Months" ? value?.onhand : 0,
          M12: value?.InventoryAgeBuckets === "<8Months" ? value?.onhand : 0,
          sum: value?.onhand,
        };
      }
    });

    let finalData5: any = [];
    for (let key in obj4) {
      finalData5.push(obj4[key]);
    }
    finalData5.sort((a: any, b: any) => b?.sum - a?.sum);

    setBarGraphDataAge1(finalData5);


  };


  const prepareBarGraphData = (data: any, key: number = 0) => {
    console.log(key,data);
    
    let obj: any = new Object();
    if (key === 3) {
      console.log(data,key,11)
      data?.forEach((value: any) => {
        if (obj.hasOwnProperty(value?.plant)) {
          console.log(value?.InventoryAgeBuckets, 2);
          if (value?.InventoryAgeBuckets === ">12Months") {
            obj[value?.plant].M8 += value?.onhand;
          } else {
            obj[value?.plant].M812 += value?.onhand;
          }
          obj[value?.plant].sum += value?.onhand;
        } else {
          console.log(value?.InventoryAgeBuckets, 1);
          obj[value?.plant] = {
            cluster: value?.plant,
            M8: value?.InventoryAgeBuckets === ">12Months" ? value?.onhand : 0,
            M812: value?.InventoryAgeBuckets === "8-12Months" ? value?.onhand : 0,
            M12: value?.InventoryAgeBuckets === "<8Months" ? value?.onhand : 0,
            sum: value?.onhand,
          };
        }
      });
      let obj2:any=new Object()
      data?.vendor?.forEach((value: any) => {
        if (obj2.hasOwnProperty(value?.plant)) {
          console.log(value?.InventoryAgeBuckets, 2);
          if (value?.InventoryAgeBuckets === ">12Months") {
            obj2[value?.plant].M8 += value?.onhand;
          } else {
            obj2[value?.plant].M812 += value?.onhand;
          }
          obj2[value?.plant].sum += value?.onhand;
        } else {
          console.log(value?.InventoryAgeBuckets, 1);
          obj2[value?.plant] = {
            cluster: value?.plant,
            M8: value?.InventoryAgeBuckets === ">12Months" ? value?.onhand : 0,
            M812: value?.InventoryAgeBuckets === "8-12Months" ? value?.onhand : 0,
            M12: value?.InventoryAgeBuckets === "<8Months" ? value?.onhand : 0,
            sum: value?.onhand,
          };
        }
      });
  

      let finalData: any = [];
      console.log(obj , finalData);
      
      for (let key in obj) {
        finalData.push(obj[key]);
      }
      finalData.sort((a: any, b: any) => b?.sum - a?.sum);
      let finalData2: any = [];
      
      for (let key in obj2) {
        finalData2.push(obj2[key]);
      }
      finalData2.sort((a: any, b: any) => b?.sum - a?.sum);
      console.log(barGraphData1 , finalData);
      setPlantLvlDataForVendor(finalData2)
      setPlantLvlDataForCluster({cluster:finalData,vendor:finalData2})
      setBarGraphData1({cluster:finalData,vendor:finalData2});
      console.log(barGraphData1)
      
    }else if (key === 2) {
      console.log("======entered=======" , data )
      
      data?.forEach((value: any) => {
        console.log(obj.hasOwnProperty(value?.plant));
        
        if (obj.hasOwnProperty(value?.plant)) {
          if (value?.mrp_type == 'ZD') {
            obj[value?.plant].ZD += value?.onhand;
          } else {
            obj[value?.plant].notZD += value?.onhand;
          }
          obj[value?.plant].sum += value?.onhand;
        } else {
          obj[value?.plant] = {
            cluster: value?.plant,
            ZD: value?.mrp_type == 'ZD' ? value?.onhand : 0,
            notZD: value?.mrp_type != 'ZD'? value?.onhand : 0,
            sum: value?.onhand,
          };
        }
      });
      let obj2:any=new Object()
      data?.forEach((value: any) => {
        if (obj2.hasOwnProperty(value?.plant)) {
          if (value?.mrp_type == 'ZD') {
            obj2[value?.plant].ZD += value?.SKUl;
          } else {
            obj2[value?.plant].notZD += value?.SKUl;
          }
          obj2[value?.plant].sum += value?.SKUl;
        } else {
          obj2[value?.plant] = {
            cluster: value?.plant,
            ZD: value?.mrp_type == 'ZD' ? value?.SKUl : 0,
            notZD: value?.mrp_type != 'ZD' ? value?.SKUl : 0,
            sum: value?.SKUl,
          };
        }
      });

      let finalData: any = [];

      for (let key in obj) {
        finalData.push(obj[key]);
      }
      finalData.sort((a: any, b: any) => b?.sum - a?.sum);
      let finalData2: any = [];
      for (let key in obj2) {
        finalData2.push(obj2[key]);
      }
      finalData2.sort((a: any, b: any) => b?.sum - a?.sum);
      console.log({cluster:finalData,skul:finalData2});
      setBarGraphDataFromBreakDownClick({cluster:finalData,skul:finalData2})
      setBarGraphData1({cluster:finalData,skul:finalData2});
      const updatedBarGraphDataZD = {
        cluster: finalData.map((cluster:any) => {
          const skul = finalData2.find((skul:any) => skul.cluster === cluster.cluster);
          return {
            ...cluster,
            OtherMRPSkulCount: skul.notZD,
            ZDSkulCount: skul.ZD,
          };
        }),
        skul: finalData2,
      };
      setPlantLvlDataForOnhand(updatedBarGraphDataZD)
      setBarGraphDataFromBreakDownClick(updatedBarGraphDataZD)
      setBarGraphData1(updatedBarGraphDataZD);
    } else if (key === 1) {
      data?.forEach((value: any) => {
        if (obj.hasOwnProperty(value?.plant)) {
          if (value?.segment === "X1. No Demand In Last 6M") {
            obj[value?.plant].x1 = value?.onhand;
          } else if (value?.segment === "X2. No Demand In Last 7M") {
            obj[value?.plant].x2 = value?.onhand;
          }
          obj[value?.plant].sum += value?.onhand;
        } else {
          obj[value?.plant] = {
            cluster: value?.plant,
            x1:
              value?.segment === "X1. No Demand In Last 6M" ? value?.onhand : 0,
            x2:
              value?.segment === "X2. No Demand In Last 7M" ? value?.onhand : 0,
            sum: value?.onhand,
          };
        }
      });

      let finalData: any = [];

      for (let key in obj) {
        finalData.push(obj[key]);
      }
      finalData.sort((a: any, b: any) => b?.sum - a?.sum);
      setBarGraphData1(finalData);
      setPlantLvlDataForx1x2(finalData)
    } else {
      data?.data?.forEach((value: any) => {
        if (obj.hasOwnProperty(value?.cluster)) {
          if (value?.segment === "X1. No Demand In Last 6M") {
            obj[value?.cluster].x1 = value?.onhand;
          } else if (value?.segment === "X2. No Demand In Last 7M") {
            obj[value?.cluster].x2 = value?.onhand;
          }
          obj[value?.cluster].sum += value?.onhand;
        } else {
          obj[value?.cluster] = {
            cluster: value?.cluster,
            x1:
              value?.segment === "X1. No Demand In Last 6M" ? value?.onhand : 0,
            x2:
              value?.segment === "X2. No Demand In Last 7M" ? value?.onhand : 0,
            sum: value?.onhand,
          };
        }
      });

      let obj1 = [
        {
          label: "X1toX3",
          value: data?.movement[0]?.X1toX3,
        },
        {
          label: "X1toX2",
          value: data?.movement[0]?.X1toX2,
        },
        {
          label: "X2toX3",
          value: data?.movement[0]?.X2toX3,
        },
      ];

      let finalData: any = [];

      for (let key in obj) {
        finalData.push(obj[key]);
      }
      finalData.sort((a: any, b: any) => b?.sum - a?.sum);
      setBarGraphData({ stacked: finalData, movement: obj1 });
    }
  };

  return (
    <>
      <p className="text-mainGreen text-1.3vw text-center ">
        {type === "0"
          ? `Potential Inactive
(No demand in last 6M)`
          : type === "1"
          ? `Untagged Inactive`
          : `Ageing Inactive
`}
        ​
      </p>
      <div className=" mt-2">
        {loading && (
          <Loader backdrop size="md" inverse center content="loading..." />
        )}

        <div className="w-full flex justify-center">
          <div className="w-[95%]">
            <div className={`grid  grid-cols-2 gap-6`}>
              {type === "0" && (
                <InactiveInventoryBarGraph
                  barGraphData={barGraphData}
                  selectedRowData= {selectedRowData}
                  barGraphData1={barGraphData1}
                  plantLvlDataForx1x2={plantLvlDataForx1x2}
                  selectedGBPFilter={selectedGBPFilter}
                  onbarClick={getInactiveInventoryPlantChartDataHandler}
                  goBack={() => {
                    setBarGraphData1(null);
                    setPlantLvlDataForx1x2(null)
                    setPlantLvlDataForPiRebalance(null)
                    setSelectedCluster("");
                  }}
                  selectedCluster={selectedCluster}
                />
              )}
              {type === "0" && (
                <PinactiveRebalanceGraph 
                RebalanceData = {RebalanceDataForPi}
                plantLvlDataForPiRebalance={plantLvlDataForPiRebalance}
                RebalanceData1={RebalanceData1}
                CurrentAlert={CurrentAlert}
                selectedRowData={selectedRowData}
                selectedGBPFilter={selectedGBPFilter}
                selectedCluster={selectedCluster}
                onbarClick={getInactiveInventoryPlantChartDataHandler}
                onbarClickRebalance={getInactiveInvDataForRebalanceClick}
                goBack={() => {
                  setBarGraphData1(null);
                  setSelectedCluster("");
                  setCurrentAlert(0)
                  setPlantLvlDataForx1x2(null)
                  setPlantLvlDataForPiRebalance(null)
                  setRebalanceData1(null)
                }}
                 />
              )}
              {type === "1" && (
                // <InactiveInventoryWaterfall1
                //   inactiveTaggedData={inactiveTaggedData}
                // />
                <InactiveInventoryZDBarGraph
                showRebalance = {false}
                  onbarClick={getInactiveInventoryPlantChartDataHandler}
                  onbarClickRebalance={getInactiveInvDataForRebalanceClick}
                  RebalanceData1={RebalanceData1}
                  plantLvlDataForRebalance = {plantLvlDataForRebalance}
                  goBack={() => {
                    setBarGraphData1(null);
                    setSelectedCluster("");
                    setPlantLvlDataForVendor(null)
                    setBarGraphDataFromBreakDownClick(null);
                    setCurrentAlert(0)
                    setPlantLvlDataForOnhand(null);
                    setPlantLvlDataForRebalance(null);
                    setPlantLvlDataForCluster(null)
                  }}
                  RebalanceData = {RebalanceData}
                  plantLvlDataForOnhand = {plantLvlDataForOnhand}
                  barGraphData1={barGraphData1}
                  selectedRowData= {selectedRowData}
                  selectedGBPFilter={selectedGBPFilter}
                  CurrentAlert={CurrentAlert}
                  selectedCluster={selectedCluster}
                  barGraphDataFromBreakDownClick={barGraphDataFromBreakDownClick}
                  barGraphData={barGraphDataZD}
                  barGraphDataZDSKUl={barGraphDataZDSKUl}
                />
              )}
              {type === "2" && (
                // <InactiveInventoryWaterfall1
                //   inactiveTaggedData={inactiveTaggedData}
                // />
                <InactiveInventoryTimeframeBarGraph
                  barGraphData={{
                    cluster: barGraphDataAge,
                    vendor: barGraphDataAge1,
                  }}
                  onbarClick={getInactiveInventoryPlantChartDataHandler}
                  goBack={() => {
                    setBarGraphData1(null);
                    setSelectedCluster("");
                    setPlantLvlDataForRebalance(null)
                  }}
                  selectedGBPFilter={selectedGBPFilter}
                  barGraphData1={barGraphData1}
                  selectedRowData= {selectedRowData}
                  plantLvlDataForVendor={plantLvlDataForVendor}
                  selectedCluster={selectedCluster}
                />
              )}

              {(type === "1" ) && (
            
                <InactiveInventoryZDBarGraph
                showRebalance = {true}
                onbarClick={getInactiveInventoryPlantChartDataHandler}
                onbarClickRebalance={getInactiveInvDataForRebalanceClick}
                RebalanceData1={RebalanceData1}
                plantLvlDataForRebalance = {plantLvlDataForRebalance}
                goBack={() => {
                  setBarGraphData1(null);
                  setSelectedCluster("");
                  setPlantLvlDataForVendor(null)
                  setBarGraphDataFromBreakDownClick(null);
                  setCurrentAlert(0)
                  setPlantLvlDataForOnhand(null);
                  setPlantLvlDataForRebalance(null);
                  setPlantLvlDataForCluster(null)
                }}
                RebalanceData = {RebalanceData}
                plantLvlDataForOnhand = {plantLvlDataForOnhand}
                barGraphData1={barGraphData1}
                selectedRowData= {selectedRowData}
                CurrentAlert={CurrentAlert}
                selectedGBPFilter={selectedGBPFilter}
                selectedCluster={selectedCluster}
                barGraphDataFromBreakDownClick={barGraphDataFromBreakDownClick}
                barGraphData={barGraphDataZD}
                barGraphDataZDSKUl={barGraphDataZDSKUl}
              />
           
              //   <InactiveInventoryTimeframeBarGraph
              //   barGraphData={{
              //     cluster: barGraphDataAge,
              //     vendor: barGraphDataAge1,
              //   }}
              //   onbarClick={getInactiveInventoryPlantChartDataHandler}
              //   goBack={() => {
              //     setBarGraphData1(null);
              //     setSelectedCluster("");
              //     setBarGraphDataFromBreakDownClick(null);
              //     setCurrentAlert(0)
              //     setPlantLvlDataForOnhand(null);
              //     setPlantLvlDataForCluster(null)
              //     setPlantLvlDataForVendor(null)
              //     setPlantLvlDataForRebalance(null)
              //   }}
              //   barGraphData1={barGraphData1}
              //   selectedRowData= {selectedRowData}
              //   plantLvlDataForVendor={plantLvlDataForVendor}
              //   plantLvlDataForCluster={plantLvlDataForCluster}
              //   selectedCluster={selectedCluster}
              // />
              )}
                {(type === "2") && (
                //    <InactiveInventoryWaterfall
                //    inactiveTimeframeData={inactiveTimeframeData}
                //  />
                <InactiveInventoryZDBarGraph
                showRebalance = {true}
                onbarClick={getInactiveInventoryPlantChartDataHandler}
                onbarClickRebalance={getInactiveInvDataForRebalanceClick}
                RebalanceData1={RebalanceData1}
                selectedGBPFilter={selectedGBPFilter}
                plantLvlDataForRebalance = {plantLvlDataForRebalance}
                goBack={() => {
                  setBarGraphData1(null);
                  setSelectedCluster("");
                  setPlantLvlDataForVendor(null)
                  setBarGraphDataFromBreakDownClick(null);
                  setCurrentAlert(0)
                  setPlantLvlDataForRebalance(null)
                  setPlantLvlDataForOnhand(null);
                  setPlantLvlDataForRebalance(null);
                  setPlantLvlDataForCluster(null)
                }}
                RebalanceData = {RebalanceData}
                plantLvlDataForOnhand = {plantLvlDataForOnhand}
                barGraphData1={barGraphData1}
                selectedRowData= {selectedRowData}
                CurrentAlert={CurrentAlert}
                selectedCluster={selectedCluster}
                barGraphDataFromBreakDownClick={barGraphDataFromBreakDownClick}
                barGraphData={barGraphDataZD}
                barGraphDataZDSKUl={barGraphDataZDSKUl}
              />
                     )}
            </div>
            <div className="w-full flex justify-center items-center">
              <div className="w-full mt-5">
                <InactiveInventoryTable selectedRowData={(data:any)=>setSelectedRowData(data)} selectedCluster={selectedCluster} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InactiveInventoryPage;
