import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Button, ButtonGroup, ButtonToolbar, Pagination, Table } from "rsuite";

import { useLocation } from "react-router-dom";
import { CSVLink } from "react-csv";
import { formatNumber } from "../../utils/commonFunctions";

const tableHeaders = [
  { key: "District_Name", label: "District", width: 90 },
  { key: "Plant_Code", label: "Cluster", width: 100 },
  { key: "Plant", label: "Plant", width: 100 },
  { key: "Material", label: "Material", width: 100 },
  {
    key: "PlantSpecificMaterialStatus",
    label: "Plant Specific Material Status",
    width: 100,
  },

  { key: "PFXPG", label: "Supplier Prefix Purchase Group", width: 100 },
  { key: "Catalog_Number", label: "Catalog Number", width: 150 },
  { key: "Material_Description", label: "Material Description", width: 150 },
  { key: "OnHand", label: "Quantity", width: 100 },
  { key: "Cost_FOB", label: "Std. Price Per Unit ($)", width: 150 },
  { key: "Onhand$Value", label: "OnHand ($)", width: 90 },
  { key: "SKUl_Check", label: "On Last Report", width: 100 },
  { key: "Previous_Month_Status", label: "Previous Month Status", width: 130 },
  {
    key: "Previous_Resolution_Activities",
    label: "Previous Resolution Activities",
    width: 150,
  },
  { key: "Current_Month_Status", label: "Current Month Status", width: 150 },
  {
    key: "Current_Month_Resolution_Activities",
    label: "Current Month Resolution Activities",
    width: 150,
  },
  { key: "isReviewed", label: "Review Status", width: 100 },
];

const tableHeader1 = [
  { key: "Material", label: "Material", width: 100 },
  { key: "Plant", label: "Plant", width: 100 },
  { key: "District_Name", label: "District", width: 100 },
  { key: "Plant_Code", label: "Cluster", width: 100 },
  // { key: "District_w_D65", label: "District w/ D65", width: 150 },
  { key: "PFXPG", label: "PFX-PG", width: 100 },
  { key: "MRP_Group", label: "MRP Group", width: 100 },
  { key: "MRP_Type", label: "MRP Type", width: 100 },
  { key: "Velocity_Code", label: "Velocity Code", width: 100 },
  {
    key: "National_Movement_Code",
    label: "National Movement Code",
    width: 150,
  },
  { key: "Catalog_Number", label: "Catalog Number", width: 150 },
  { key: "OnHand", label: "On Hand (Qty)", width: 100 },
  { key: "Rounding_Value", label: "Rounding Value", width: 100 },
  { key: "LastPODate", label: "Last PO Date", width: 100 },
  { key: "LastPONumber", label: "Last PO Number", width: 100 },
  {
    key: "ConstrainedOverstockQty",
    label: "Constrained Overstock Qty",
    width: 150,
  },
  {
    key: "ConstrainedOverstockQty$",
    label: "Constrained Overstock Qty $",
    width: 150,
  },
  { key: "SKUl_Check", label: "On Last Report", width: 100 },
  { key: "Previous_Month_Status", label: "Previous Month Status", width: 150 },
  {
    key: "Previous_Resolution_Activities",
    label: "Previous Resolution Activities",
    width: 150,
  },
  { key: "Current_Month_Status", label: "Current Month Status", width: 150 },
  {
    key: "Current_Month_Resolution_Activities",
    label: "Current Month Resolution Activities",
    width: 150,
  },
  { key: "isReviewed", label: "Review Status", width: 100 },
];

const rightStick = [
  "isReviewed",
  "Current_Month_Status",
  "Current_Month_Resolution_Activities",
];
const leftStick = ["Material", "Plant", "District_Name", "Plant_Code"];
function DataCell({
  rowData,
  dataKey,
  onRowSelect,
  onReviewChange,
}: {
  rowData: any;
  dataKey: string;
  onRowSelect: (selectedData: {
    sku: string;
    plant: string;
    cluster: string;
    material: string;
  }) => void;
  onReviewChange: (material: string, status: string) => void;
}) {
  const handleRowSelection = () => {
    const selectedData = {
      sku: `${rowData.material}-${rowData.plant}`,
      plant: rowData.plant,
      cluster: rowData.cluster,
      material: rowData.material,
    };
    onRowSelect(selectedData);
  };

  const handleReviewClick = (status: string) => {
    onReviewChange(rowData.material, status);
  };
  const displayValue = rowData[dataKey] != null ? rowData[dataKey] : "-";

  return (
    <div onClick={handleRowSelection} style={{ cursor: "pointer" }}>
      <p className="text-white text-0.7vw bg-[#1a1916]  truncate ">
        {displayValue}
      </p>
    </div>
  );
}

function ObsoleteMaterialTable(props: any) {
  const { Column, HeaderCell, Cell } = Table;
  const windowSize = useSelector(
    (state: any) => state.CommonReducer.windowSize
  );
  const location = useLocation();
  const type = new URLSearchParams(location.search).get("type");
  const handleRowSelect = (selectedData: any) => {
    console.log("Row selected:", selectedData);
    // Handle row selection logic here
  };
  

  const Theader = type === "0" ? tableHeaders : tableHeader1;

  const handleTextareaChange = (rowData: any, key: string, value: string) => {
    // Call the HandleResolutionChange prop with the necessary data
    props.HandleResolutionChange({
      SKUl: rowData["SKUl"],
      [key]: value, // Set the key dynamically
    });
  };

  return (
    <>
      <Table
        height={
          props?.showTable ? windowSize?.height / 1.6 : windowSize?.height / 3.6
        }
        data={props?.data}
        className="border-mainGray mt-2 "
        bordered
        virtualized
        cellBordered={true}
        headerHeight={65}
        loading={props.loading}
      >
        {Theader.map((header) => (
          <Column
            key={header.key}
            fixed={
              rightStick.includes(header?.key)
                ? "right"
                : leftStick.includes(header?.key)
                ? "left"
                : false
            }
            fullText
            width={
              header?.key === "Previous_Month_Status"
               || header?.key === "Current_Month_Status" 
               || header?.key === "Current_Month_Resolution_Activities" ||
              header?.key === "Previous_Resolution_Activities"
                ? windowSize?.width / 10
                : windowSize?.width / 15
            }
          >
            <HeaderCell
              style={{
                backgroundColor: "black",
                color: "white",
              }}
              className=" text-0.7vw text-center border-[#333231]"
            >
              <p className="text-wrap">{header.label}</p>
            </HeaderCell>
            <Cell
              style={{
                background: "#1a1916",
                borderRight: "0px solid #333231",
                borderBottom: "0px solid #333231",
              }}
            >
              {(rowData) => {
                if (header.key === "isReviewed") {
                  return (
                    <ButtonToolbar className="pb-1 ">
                      <ButtonGroup>
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            props.handleReviewChange(rowData.SKUl, 0);
                          }}
                          size="xs"
                          appearance="ghost"
                          className={`${
                            rowData[header.key] === 0
                              ? "bg-gray-500 text-white"
                              : "bg-white text-black"
                          } border-black border-l-2 hover:bg-green-900 hover:text-white  text-xs`}
                        >
                          N
                        </Button>
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            props.handleReviewChange(rowData.SKUl, 1);
                          }}
                          appearance="ghost"
                          size="xs"
                          className={`${
                            rowData[header.key] === 1
                              ? "bg-yellow-500 text-white"
                              : "bg-white text-black"
                          } border-black border-l-2  hover:bg-green-900 hover:text-white text-xs `}
                        >
                          IP
                        </Button>
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            props.handleReviewChange(rowData.SKUl, 2);
                          }}
                          appearance="ghost"
                          size="xs"
                          className={`${
                            rowData[header.key] === 2
                              ? "bg-green-500 text-white"
                              : "bg-white text-black"
                          } border-black  hover:bg-green-900 hover:text-white  text-xs`}
                        >
                          Y
                        </Button>
                      </ButtonGroup>
                    </ButtonToolbar>
                  );
                } else if (header.key === "SKUl_Check") {
                  return (
                    <div className="bg-[#1a1916] text-0.7vw text-white flex justify-center items-center">
                      {rowData[header.key] === "Yes" ? (
                       '✓'
                      ) : (
                       '✘'
                      )}
                    </div>
                  );
                } else if (header?.key === "ConstrainedOverstockQty$") {
                  return (
                    <div className="bg-[#1a1916] text-0.7vw text-white">
                      ${formatNumber(rowData[header.key])}
                    </div>
                  );
                }else if (header?.key === "ConstrainedOverstockQty") {
                  return (
                    <div className="bg-[#1a1916] text-0.7vw text-white">
                      {formatNumber(rowData[header.key])}
                    </div>
                  );
                } else if (header.key === "Current_Month_Status" || header.key === "Current_Month_Resolution_Activities") {
                  return (
                    <textarea
                    onClick={(e) => e.stopPropagation()}
                    defaultValue={rowData[header.key]}
                    className="w-full text-[12px] border-[0.5px] text-black rounded-lg h-[35px] relative top-[-10px] px-2 border-gray-700"
                    onChange={(e) => handleTextareaChange(rowData, header.key, e.target.value)}
                  />
                  );
                } else if (header.key === "Cost_FOB") {
                  return (
                    <div className="bg-[#1a1916] text-0.7vw text-white">
                      ${parseFloat(rowData[header.key]).toFixed(2)}
                    </div>
                  );
                } else if (header.key === "OnHand" ) {
                  return (
                    <div className="bg-[#1a1916] text-0.7vw text-white">
                      {formatNumber(rowData[header.key])}
                    </div>
                  );
                }
                else if (header.key === "Rounding_Value" ) {
                  return (
                    <div className="bg-[#1a1916] text-0.7vw text-white">
                      {formatNumber(rowData[header.key])}
                    </div>
                  );
                }  else if (header.key === "Onhand$Value") {
                  return (
                    <div className="bg-[#1a1916] text-0.7vw text-white">
                      ${formatNumber(rowData[header.key])}
                    </div>
                  );
                } else {
                  return (
                    <DataCell
                      rowData={rowData}
                      dataKey={header.key}
                      onRowSelect={handleRowSelect}
                      onReviewChange={props.handleReviewChange}
                    />
                  );
                }
              }}
            </Cell>
          </Column>
        ))}
      </Table>
      <div className="w-full flex justify-center p-2">
        <div className="w-3/12"></div>
        <div className="w-1/2 flex justify-center">
          <Pagination
            ellipsis
            boundaryLinks
            maxButtons={5}
            last
            next
            first
            size="xs"
            total={props.count}
            limit={100}
            layout={["pager", "skip"]}
            activePage={props.pageNumber}
            onChangePage={(page: any) => {
              props.setPageNumber(page);
            }}
          />
        </div>
        <div className="w-3/12 flex justify-end">
        <Button
            onClick={props.updateForecast}
            disabled={props.saveButton}
            className="w-20 mr-2"
            color="green"
            appearance="primary"
          >
            Save
          </Button>
          <Button
            onClick={() => {
              props?.setIsExport(true);
            
            }}
            className="w-20"
            color="green"
            appearance="primary">
            Export
          </Button>
          <CSVLink
            data={props?.exportAllData}
            ref={props?.exportFullRef}
            headers={type === "1" ? tableHeader1  : tableHeaders}
            filename={type === "0" ? "Obsolete Materials" : "DNO Materials"}
            className="hidden"
          ></CSVLink>
        </div>
      </div>
    </>
  );
}



export default ObsoleteMaterialTable;
