import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { getInactiveReportChartData } from '../../queries/apiQueries';
import { useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import { formatAmount, formatNumber } from '../../utils/commonFunctions';

function ObsoleteMaterialseBarGraph() {
    const windowSize = useSelector(
        (state: any) => state.CommonReducer.windowSize
      );

      const location = useLocation();
      const type = new URLSearchParams(location.search).get("type");
      const [BarData, setBarData] = useState([])

      const getbardata = () =>{
        getInactiveReportChartData({alertType : Number(type) }).then((response)=>{
          if(response.status === 200){
            const formattedData = response?.data?.monthwise.map((item: { MonthStartDate: string | number | Date; }) => ({
              ...item,
              MonthStartDate: format(new Date(item.MonthStartDate), 'MMM yy'), // Format date
            }));
            setBarData(formattedData)
          }
        })
      }

      useEffect(() => {
        
        getbardata();

      }, [])
    

  return (
    <div className="w-full ">
    <div className="flex">
      <span className="text-white font-medium text-0.95vw ">
     {`${type === '0' ?  'Obsolete Inventory Trend ($)': 'DNO & Excess Inventory Trend ($)'}  `}
      </span>
     
    </div>
    <ResponsiveContainer
      width={"100%"}
      height={windowSize?.width / 6.5}
      className={`border-[1px]  border-mainGray`}
    >
      <BarChart
        width={500}
        height={300}
        data={BarData}
        margin={{
          top: 10,
          right: 30,
          left: 20,
          bottom:  10,
        }}
      >
      
          <XAxis fontSize={10} stroke="#B5B5B5" dataKey="MonthStartDate" />
          <YAxis
            fontSize={10}
            stroke="#B5B5B5"
            tickFormatter={(value: any) => `$${formatAmount(value)}`} 
          />
        
        <Tooltip
          cursor={{ fill: "transparent" }}
          content={($) => <CustomTooltip data={$} />}
        />
        <Legend verticalAlign="top" />
          <Bar
            dataKey="onhand$"
            name={ `${type === '0' ? "OnHand ($)"  : "Constrained Overstock ($)"} `}
           
            barSize={60}
            stackId="a"
            fill="#57A4AD"
          ></Bar>
       

       
      </BarChart>
    </ResponsiveContainer>
  </div>
  )
}


const CustomTooltip = (props: any) => {
    const { active, payload } = props.data;
    console.log(payload);
    
    if (active && payload && payload.length) {
      return (
        <>
          <div className="bg-[#353535] p-3">
            <>
              <p className="text-white text-0.95vw">
                {payload[0]?.payload?.cluster
                  ? payload[0]?.payload?.cluster
                  : payload[0]?.payload?.label}
              </p>
              {payload?.map(($: any) => {
                return (
                  <>
                    {payload[0]?.payload?.MonthStartDate && (
                      <p className="text-white text-0.7vw">
                        {$?.payload?.MonthStartDate} : ${formatAmount($?.value)}
                      </p>
                    )}
                    
                  </>
                );
              })}
            </>
          </div>
        </>
      );
    }
  
    return null;
  };

export default ObsoleteMaterialseBarGraph