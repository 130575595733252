export const formatNumber = (num: number) => {
  let n=num
    if (n) {
      return n.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    } else {
      return n;
    }
};

export const  formatAmount = (num:number,decimal:number=1 ,unit: string = '') => {
  if (num === 0) {
    return (unit + 0).trim();
}
let returnValue = '';
let isNegative = num < 0;
num = Math.abs(num);
if (num > 999 && num < 1000000) {
    returnValue = (num / 1000).toFixed(decimal).toString() + 'K'; // convert to K for number from > 1000 < 1 million 
} else if (num >= 1000000) {
    returnValue = (num / 1000000).toFixed(decimal).toString() + 'M'; // convert to M for number from > 1 million 
} else {
    returnValue = num.toFixed(decimal).toString(); // if value < 1000, nothing to do
}
return (unit + (isNegative ? `(${returnValue})` : returnValue)).trim();
};