import React, { useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Notification, Table, useToaster } from "rsuite";
import { LONGTERM_CUSTOMER_TABLE } from "../../constants/dataConstant";

import { TABLEFILTER } from "./forecastDataTable";
import { formatNumber } from "../../utils/commonFunctions";

const { Column, HeaderCell, Cell, ColumnGroup } = Table;

const ForecastLongTermMainTable = (props: any) => {
  const { tableData } = props;

  const windowSize = useSelector(
    (state: any) => state.CommonReducer.windowSize
  );
  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();
  const [loading, setLoading] = useState(false);
  const [exportAllData, setExportALLData] = useState<any>([]);
  const exportFullRef = useRef<any>(null);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [selectedRowData, setSelectedRowData] = useState<any>([]);
  const didMount = useRef(false);
  const [coundtHandler, setCountHandler] = useState(0);

  const getData = () => {
    let data = tableData;
    if (sortColumn && sortType) {
      return data.sort((a: any, b: any) => {
        let x: any = a[sortColumn];
        let y: any = b[sortColumn];
        if (typeof x === "string") {
          x = x.charCodeAt(0);
        }
        if (typeof y === "string") {
          y = y.charCodeAt(0);
        }
        if (sortType === "asc") {
          return x - y;
        } else {
          return y - x;
        }
      });
    }
    return data;
  };

  const handleSortColumn = (sortColumn: any, sortType: any) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  useEffect(() => {
    if (exportAllData?.length !== 0) {
      exportFullRef?.current?.link.click();
    }
  }, [exportAllData]);

  const handleRowSelection = (data: any) => {
    let index = selectedRow.indexOf(data?.SKUl);
    if (index !== -1) {
      selectedRow.splice(index, 1);
      setSelectedRow([...selectedRow]);
      setSelectedRowData(
        selectedRowData?.filter(($: any) => $?.SKUl !== data?.SKUl)
      );
    } else {
      setSelectedRow([...selectedRow, data?.SKUl]);
      setSelectedRowData([...selectedRowData, data]);
    }
  };

  useEffect(() => {
    if (didMount.current) {
      if (coundtHandler >= 1) {
        props?.onRowChange(selectedRowData);
      } else {
        setCountHandler(coundtHandler + 1);
      }
    } else {
      didMount.current = true;
      setCountHandler(coundtHandler + 1);
    }
  }, [selectedRowData]);

  return (
    <>
      <div className=" flex justify-between mt-[10px]">
        <p className={`text-white font-medium text-0.95vw text-left `}>
          Items With High Outlier Correction -SKUL and Customer View for top 15 Customer
        </p>
      </div>
      <Table
        height={windowSize?.height / 3.3}
        // data={tableData ? tableData : []}
        loading={loading}
        bordered={true}
        className="border-mainGray mt-2"
        cellBordered={true}
        headerHeight={65}
        data={getData()}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        hover={false}
      >
        {LONGTERM_CUSTOMER_TABLE?.map((value) => {
          return (
            <>
              {!value?.subHeader && (
                <Column key={value?.key} sortable={value?.key==='sum_qty'?true:false} fullText flexGrow={1}>
                  <HeaderCell
                    style={{
                      backgroundColor: "black",
                      color: "white",
                    }}
                    className="text-0.7vw  border-[#333231]"
                  >
                    <p className="text-wrap">{value?.label}</p>
                  </HeaderCell>
                  <DataCell
                    dataKey={value?.key}
                    handleRowSelection={(data: any) => {
                      handleRowSelection(data);
                    }}
                  />
                </Column>
              )}
              {value?.subHeader && (
                <ColumnGroup header={value?.label} align="center">
                  {value?.subHeader?.map((element) => {
                    return (
                      <Column key={element?.key} fullText flexGrow={1}>
                        <HeaderCell
                          style={{
                            backgroundColor: "black",
                            color: "white",
                          }}
                          className="text-0.7vw  border-[#333231]"
                        >
                          <p className="text-wrap">{element?.label}</p>
                        </HeaderCell>
                        <DataCell
                          dataKey={element?.key}
                          handleRowSelection={(data: any) => {
                            handleRowSelection(data);
                          }}
                        />
                      </Column>
                    );
                  })}
                </ColumnGroup>
              )}
            </>
          );
        })}
      </Table>
    </>
  );
};

const DataCell = ({ rowData, dataKey, ...props }: any) => {
  const changeKey = [
    "onhand",
    "open_eros",
    "Total_Dmd_Within_LT_Custom",
    "effective_ss",
    "InvAtEndOfLT",
    "poqty",
    "povalue",
    "Onhand$Value",
  ];

  const handleRowSelection = (data: any) => {
    props?.handleRowSelection({
      SKUl: data?.material + data?.plant,
      plant: data?.plant,
      material: data?.material,
    });
  };
  return (
    <Cell
      {...props}
      className={` text-white cursor-pointer `}
      style={{ backgroundColor: "#1a1916" }}
      onClick={() => {
        handleRowSelection(rowData);
      }}
    >
      {!changeKey?.includes(dataKey) && (
        <p className="truncate  text-0.7vw">
          {rowData[dataKey] !== null ? rowData[dataKey] : ""}
        </p>
      )}
      {changeKey?.includes(dataKey) && (
        <p className="truncate  text-0.7vw">
          {rowData[dataKey] !== null
            ? `${
                dataKey !== "povalue" && dataKey !== "Onhand$Value" ? "" : "$"
              } ${formatNumber(rowData[dataKey])} `
            : ""}
        </p>
      )}
    </Cell>
  );
};

const message = (
  <Notification type={"info"} header={`Exporting...`} closable>
    <p>Your file is being created. It will be download automatically</p>
  </Notification>
);
const mapDispatchToProps = (dispatch: any) => ({
  onRowChange: (data: any) => dispatch({ type: TABLEFILTER, data: data }),
});

export default connect(null, mapDispatchToProps)(ForecastLongTermMainTable);
