import React, { useEffect, useState } from "react";
import { Loader, Table } from "rsuite";
import { CUSTOMER_LEVEL_TABLE, DATA_TABLE } from "../../constants/dataConstant";
import { formatNumber } from "../../utils/commonFunctions";
import { useSelector } from "react-redux";
import { getForecastCustomerLevelData } from "../../queries/apiQueries";

const { Column, HeaderCell, Cell } = Table;

const ForecastCustomerLevelTable = (props: any) => {
  const { SKUl } = props;
  const [tableData, setTableData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();
  const windowSize = useSelector(
    (state: any) => state.CommonReducer.windowSize
  );

  const getData = () => {
    let data = tableData;
    if (sortColumn && sortType) {
      return data.sort((a: any, b: any) => {
        let x: any = a[sortColumn];
        let y: any = b[sortColumn];
        if (typeof x === "string") {
          x = x.charCodeAt(0);
        }
        if (typeof y === "string") {
          y = y.charCodeAt(0);
        }
        if (sortType === "asc") {
          return x - y;
        } else {
          return y - x;
        }
      });
    }
    return data;
  };

  const handleSortColumn = (sortColumn: any, sortType: any) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  const getForecastCustomerLevelDataHandler = () => {
    setLoading(true);
    getForecastCustomerLevelData({ skul: SKUl })
      .then((response: any) => {
        if (response?.status === 200) {
          setTableData(response?.data?.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getForecastCustomerLevelDataHandler();
  }, [SKUl]);

  return (
    <>
      <div className="h-[20dvw]">
        {/* <Table
          style={{ height: windowSize.width / 7.4 }}
          data={getData()}
          bordered={true}
          cellBordered={true}
          headerHeight={45}
          affixHeader={10}
          className=" mr-3 border-mainGray border-2"
          fillHeight
          hover={false}
          loading={loading}

          sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        >
          {CUSTOMER_LEVEL_TABLE?.map((value) => {
            return (
              <Column
                key={value?.key}
                // fullText
                flexGrow={1}
                sortable={value?.key==='sum_qty'?true:false}
              >
                <HeaderCell
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    fontSize: 12,
                  }}
                  className="text-center"
                >
                  <p className="text-wrap">{value?.label}</p>
                </HeaderCell>
                <DataCell dataKey={value?.key} />
              </Column>
            );
          })}
        </Table> */}

        <div className=" overflow-x-auto h-full">
          <table className="w-full relative">
            <thead className="border-l-2 sticky top-0">
              <tr>
                {CUSTOMER_LEVEL_TABLE.map((ele) => {
                  return (
                    <th
                      scope="col"
                      className="px-6 py-4 text-center bg-black text-white border-b-[1px] border-b-mainGreen"
                    >
                      {ele?.label}
                    </th>
                  );
                })}
              </tr>
            </thead>
            
            <tbody className=" h-full">
            {loading && (
                <div className="flex justify-center items-center h-full w-full">
                  <Loader className="top-[10vw]" inverse center size="md"/>
                </div>
              )}
              {!loading && (
                <>
                  {getData()?.map((value: any) => {
                    return (
                      <tr className="bg-mainGray text-white">
                        {CUSTOMER_LEVEL_TABLE?.map((ele) => {
                          return (
                            <td className="px-6 py-4 ">{value[ele?.key]}</td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

const DataCell = ({ rowData, dataKey, ...props }: any) => {
  const formatData = [
    "sum_qty",
    "M_1_Qty",
    "M_2_Qty",
    "M_3_Qty",
    "M_4_Qty",
    "M_5_Qty",
    "M_6_Qty",
  ];

  return (
    <Cell
      style={{ backgroundColor: "#1a1916" }}
      {...props}
      className="text-white "
    >
      {!formatData?.includes(dataKey) && (
        <p className="truncate  text-0.7vw">{rowData[dataKey]}</p>
      )}

      {formatData?.includes(dataKey) && (
        <p className="truncate text-0.7vw ">
          {formatNumber(rowData[dataKey] || 0)}
        </p>
      )}
    </Cell>
  );
};

export default ForecastCustomerLevelTable;
