import React, { useEffect, useState } from "react";
import {
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useSelector } from "react-redux";
import { formatAmount, formatNumber } from "../../utils/commonFunctions";

const LineGraph = (props: any) => {
  const windowSize = useSelector(
    (state: any) => state.CommonReducer.windowSize
  );

  const { graphData } = props;


  return (
    <>
      <div className="w-full">
        <span className="text-white font-medium text-0.95vw ">
          Demand vs Forecast
        </span>
        <ResponsiveContainer
          width={"100%"}
          height={windowSize?.width / 6}
          className={`border-[1px] border-mainGray`}
        >
          <LineChart
            margin={{
              top: 0,
              right: 50,
              left: 50,
              bottom: 0,
            }}
            layout="horizontal"
            data={graphData}
          >
            <XAxis fontSize={10} stroke="#ffffff" dataKey="forecastDate" type="category" />
            <YAxis fontSize={10} tickFormatter={(value: string) => value && `${formatAmount(parseInt(value),0)}`} domain={[ (dataMin:any) => (dataMin / 1.3), (dataMax:any) => (dataMax * 1.05)]} stroke="#ffffff"  />
            <Tooltip content={($) => <CustomTooltip data={$} />} />
            <Legend verticalAlign="top" iconType="plainline" />

            <Line
              name="CY Demand (Post outlier adj.)"
              dataKey="processedQty"
              stroke="#a4f504"
              fill="#a4f504"
              strokeWidth={3}
              dot={{ strokeWidth: 1, r: 3 }}
            />
            <Line
              name="CY Forecast"
              dataKey="toolforecast"
              stroke="#ffffff"
              fill="#ffffff"
              strokeWidth={3}
              dot={{ strokeWidth: 1, r: 3 }}
            />
            <Line
              name="Last Year Demand"
              dataKey="processedQtyLY"
              stroke="#57a4ad"
              fill="#57a4ad"
              strokeWidth={3}
              //   hide={legendStatus.includes(3)}
              dot={{ strokeWidth: 1, r: 3 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

const CustomTooltip = (props: any) => {
  const { active, payload } = props.data;
  if (active && payload && payload.length) {
    return (
      <>
        <div className="bg-[#353535] p-3">
          <>
            <p className="text-white text-0.95vw">
              {payload[0]?.payload?.forecastDate}
            </p>
            {payload?.map(($: any) => {
              return (
                <>
                  <p key={$?.name} className="text-white text-0.7vw">
                    {$?.name} : {formatNumber($?.value)}
                  </p>
                </>
              );
            })}
          </>
        </div>
      </>
    );
  }

  return null;
};

export default LineGraph;
