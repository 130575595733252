import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  Bar,
  BarChart,
  Brush,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { getInactiveReportChartData } from "../../queries/apiQueries";
import { formatAmount, formatNumber } from "../../utils/commonFunctions";

function ObsoleteMaterialStackGraph() {
  const location = useLocation();
  const type = new URLSearchParams(location.search).get("type");
  const windowSize = useSelector(
    (state: any) => state.CommonReducer.windowSize
  );

  const [barData, setBarData] = useState([]);
  const [maxValue, setMaxValue] = useState(600000);

  const getbarData = () => {
    getInactiveReportChartData({ alertType: Number(type) }).then((response) => {
      if (response?.status === 200) {
        setBarData(response?.data?.plantwise);

        const maxOnHandValue = Math.max(
          ...(response?.data?.plantwise?.map(
            (item: any) => item.onhand$ || 0
          ) ?? [])
        );
        setMaxValue(maxOnHandValue);
      }
    });
  };

  const CustomTraveler = (props:any) => {
    const { x, y, width, height, stroke, fill } = props;
    return (
      <g>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          stroke={stroke}
          fill="red" // Set the fill color here
          opacity={0.5} // Optional: Set opacity if desired
        />
      </g>
    );
  };

  useEffect(() => {
    getbarData();
  }, []);

  const startIndex = 0; // Starting index of the brush
  const endIndex = Math.min(8, barData.length - 1);
  return (
    <div className="w-full overflow-auto">
      <div className="flex">
        <span className="text-white font-medium text-0.95vw ">
          {`${
            type === "0"
              ? "Districtwise Obsolete Breakdown ($)"
              : "Districtwise DNO & Excess Inventory Breakdown ($)"
          } `}
        </span>
      </div>
      <ResponsiveContainer
        width={"100%"}
        height={windowSize?.width / 6.5}
        className={`border-[1px]  border-mainGray`}
      >
        <BarChart
          width={500}
          height={300}
          data={barData}
          margin={{
            top: 10,
            right: 0,
            left: 0,
            bottom: 15,
          }}
          barCategoryGap={50}
        >
          <XAxis
            fontSize={10}
            tickMargin={8}
            stroke="#B5B5B5"
            dataKey="Plant_Code"
          />
          <YAxis
            fontSize={10}
            stroke="#B5B5B5"
            domain={[0, maxValue * 1.05 ]}
            tickFormatter={(value: any) => `$${formatAmount(value)}`}
          />

          <Tooltip
            cursor={{ fill: "transparent" }}
            content={($) => <CustomTooltip data={$} />}
          />
          <Legend verticalAlign="top" />
          <Bar
            dataKey="onhand$"
            name={`${
              type === "0" ? "OnHand ($)" : "Constrained Overstock ($)"
            } `}
            barSize={50}
            stackId="a"
            fill="#57A4AD"
          ></Bar>

          <Brush
            dataKey="Plant_Code"
            alwaysShowText={false}
            startIndex={startIndex}
            endIndex={endIndex}
            height={6}
            tickFormatter={(tick) => (
              0
              ? tick :
              ''
              )}
            opacity={1}
            fill="black"
            strokeOpacity={1}
            fillOpacity={1}
            stroke="white"
            travellerWidth={0}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

const CustomTooltip = (props: any) => {
  const { active, payload } = props.data;
  console.log(payload);

  if (active && payload && payload.length) {
    return (
      <>
        <div className="bg-[#353535] p-3">
          <>
            {payload?.map(($: any) => {
              return (
                <>
                  {payload[0]?.payload?.Plant_Code && (
                    <p className="text-white text-0.7vw">
                      {$?.payload?.Plant_Code} : ${formatAmount($?.value)}
                    </p>
                  )}
                </>
              );
            })}
          </>
        </div>
      </>
    );
  }

  return null;
};

export default ObsoleteMaterialStackGraph;
