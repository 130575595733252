import React, { useEffect, useState } from "react";

import ForecastDataTable from "../../components/forecast/forecastDataTable";
import {
  getHighOutlierChartData,
  getHighOutlierCustomerData,
  getInventoryAvailabilityData,
  getStockOutAnddemandData,
} from "../../queries/apiQueries";
import moment from "moment";
import ForecastGraph from "../../components/forecast/forecastGraph";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ForecastGraphTabular from "../../components/forecast/forecastGraphTabular";
import { appInsights } from "../router";
import ForecastLongTermLineGraph from "../../components/forecast/forecastLongTermLineGraph";
import ForecastLongTermTable from "../../components/forecast/forecastLongTermTable";
import ForecastLongTermMainTable from "../../components/forecast/forecastLongTermMainTable";

const Forecast = () => {
  const selectedGBPFilter = useSelector(
    (state: any) => state.CommonReducer.selectedGBPFilter
  );
  

  const [monthNumber, setMonthNumber] = useState(0);
  const [detectMonth, setDetectMonth] = useState<any>(null);
  const [graphData, setGraphData] = useState<any>([]);
  const [highOutlierGraphData, setHighOutlierGraphData] = useState<any>([]);
  const [graphStockAndAcurracyData, setGraphStockAndAcurracyData] = useState<any>([]);
  const [invGraphData, setInvGraphData] = useState<any>([]);
  const [graphTab, setGraphTab] = useState<any>(1);
  const [graphTable, setGraphTable] = useState<any>(1);
  const [highOutlierCustomerTableData, setHighOutlierCustomerTableData] = useState<any>([]);


  let location = useLocation();

  const alertType=new URLSearchParams(location.search).get('alert')
  const type = new URLSearchParams(location.search).get("type");

  const getStockOutAnddemandDataForeCast = async (monthNumber:any=0) => {
    let payload=selectedGBPFilter
    getStockOutAnddemandData(payload)
      .then((response) => {
          let sorted=response?.data?.sdata.sort((a:any,b:any) => b.forecast_date - a.forecast_date)
          sorted.reverse()
          let finalData:any=[]
          if(sorted?.length!==0){
            let isFuture=0
            sorted.forEach((element:any) => {
              let obj={}
              let nextMonthCheck=moment(new Date(response?.data?.replicateDate[0]?.replicate_date?.slice(0, 10)).setMonth(new Date(response?.data?.replicateDate[0]?.replicate_date).getMonth() )+86400000).format("MMM YYYY")
              if(isFuture===0&&nextMonthCheck!==moment(element?.forecast_date?.slice(0, 10)).format("MMM YYYY")){
                console.log(moment(element?.forecast_date?.slice(0, 10)).format("MMM YYYY"),'element')
                element.toolforecast=element?.toolforecast ? element?.toolforecast:0
                obj={
                  forecastDate:moment(element?.forecast_date?.slice(0, 10)).format("MMM'YY"),
                  accuracy:element?.accuracy ? (element?.accuracy*100).toFixed(2):0,
                  bias:element?.bias?(element?.bias*100).toFixed(2):0,
                  stockout_days:element?.stockout_days
                  
                }
                finalData.push(obj)
              } 
              if(isFuture<6){
                element.forecastDate=moment(element?.forecast_date?.slice(0, 10)).format("MMM'YY")
              }else{
                element.toolforecast=null
              }
              if(moment(response?.data?.replicateDate[0]?.replicate_date?.slice(0, 10)).format("MMM YYYY")===moment(element?.forecast_date?.slice(0, 10)).format("MMM YYYY")||isFuture!==0){
                isFuture+=1
                element.processedQty=null
                element.processedQtyLY=null
                element.processedQtyL2Y=null
                element.stockout_days=null
                element.bias=null
                element.accuracy=null
              }
                
            });
          }
          let filteredData=sorted?.filter((val:any)=>val?.toolforecast!==null)
          setGraphData(filteredData);
          setGraphStockAndAcurracyData(finalData)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getHighOutlierChartDataHandler=()=>{
    getHighOutlierChartData(selectedGBPFilter).then((response:any)=>{
      if(response?.status===200){
        response?.data?.data?.forEach((value:any)=>{
          value.date=moment(value?.dayDate?.slice(0, 10)).format("MMM'YY")
        })
        setHighOutlierGraphData(response?.data?.data)
      }
    }).catch((err)=>{
      console.log(err)
    })
  }

  useEffect(() => {
    appInsights.trackPageView({ name: `Navigate to Forecast Overview by ${localStorage.getItem('name')}`  });
    if(Object.keys(selectedGBPFilter).length !== 0&&alertType){
      if(type!=='1'){
        getStockOutAnddemandDataForeCast();
        getInventoryAvailability()
      }else{
        getHighOutlierChartDataHandler()
      }
    }

    if(!alertType){
      if(type!=='1'){
        getStockOutAnddemandDataForeCast();
        getInventoryAvailability()
      }else{
        getHighOutlierChartDataHandler()
      }
    }
  }, [selectedGBPFilter]);

  // useEffect(() => {
  //   getStockOutAnddemandDataForeCast();
  // }, [selectedFilterData]);



  const getInventoryAvailability = async () => {
    let payload={
      ...selectedGBPFilter,
      monthNumber:[monthNumber]
    }
    getInventoryAvailabilityData(payload)
      .then((response: any) => {
        if(response?.status===200){
          response?.data?.sdata?.reverse().forEach((element:any) => {
            element.forecastDate=moment(element.weekstartdate?.slice(0, 10)).format('MMM Do')
        });

        setInvGraphData(response?.data?.sdata)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getHighOutlierCustomerDataHandler=(data:any)=>{
    getHighOutlierCustomerData({skul:data}).then((response:any)=>{
      if(response?.status===200){
        setHighOutlierCustomerTableData(response?.data?.data)
      }
    }).catch((err)=>{
      console.log(err)
    })
  }

  return (
    <>
    {/* type not equal to 1 means we are showing the forecast overview of Short Term Alerts */}
    {type!=='1'&&<>
      <div className="w-full flex justify-center mt-2 px-4">
        <div className="w-full flex justify-between">
        </div>
      </div>
      <div className="w-full flex justify-center px-4">
        <div className="flex justify-between w-[95%]">

        {graphTable===2&&<div className="w-full h-full">  
            <ForecastGraphTabular data={graphData} setGraphTable={(tab:number)=>{setGraphTable(tab)}} graphTable={graphTable}/>
        </div>}
          {graphTable===1&&<ForecastGraph title="Demand vs Forecast" flag={1} setGraphTable={(tab:number)=>{setGraphTable(tab)}}  graphTable={graphTable} data={graphData} />}
          {graphTab === 1 && (
            <ForecastGraph   graphTab={graphTab} setGraphTab={(tab:number)=>{setGraphTab(tab)}} title="Stock Out Days" flag={2} data={graphStockAndAcurracyData} />
          )}
          {graphTab === 2 && (
            <ForecastGraph graphTab={graphTab} setGraphTab={(tab:number)=>{setGraphTab(tab)}}  title="Accuracy/BIAS" flag={3} data={graphStockAndAcurracyData} />
          )}
          {graphTab === 3 && (
            <ForecastGraph
            setGraphTab={(tab:number)=>{setGraphTab(tab)}}
            graphTab={graphTab}
              title="Inventory Availability for Last 12 Weeks"
              flag={4}
              data={invGraphData}
            />
          )}
        </div>
      </div>

      <div className="w-full  flex justify-center mt-2 px-4" >
        <div className="w-[95%] "  >
          <ForecastDataTable monthNumber={detectMonth} />
        </div>
      </div>
    </>}

{/* type not equal to 1 means we are showing the forecast overview of Short Term Alerts */}
{type==='1'&&<>
      <div className="w-full flex justify-center mt-2 px-4">
        <div className="w-full flex justify-between">
        </div>
      </div>
      <div className="w-full flex justify-center px-4">
        <div className=" w-[95%]">
          <div className=" grid grid-cols-2 gap-6">
          <ForecastLongTermLineGraph lineGraphData={highOutlierGraphData}/>
          <div className="w-full h-full">  
          <ForecastLongTermTable getHighOutlierCustomerDataHandler={(val:any)=>val?.length!==0?getHighOutlierCustomerDataHandler(val):setHighOutlierCustomerTableData([])}/>
          </div>
          </div>
        </div>
      </div>

      <div className="w-full  flex justify-center mt-2 px-4" >
        <div className="w-[95%] "  >
          <ForecastLongTermMainTable tableData={highOutlierCustomerTableData}/>
        </div>
      </div>
    </>}

    </>
  );
};

export default Forecast;
