import * as React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Alerts from "./alerts";
import Headers from "../components/common/header";
import Forecast from "./forecast";
import { useIsAuthenticated } from "@azure/msal-react";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import {
  ApplicationInsights,
  ITelemetryPlugin,
} from "@microsoft/applicationinsights-web";
import { INSTRUMENTATION_KEY } from "../config/config";
import ForecastSummary from "./forecastSummary";
import InventoryAlerts from "./inventoryAlerts";
import InactiveInventoryPage from "./inventoryAlerts/inactiveInventory";
import OverstockInventoryPage from "./inventoryAlerts/overstockInventory";
import StockoutInventoryPage from "./inventoryAlerts/stockoutInventory";
import POInventoryPage from "./inventoryAlerts/poInventory";
import HomePage from "./home";
import InventoryReports from "./inventoryReports";
import ObsoleteMaterials from "./inventoryReports/obsoleteMaterials";

export const reactPlugin = new ReactPlugin();
export const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: INSTRUMENTATION_KEY,
    disableFetchTracking: false,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin as unknown as ITelemetryPlugin],
    autoTrackPageVisitTime: true,
  },
});
appInsights.loadAppInsights();
const Router = () => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <BrowserRouter>
      <Headers />
      <Routes>
        <Route
          path="/"
          element={<HomePage />}
        />
        <Route path="/forecast/forecast-alerts" element={<Alerts />} />
        <Route path="/forecast/forecast-overview" element={<Forecast />} />
        <Route path="/forecast/forecast-summary" element={<ForecastSummary />} />
        <Route path="/inventory/inventory-alerts" element={<InventoryAlerts />}/>
        <Route path="/inventory/inventory-reports" element={<InventoryReports />}/>
        <Route path="/inventory/inventory-reports/obsolete-materials" element={<ObsoleteMaterials />}/>
        <Route path="/inventory/inventory-alerts/inactive-inventory-managment" element={<InactiveInventoryPage />} />
        <Route path="/inventory/inventory-alerts/overstock-inventory-managment" element={<OverstockInventoryPage />} />
        <Route path="/inventory/inventory-alerts/stockout-inventory-managment" element={<StockoutInventoryPage />} />
        <Route path="/inventory/inventory-alerts/po-inventory-managment" element={<POInventoryPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
