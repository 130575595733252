import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Notification, useToaster } from "rsuite";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { getSummaryExportData } from "../../queries/apiQueries";
import { CSVLink } from "react-csv";
import { SUMMARY_EXPORT } from "../../constants/dataConstant";
import {
  Bar,
  BarChart,
  Cell,
  Label,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { formatNumber } from "../../utils/commonFunctions";

const WaterfallGraph = (props: any) => {
  const { waterfallGraph } = props;
  const windowSize = useSelector(
    (state: any) => state.CommonReducer.windowSize
  );
  const selectedPlantCode = useSelector(
    (state: any) => state.CommonReducer.selectedPlantCode
  );

  const [exportSummary, setExportSummary] = useState([]);
  const exportSummaryRef = useRef<any>(null);

  const toaster = useToaster();

  const getSummaryExportDataHandler = () => {
    toaster.push(message, { placement: "topCenter" });
    getSummaryExportData(selectedPlantCode)
      .then((response: any) => {
        if (response?.status === 200) {
          setExportSummary(response?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (exportSummary?.length !== 0) {
      exportSummaryRef?.current?.link.click();
    }
  }, [exportSummary]);

  return (
    <>
      <div className="w-full ">
        <div className="flex justify-between">
          <span className="text-white font-medium text-0.95vw ">
            Changes in Forecastable SKULs from last month
          </span>
          <CSVLink
            className="hidden"
            data={exportSummary}
            ref={exportSummaryRef}
            headers={SUMMARY_EXPORT}
            filename={"SummaryData"}
          ></CSVLink>
          <Button
            appearance="ghost"
            size="xs"
            className={`bg-green-500 text-white
                  border-black border-l-2 hover:bg-green-900 hover:text-white focus:bg-green-500 focus:text-white`}
            onClick={getSummaryExportDataHandler}
          >
            <FileDownloadIcon sx={{ fontSize: 20 }} />
          </Button>
        </div>
        <ResponsiveContainer
          width={"100%"}
          height={windowSize?.width / 7}
          className={`border-[1px] border-mainGray`}
        >
          <BarChart
            data={waterfallGraph}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis fontSize={10} stroke="#B5B5B5" dataKey="name" />
            <YAxis fontSize={10} stroke="#B5B5B5" tickFormatter={(value: any) => value && `${formatNumber(value)}`} />
            <Tooltip cursor={{fill: 'transparent'}} content={($) => <CustomTooltip data={$} />} />
            <Bar dataKey="pv" stackId="a" fill="transparent" />
            <Bar
              dataKey="uv"
              name="Addition/Dropped"
              stackId="a"
              fill="#82ca9d"
            >
              {waterfallGraph?.map((item: any, index: any) => {
                if (item.uv < 0) return <Cell key={index} fill="#B22222" />;
                if (item.name !== ""&&item.name !== "Addition")
                  return <Cell key={index} fill="#57A4AD" />;
                return <Cell key={index} fill="#228B22" />;
              })}
              <LabelList dataKey="uv" content={renderCustomizedLabel} />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

const message = (
  <Notification type={"info"} header={`Exporting...`} closable>
    <p>Your file is being created. It will be download automatically</p>
  </Notification>
);

const renderCustomizedLabel = (props: any) => {
  const { x, y, width, value,name } = props;
  return (
    <>
      <text x={x + width / 2} y={y- (value>0?10:-10) } fill={value>0?(name!==''&&name !== "Addition"?'#57A4AD':'#228B22'):'#B22222'} textAnchor="middle" dominantBaseline="middle" >
        {formatNumber(value)}
      </text>
    </>
  );
};

const CustomTooltip = (props: any) => {
  const { active, payload } = props.data;
  if (active && payload && payload.length) {
    return (
      <>
        <div className="bg-[#353535] p-3">
          <>
            <p className="text-white text-0.95vw">
              {payload[0]?.payload?.name}
            </p>
            {payload?.map(($: any) => {
              return (
                <>
                  {$?.name !== "pv" && (
                    <>
                      {payload[0]?.payload?.name !== "" && (
                        <p className="text-white text-0.7vw">
                          Total : {$?.value}
                        </p>
                      )}
                      {payload[0]?.payload?.name === "" && (
                        <p className="text-white text-0.7vw">
                          {$?.value > 0 ? "Additon" : "Dropped"} : {$?.value}
                        </p>
                      )}
                    </>
                  )}
                </>
              );
            })}
          </>
        </div>
      </>
    );
  }

  return null;
};

export default WaterfallGraph;
