import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Loader, Toggle, toaster, Notification } from "rsuite";
import ObsoleteMaterialseBarGraph from "../../../components/inventory/ObsoleteMaterialseBarGraph";
import ObsoleteMaterialStackGraph from "../../../components/inventory/ObsoleteMaterialStackGraph";
import ObsoleteMaterialTable from "../../../components/inventory/ObsoleteMaterialTable";
import ObsoleteMaterialsFilters from "../../../components/inventory/ObsoleteMaterialsFilters";
import {
  getInactiveReportTableData,
  updateInventoryReportComments,
  updateReviewedInventoryReportData,
} from "../../../queries/apiQueries";

function ObsoleteMaterials() {
  const location = useLocation();
  const type = new URLSearchParams(location.search).get("type");

  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [ShowTable, setShowTable] = useState(false);
  const [commentValues, setCommentValues] = useState<any>([]);
  const handleToggleChange = (checked: boolean) => {
    setShowTable(checked);
  };
  const [count, setCount] = useState(0);
  const exportFullRef = useRef<any>(null);
  const [saveButton, setSaveButton] = useState(true);
  const [IsExport, setIsExport] = useState(false);
  const [changeData, setChangeData] = useState<any>(null);
  const [changedCurrentStatusComment, setChangedCurrentStatusComment] =
    useState<any>({ data: [] });
  const [IsReviewTable, setIsReviewTable] = useState([]);
  const [UserSelectedFilters, setUserSelectedFilters] = useState<any>({
    district: "",
    clusterName: "",
  });
  const [data, setData] = useState<any>([]);
  const [TableCount, setTableCount] = useState({
    yesCount: 0,
    NoCount: 0,
    IPCount: 0,
  });
  const [TableOnhand, setTableOnhand] = useState({
    yesOnhand: 0,
    NoOnhand: 0,
    IPOnhand: 0,
  });
  const [exportAllData, setExportALLData] = useState<any>([]);
  const updateFilterState = (data: any) => {
    console.log(data);

    setUserSelectedFilters((prevState: any) => ({
      ...prevState,
      district:
        data.district !== undefined ? data.district : prevState.district,
      clusterName:
        data.clustername !== undefined
          ? data.clustername
          : prevState.clusterName,
    }));
  };



  const HandleResolutionChange = (data: any) => {
    console.log(data);
    const existingIndex = commentValues.findIndex(
      (item: { SKUl: any }) => item.SKUl === data.SKUl
    );
    if (existingIndex > -1) {
      // If it exists, update the existing entry
      const updatedcommentValues = [...commentValues];
      updatedcommentValues[existingIndex] = {
        ...updatedcommentValues[existingIndex],
        ...data, // Spread the updated values
      };
      setCommentValues(updatedcommentValues);
    } else {
      // If it doesn't exist, add a new entry
      setCommentValues([...commentValues, data]);
    }
    console.log("Updated Resolution Data:", commentValues);
    setSaveButton(commentValues.length !== 0 ? false : true);
  };

  const updateForecast = () => {
    const payload = {
      data: commentValues.map((comment:any) => ({
          SKUl: comment.SKUl,
          Current_Month_Status: comment.Current_Month_Status,
          Current_Month_Resolution_Activities: comment.Current_Month_Resolution_Activities,
      })),
     alertType :  type === "0" ? "obsolete" : "dno"
      };
  console.log(payload);
  
    updateInventoryReportComments(payload).then((response) =>{
      if (response?.status === 200) {
        setLoading(true);
        GetTableData();
        setSaveButton(false)
      }
    })
  };





  const GetTableData = () => {
    setLoading(true);
    let payload = {
      district: UserSelectedFilters.district,
      clustername: UserSelectedFilters.clusterName,
      alertType: Number(type),
      pageNumber: pageNumber || 1,
      pageSize: 20,
      isExport: IsExport,
    };
    getInactiveReportTableData(payload).then((response) => {
      if (response?.status === 200) {
        if (!IsExport) {
          console.log(response?.data);
          setData(response?.data?.data);
          setCount(response?.data?.count);
          PrepareCountTable(response?.data?.isReviewed);
        } else {
          setExportALLData(response?.data?.data);
          toaster.push(message, { placement: "topCenter" });
        }
        setLoading(false);
        setIsExport(false);
      }
    });
  };

  const PrepareCountTable = (IsReviewTable: any) => {
    const newOnhand = { yesOnhand: 0, NoOnhand: 0, IPOnhand: 0 };
    const newCount = { yesCount: 0, NoCount: 0, IPCount: 0 };
    IsReviewTable?.forEach((value: any) => {
      switch (value?.isReviewed) {
        case 0:
          newOnhand.NoOnhand = value?.Onhand$Value;
          newCount.NoCount = value?.isReviewedCount;
          break;
        case 1:
          newOnhand.IPOnhand = value?.Onhand$Value;
          newCount.IPCount = value.isReviewedCount;
          break;
        case 2:
          newOnhand.yesOnhand = value?.Onhand$Value;
          newCount.yesCount = value.isReviewedCount;
          break;
        default:
          break;
      }
    });

    setTableOnhand(newOnhand);
    setTableCount(newCount);
  };

  const handleReviewChange = (SKUl: any, status: any) => {
    let payload = {
      SKUl: SKUl,
      reviewedFlag: status,
      alertType: type === "0" ? "obsolete" : "dno",
    };
    updateReviewedInventoryReportData(payload).then((response) => {
      if (response?.status === 200) {
        GetTableData();
      } else {
        console.log("Failed to update review status");
      }
    });
    // Handle review status change logic here
  };

  useEffect(() => {
    if (IsExport) {
      GetTableData();
    }
  }, [IsExport]);

  useEffect(() => {
    GetTableData();
    // PrepareCountTable();
  }, [type, pageNumber, UserSelectedFilters]);

  useEffect(() => {
    if (exportAllData?.length !== 0) {
      exportFullRef?.current?.link.click();
    }
  }, [exportAllData]);

  return (
    <>
      <div className="w-full flex justify-center">
        <div className="w-[95%]">
          <ObsoleteMaterialsFilters
            TableCount={TableCount}
            TableOnhand={TableOnhand}
            updateFilterState={updateFilterState}
          />
        </div>
      </div>
      <div className="w-full flex justify-center">
        <div className="w-[95%] relative flex">
          <div>
            <span
              className={`font-${ShowTable ? "normal" : "bold"} ${
                ShowTable ? "text-[#858585]" : "text-white"
              } text-[0.8vw] mr-1`}
            >
              Min. Table
            </span>
            <Toggle checked={ShowTable} onChange={handleToggleChange} />
            <span
              className={`font-${!ShowTable ? "normal" : "bold"} ${
                !ShowTable ? "text-[#858585]" : "text-white"
              } text-[0.8vw] ml-1`}
            >
              Max. Table
            </span>
          </div>
          <p className="text-mainGreen text-1.3vw text-center -z-10 absolute w-full">
            {type === "0"
              ? `Top Obsolete Materials`
              : type === "1"
              ? `Top DNO and Excess Material`
              : `Top DNO and Excess Material`}
            ​
          </p>
        </div>
      </div>
      <div className=" mt-2">
        {loading && (
          <Loader backdrop size="md" inverse center content="loading..." />
        )}
        <div className="w-full flex justify-center">
          <div className="w-[95%]">
            <div className={`grid  grid-cols-2 gap-6`}>
              {!ShowTable && <ObsoleteMaterialseBarGraph />}
              {!ShowTable && <ObsoleteMaterialStackGraph />}
            </div>
            <div className="w-full flex justify-center items-center">
              <div className="w-full mt-5">
                <ObsoleteMaterialTable
                  commentValues={commentValues}
                  HandleResolutionChange={HandleResolutionChange}
                  updateForecast={updateForecast}
                  saveButton={saveButton}
                  setIsExport={setIsExport}
                  exportAllData={exportAllData}
                  exportFullRef={exportFullRef}
                  handleReviewChange={handleReviewChange}
                  UserSelectedFilters={UserSelectedFilters}
                  loading={loading}
                  setPageNumber={setPageNumber}
                  data={data}
                  count={count}
                  showTable={ShowTable}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const message = (
  <Notification type={"info"} header={`Exporting...`} closable>
    <p>Your file is being created. It will be download automatically</p>
  </Notification>
);

export default ObsoleteMaterials;
