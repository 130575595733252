import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as xlsx from "xlsx";
import { formatAmount, formatNumber } from "../../utils/commonFunctions";

const HomePage = (props: any) => {
  const navigate = useNavigate();

  const gotToPage = (path: string) => {
    navigate(path);
  };

  return (
    <div className="w-full flex justify-center">
      <div className="w-[90dvw] h-full">
        <div className="grid grid-cols-7 gap-4 h-[90dvh]">
          <div className=" col-span-2">
            <div className=" mt-[5dvw] ">
              <img
                src="/upload-bg.png"
                alt="upload-bg.png"
                className="w-full relative left-[-3dvw]"
              />
              <div className="text-mainGreen  z-10 relative mt-[-1dvw] ">
                <span>
                  <h1 className="text-[7vh]">
                    <strong className="text-[10vh]">
                      <span>D</span>
                    </strong>
                    emand 
                    <strong className="text-[10vh]">
                      <span>B</span>
                    </strong>
                    rain
                  </h1>
                </span>
              </div>
            </div>
          </div>

          <div className="col-span-5 w-full border-l-2 border-gray-500 flex justify-center items-center">
            <div className="w-[95%]">
              <div onClick={()=>gotToPage('/forecast/forecast-alerts')} className="cursor-pointer  border-2 border-gray-500 rounded-3xl grid grid-cols-5 p-3">
                <div className="col-span-2 border-red">
                  <div className="relative max-w-xl mx-auto ">
                    <img
                      className="h-[10dvw] w-full object-cover rounded-3xl"
                      src="/fore_alerts.jpg"
                      alt="Random"
                    />
                    <div className="absolute inset-0 bg-gray-700  opacity-60  rounded-3xl"></div>
                    <div className="absolute inset-0 flex items-center justify-center">
                      <h2 className="text-white text-1.5vw font-bold">
                      Forecast Alerts
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="col-span-3 border-red">
                  <div className="w-full">
                    <p className="px-10 text-white mt-5 italic text-0.95vw text-left">
                      To flag abnormal changes in demand forecasts compared to
                      historical demand, enabling demand planners to make
                      necessary adjustments
                    </p>

                    {/* <div className="w-full px-10 pt-3 grid grid-cols-3">
                      <div className=" border-r-2 border-gray-500">
                        <div className="py-1">
                          <p className="text-center italic text-mainGreen">Total Alerts</p>
                        </div>
                        <div className="bg-[#404040] py-1">
                          <p className="text-center text-white">{formatNumber(1234)}</p>
                        </div>
                      </div>

                      <div className=" border-r-2 border-gray-500">
                        <div className="py-1">
                          <p className="text-center italic text-mainGreen">Total Value</p>
                        </div>
                        <div className="bg-[#404040] py-1">
                          <p className="text-center text-white">${formatAmount(12340000)}</p>
                        </div>
                      </div>

                      <div className=" ">
                        <div className="py-1">
                          <p className="text-center italic text-mainGreen">Reviewed Alerts</p>
                        </div>
                        <div className="bg-[#404040] py-1">
                          <p className="text-center text-white">{formatNumber(120)}</p>
                        </div>
                      </div>
                    </div> */}

                  </div>
                </div>
              </div>


              <div onClick={()=>gotToPage('/inventory/inventory-alerts')} className="cursor-pointer mt-[1dvw] border-2 border-gray-500 rounded-3xl grid grid-cols-5 p-3">
                <div className="col-span-2 border-red">
                  <div className="relative max-w-xl mx-auto ">
                    <img
                      className="h-[10dvw] w-full object-cover rounded-3xl"
                      src="/inv_alerts.jpg"
                      alt="Random"
                    />
                    <div className="absolute inset-0 bg-gray-700  opacity-60  rounded-3xl"></div>
                    <div className="absolute inset-0 flex items-center justify-center">
                      <h2 className="text-white text-1.5vw font-bold">
                      Inventory Alerts
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="col-span-3 border-red">
                  <div className="w-full">
                    <p className="px-10 text-white mt-5 italic text-0.95vw text-left">
                    To flag priority items based on recent history and near future in terms of high overstock, high understock / stockout or inactive
                    </p>
                    {/* <div className="w-full px-10 pt-3 grid grid-cols-3">
                      <div className=" border-r-2 border-gray-500">
                        <div className="py-1">
                          <p className="text-center italic text-mainGreen">Total Alerts</p>
                        </div>
                        <div className="bg-[#404040] py-1">
                          <p className="text-center text-white">{formatNumber(1234)}</p>
                        </div>
                      </div>

                      <div className=" border-r-2 border-gray-500">
                        <div className="py-1">
                          <p className="text-center italic text-mainGreen">Total Value</p>
                        </div>
                        <div className="bg-[#404040] py-1">
                          <p className="text-center text-white">${formatAmount(12340000)}</p>
                        </div>
                      </div>

                      <div className=" ">
                        <div className="py-1">
                          <p className="text-center italic text-mainGreen">Reviewed Alerts</p>
                        </div>
                        <div className="bg-[#404040] py-1">
                          <p className="text-center text-white">{formatNumber(120)}</p>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>

              <a style={{ textDecoration: 'none' }} rel="noreferrer" href="https://app.powerbi.com/Redirect?action=OpenApp&appId=14b15720-78ec-440f-9781-383d4516e4eb&ctid=36da45f1-dd2c-4d1f-af13-5abe46b99921" target="_blank">
              <div className=" border-2 border-gray-500 rounded-3xl mt-[1dvw] grid grid-cols-5 p-3">
                <div className="col-span-2 border-red">
                  <div className="relative max-w-xl mx-auto ">
                    <img
                      className="h-[10dvw] w-full object-cover rounded-3xl"
                      src="/inv_perf.jpg"
                      alt="Random"
                    />
                    <div className="absolute inset-0 bg-gray-700  opacity-60  rounded-3xl"></div>
                    <div className="absolute inset-0 flex items-center justify-center">
                      <h2 className="text-white text-1.5vw font-bold">
                      ZFBK/NSR Performance
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="col-span-3 border-red">
                  <div className="w-full">
                    <p className="px-10 text-white mt-5 italic text-0.95vw text-left">
                    Assess and review the historical performance of the processes to gain insights and provide forward-looking insights for the approval of upcoming ZFBKs/NSRs
                    </p>
                  </div>
                </div>
              </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div className="w-full flex justify-center">
    //   <div className="w-[90%] ">
    //     <div></div>
    //     <div className="absolute h-[90%] w-[56%] right-[1%]">
    //       <img
    //         src="/upload-bg.png"
    //         alt="upload-bg.png"
    //         className="w-full h-[90%]"
    //       />
    //     </div>

    //     <div className="text-mainGreen mt-20 z-10 relative">
    //       <span>
    //         <h1 className="text-[5vh]">
    //           <strong className="text-[10vh]">
    //             <span>D</span>
    //           </strong>
    //           emand
    //           <strong className="text-[10vh]">
    //             <span> B</span>
    //           </strong>
    //           rain

    //         </h1>
    //       </span>
    //     </div>

    //     <div  className="w-[55vw] gap-12 mt-28 flex justify-start z-10 relative">
    //         <div onClick={()=>gotToPage('/forecast/forecast-alerts')} className="w-full h-[11vw] border-[1px]  rounded-2xl hover:border-mainGreen border-gray-500 cursor-pointer">
    //         <div className="w-full h-full grid grid-cols-3">
    //             <div className="col-span- flex justify-center items-center">
    //                     <img src="/fore_alerts.jpg" alt="fore_alerts.jpg" className="h-[75%] w-[95%] border-2 border-mainGreen bg-white rounded-full " />
    //             </div>
    //             <div className="col-span-2 border-l-2 border-dotted border-mainGreen  h-full">
    //               <div className="w-full">
    //                   <p className="text-1.1vw text-center text-mainGreen font-bold">Forecast Alerts</p>
    //                   <p className="px-10 text-white mt-5 text-[0.8vw] text-left">To flag abnormal changes in demand forecasts compared to historical demand, enabling demand planners to make necessary adjustments</p>
    //               </div>
    //             </div>
    //         </div>
    //         </div>

    //         <div onClick={()=>gotToPage('/inventory/inventory-alerts')} className="w-full h-[11vw] border-[1px]  rounded-2xl hover:border-mainGreen border-gray-500 cursor-pointer">
    //         <div className="w-full h-full grid grid-cols-3">
    //             <div className="col-span- flex justify-center items-center">
    //                     <img src="/inv_alerts.jpg" alt="inv_alerts.jpg" className="h-[75%] w-[95%] border-2 border-mainGreen bg-white rounded-full " />
    //             </div>
    //             <div className="col-span-2 border-l-2 border-dotted border-mainGreen  h-full">
    //               <div className="w-full">
    //                   <p className="text-1.1vw text-center text-mainGreen font-bold">Inventory Alerts</p>
    //                   <p className="px-10 text-white mt-5 text-[0.8vw] text-left">To flag priority items based on recent history and near future in terms of high overstock, high understock / stockout or inactive</p>
    //               </div>
    //             </div>
    //         </div>
    //         </div>

    //     </div>
    //     <div >
    //       <a className="w-[55vw] mt-10 gap-24 flex justify-center z-10 relative" rel="noreferrer" href="https://app.powerbi.com/Redirect?action=OpenApp&appId=14b15720-78ec-440f-9781-383d4516e4eb&ctid=36da45f1-dd2c-4d1f-af13-5abe46b99921" target="_blank">
    //         <div className="w-1/2 h-[11vw] border-[1px]  rounded-2xl border-gray-500 hover:border-mainGreen cursor-pointer">
    //         <div className="w-full h-full grid grid-cols-3">
    //             <div className="col-span- flex justify-center items-center">
    //                     <img src="/inv_perf.jpg" alt="inv_perf.jpg" className="h-[75%] w-[95%] border-2 border-mainGreen bg-white rounded-full " />
    //             </div>
    //             <div className="col-span-2 border-l-2 border-dotted border-mainGreen  h-full">
    //               <div className="w-full">
    //                   <p className="text-1.1vw text-center text-mainGreen font-bold">ZFBK/NSR Performance</p>
    //                   <p className="px-10 text-white mt-5 text-[0.8vw] text-left">Assess and review the historical performance of the processes to gain insights and provide forward-looking insights for the approval of upcoming ZFBKs/NSRs</p>
    //               </div>
    //             </div>
    //         </div>
    //         </div>
    //       </a>

    //     </div>
    //   </div>
    // </div>
  );
};

export default HomePage;
