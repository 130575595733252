import React, { useEffect, useState } from "react";
import { CheckPicker } from "rsuite";
import { getInventoryReportsFilters } from "../../queries/apiQueries";
import { useLocation } from "react-router-dom";
import { formatAmount, formatNumber } from "../../utils/commonFunctions";

function ObsoleteMaterialsFilters(props: any) {
  const location = useLocation();
  const type = new URLSearchParams(location.search).get("type");

  const [loading, setLoading] = useState(false);
  const [Districts, setDistricts] = useState([]);
  const [plantCode, setplantCode] = useState([]);
  const GetMaterialFilters = (Alerttype: any) => {
    setLoading(true);
    let payload = {
      alertType: Number(Alerttype),
    };
    getInventoryReportsFilters(payload).then((response: any) => {
      if (response?.status === 200) {
        console.log(response?.data);

        setDistricts(response?.data?.ditrict);
        setplantCode(response?.data?.plantCode);
        setLoading(false);
      }
    });
  };


  useEffect(() => {
    console.log(props?.TableCount);
    
    GetMaterialFilters(type);
  }, []);

  return (
    <div className="grid grid-cols-5 gap-4 ">
    
      <div className="w-full ">
        <p className="text-mainGreen  text-0.95vw">Cluster</p>
        <CheckPicker
          placeholder="All"
          loading={loading}
          className="w-[99%]"
          onChange={(data) => {
            props.updateFilterState({ clustername: data });
          }}
          data={plantCode}
          labelKey="plant_code"
          valueKey="plant_code"
          size="sm"
        />
      </div>
      <div className="w-full ">
        <p className="text-mainGreen  text-0.95vw">District</p>
        <CheckPicker
          placeholder="All"
          loading={loading}
          onChange={(data) => {
            props.updateFilterState({ district: data });
          }}
          className="w-[99%]"
          data={Districts}
          labelKey="district"
          valueKey="district"
          size="sm"
        />
      </div>
      <div className="col-span-1"></div>

      {/* <div className="col-span-2  ">
        <div className="">
                   
                    <div className="grid grid-cols-3 gap-4 ">
                        <div className="text-center">
                            <p className="text-mainGreen">Yes</p>
                            <div className="bg-[#56544e] text-white p-2 rounded-md">
                          <span></span>   : 45
                            </div>
                        </div>
                        <div className="text-center">
                            <p className="text-mainGreen">No</p>
                            <div className="bg-[#56544e] text-white p-2 rounded-md">
                               78
                            </div>
                        </div>
                        <div className="text-center">
                            <p className="text-mainGreen">In Progress</p>
                            <div className="bg-[#56544e] text-white p-2 rounded-md">
                                132
                            </div>
                        </div>
                    </div>
                </div>
      </div> */}
  {/* <table className="min-w-full text-center text-white border-collapse border border-gray-300">
    <thead>
    <tr>
    <th className="border border-gray-300"></th>
    <th className="border border-gray-300">Yes</th>
    <th className="border border-gray-300">No</th>
    <th className="border border-gray-300">In Progress</th>
    </tr>
    </thead>
    <tbody>
    <tr>
        <td className="border border-gray-300">Count</td>
        <td className="border text-mainGreen border-gray-300">{formatNumber(props?.TableCount?.yesCount)}</td>
        <td className="border text-mainGreen border-gray-300">{formatNumber(props?.TableCount?.NoCount)}</td>
        <td className="border text-mainGreen border-gray-300">{formatNumber(props?.TableCount?.IPCount)}</td>
        </tr>
      <tr>
        <td className="border border-gray-300">Onhand ($)</td>
        <td className="border text-mainGreen border-gray-300">{formatNumber(props?.TableOnhand?.yesOnhand)}</td>
        <td className="border text-mainGreen border-gray-300">{formatNumber(props?.TableOnhand?.NoOnhand)}</td>
        <td className="border text-mainGreen border-gray-300">{formatNumber(props?.TableOnhand?.IPOnhand)}</td>
        </tr>
        </tbody>
        </table> */}
<div className="col-span-2 flex justify-end">
  <div className="flex justify-end w-full">
    <div className="bg-[#2d2d2d] w-1/4 p-1 pl-2 rounded-md">
      <p className="text-white font-bold text-sm">Reviewed</p>
      <div className=" text-white  rounded-md mt-1">
        <p className="text-base text-mainGreen font-bold">{formatAmount(props?.TableCount?.yesCount ,0)}</p>
        <p className="text-sm m-0">${formatAmount(props?.TableOnhand?.yesOnhand)}</p>
      </div>
    </div>
    <div className="bg-[#2d2d2d] w-1/4 p-1 pl-2 mx-2 rounded-md">
      <p className="text-white font-bold text-sm">Review Pending</p>
      <div className=" text-white  rounded-md mt-1">
        <p className="text-base text-mainGreen font-bold">{formatAmount(props?.TableCount?.NoCount ,0)}</p>
        <p className="text-sm m-0">${formatAmount(props?.TableOnhand?.NoOnhand)}</p>
      </div>
    </div>
    <div className="bg-[#2d2d2d] w-1/4 p-1 pl-2 rounded-md">
      <p className="text-white font-bold text-sm">In Progress</p>
      <div className=" text-white  rounded-md mt-1">
        <p className="text-base text-mainGreen font-bold">{formatAmount(props?.TableCount?.IPCount , 0)}</p>
        <p className="text-sm m-0">${formatAmount(props?.TableOnhand?.IPOnhand)}</p>
      </div>
    </div>
  </div>
</div>
    </div>
  );
}

export default ObsoleteMaterialsFilters;
