import React, { useEffect, useState } from "react";
import { Loader } from "rsuite";

import POInventoryLineGraph from "../../../components/inventory/poInventoryLineGraph";
import POInventoryTable from "../../../components/inventory/poInventoryTable";
import {
  getDemandInventoryLineGraphData,
} from "../../../queries/apiQueries";
import moment from "moment";
import { useSelector } from "react-redux";

const POInventoryPage = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [lineGraphData, setLineGraphData] = useState(null);
  const [waterfallData, setWaterfallData] = useState(null);

  const [selectedRow,setSelectedRow]=useState<any>(null)

  const selectedGBPFilter = useSelector(
    (state: any) => state.CommonReducer.selectedGBPFilter
  );

  const getDemandInventoryLineGraphDataHandler = () => {
    let payLoad={...selectedGBPFilter, alertType: "potentialOverstock" }

    if(selectedRow){
      payLoad={...payLoad,poNumber:selectedRow?.poNumber}
    }

    getDemandInventoryLineGraphData(payLoad)
      .then((response: any) => {
        if (response?.status === 200) {
        
          response?.data?.demand?.forEach((value: any, index: number) => {
            value.date = moment(value?.dayDate?.slice(0, 10)).format("MMM'YY");
            value.connect = null;
          
          });
          response?.data?.inventory?.forEach((value: any) => {
            value.date = moment(value?.monthdate?.slice(0, 10)).format(
              "MMM'YY"
            );
          });
          response?.data?.repData?.forEach((value: any) => {
            value.date = moment(value?.monthdate?.slice(0, 10)).format(
              "MMM'YY"
            );
          });


          response?.data?.potentialOverstock.forEach((value:any)=>{
            if(value?.netinv){
              value.onhand=value?.netinv
            }
            if(response?.data?.potentialOverstock.length > 1){
              if(response?.data?.potentialOverstock.indexOf(value) === response?.data?.potentialOverstock.length - 1){
                value.date = 'Current NIQ Custom';
              } else {
                value.date = moment(value?.monthstartdate?.slice(0, 10)).format("MMM'YY");
              }
            } else {
              value.date = moment(value?.monthstartdate?.slice(0, 10)).format("MMM'YY");
            }
          })
          
          setWaterfallData(response?.data?.potentialOverstock)


          setLineGraphData(response?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if(Object.keys(selectedGBPFilter).length !== 0){
      getDemandInventoryLineGraphDataHandler();
    }
  }, [selectedGBPFilter]);
  return (
    <>
      <p className="text-mainGreen text-1.5vw text-center ">
        Potential Overstock Due to Open POs
      </p>
      <div className=" ">
        {loading && (
          <Loader backdrop size="md" inverse center content="loading..." />
        )}

        <div className="w-full flex justify-center">
          <div className="w-[95%]">
            <div className=" grid grid-cols-2 gap-6">
              <POInventoryLineGraph graphType={4} waterfallData={waterfallData}/>
              <POInventoryLineGraph  lineGraphData={lineGraphData}/>
            </div>
            <div className="w-full flex justify-center items-center">
              <div className="w-full mt-[0.5dvw]">
                <POInventoryTable selectedRow={(data:any)=>setSelectedRow(data)}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default POInventoryPage;
